import { Dialog } from '@headlessui/react'
import React, { SetStateAction } from 'react'
import LayoutHeader from './LayoutHeader'
import LayoutSidebar from './LayoutSidebar'
import Maybe from 'components/common/Maybe'
import PaymentLinkTypeSelector from '../PaymentLinkTypeSelector'
import { ReactComponent as BackButton } from "assets/images/icons/arrow-left-bordered.svg";
import usePaymentLinksProvider from '../context/usePaymentLinksProvider'
import CollectionLinkHomepage from '../collections/CollectionLinkHomepage'


type Props = {
   setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const LayoutDisplay = ({ setSessionModalOpen }: Props) => {
   const { isCollectionPayment, paymentLinkStep, decrementPaymentLinkStep } = usePaymentLinksProvider()

   const handleReturn = () => {
      decrementPaymentLinkStep()
   }


   return (
      <div className="w-full h-full bg-white overflow-y-auto">
         <Dialog.Panel className="min-h-full flex flex-col">

            <LayoutHeader
               setSessionModalOpen={setSessionModalOpen}
            />

            <div className="flex lg:px-0">

               <div className='w-2/5'>
                  <LayoutSidebar />

                  <Maybe condition={paymentLinkStep > 1}>
                     <button onClick={handleReturn} className="sticky left-[350px] top-32">
                        <BackButton />
                     </button>
                  </Maybe>
               </div>

               <div className='w-3/5'>
                  <Maybe condition={paymentLinkStep === 1}>
                     <PaymentLinkTypeSelector />
                  </Maybe>


                  <Maybe condition={paymentLinkStep > 1}>

                     <Maybe condition={isCollectionPayment}>
                        <CollectionLinkHomepage />
                     </Maybe>
                  </Maybe>
               </div>

            </div>

         </Dialog.Panel>
      </div>
   )
}

export default LayoutDisplay