import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorInfo from "assets/images/icons/warning.gif";
import Maybe from "components/common/Maybe";
import { ButtonLoader } from "components/common/form";
import React, { ReactNode } from 'react';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onBtnClick: () => void;
    loading: boolean;
    ButtonTray?: ReactNode;
}

const TwoFactorAuthenticationCard = ({ isOpen, onClose, onBtnClick, loading, ButtonTray }: Props) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-gray-900 opacity-50 backdrop-filter backdrop-blur-sm"></div>

            <div className="flex flex-col bg-white max-w-[424px] rounded-[30px] z-10">
                <header className="flex flex-col items-end px-6 pt-6 pb-3 w-full bg-white rounded-t-[30px]">
                    <FontAwesomeIcon icon={faClose} onClick={onClose} className="cursor-pointer" />
                </header>
                <main className="flex flex-col justify-center px-8 pb-7 w-full text-center">
                    <div className="flex justify-center mb-3">
                        <img src={ErrorInfo} alt="Two-factor authentication illustration" className="max-w-[82px] max-h-[82px]" />
                    </div>
                    <h2 className="mt-1.5 text-2xl text-ellipsis text-zinc-800">
                        Before you continue,
                    </h2>
                    <p className="mt-5 text-base tracking-tight text-neutral-400">
                        Turning on "Two-Factor Authentication" adds an extra layer of security to your business account, making it safer for managing payouts. Just tap 'Activate 2FA' below to get started.
                    </p>
                </main>

                <footer className="px-6 pt-1 pb-5 w-full flex flex-col justify-end border-t border-solid border-neutral-100 rounded-b-[30px]">

                    <Maybe condition={ButtonTray !== undefined}>
                        {ButtonTray}
                    </Maybe>

                    <Maybe condition={!ButtonTray}>
                        <ButtonLoader loading={loading} className="w-full btn bg-shiga-black text-white btn-lg btn-block py-2 rounded-xl" onClick={onBtnClick}>
                            Activate 2FA
                        </ButtonLoader>
                    </Maybe>

                </footer>

            </div>
        </div>
    );
};

export default TwoFactorAuthenticationCard;
