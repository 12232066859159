import { ReactComponent as IconRight } from "assets/images/icons/arrow-right-gray.svg";
import collectionIcon from "assets/images/one-time-colored.png";
import tuitionIcon from "assets/images/tuition-colored.png";
import LayoutFlowWrapper from './layout/LayoutFlowWrapper';
import Maybe from "components/common/Maybe";
import usePaymentLinksProvider from "./context/usePaymentLinksProvider";

const PaymentLinkTypeSelector = () => {
   const { setPaymentLinkType, incrementPaymentLinkStep } = usePaymentLinksProvider()

   const selectionTypes = [
      {
         title: 'Payment Collection',
         subtitle: 'Create and send a link to collect payments from an individual or business',
         value: 'COLLECTION',
         icon: collectionIcon,
         permission: true
      },
      {
         title: 'Tuition Payment',
         subtitle: 'Create and send a link to an individual looking to make payment for tuition',
         value: 'TUITION',
         icon: tuitionIcon,
         permission: false
      }
   ]


   return (
      <LayoutFlowWrapper
         title='Select a Payment Link type'
         subtitle='What type of payment would you like to create?'>
         <div className="space-y-6">
            {selectionTypes.map((type, index) => {

               const permission = type?.permission;

               return (
                  <div key={index}
                     className={`w-full flex items-center rounded-[20px] px-5 py-3 border border-outline-grey-2 text-shiga-gray-300 
                     ${permission ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                     onClick={() => {
                        if (permission) {
                           setPaymentLinkType(type.value);
                           incrementPaymentLinkStep();
                        }
                     }}>

                     <div className="mr-3.5">
                        <img
                           width={55}
                           alt="transfer"
                           src={type.icon}
                        />
                     </div>

                     <div>
                        <div className="text-[16px] text-shiga-dark-100 font-semibold">
                           {type.title}
                           <Maybe condition={!permission}>
                              <span className="text-shiga-orange-100 border border-shiga-orange-50 font-medium ml-3 text-[10px] p-px rounded-sm">
                                 Coming soon
                              </span>
                           </Maybe>
                        </div>
                        <div className="text-sm font-medium text-shiga-gray-300">
                           {type.subtitle}
                        </div>
                     </div>

                     <div>
                        <IconRight />
                     </div>
                  </div>
               )
            })}
         </div>
      </LayoutFlowWrapper >
   )
}

export default PaymentLinkTypeSelector