
export const RESET_WEBHOOK_PARAMS = "RESET_WEBHOOK_PARAMS";

export const RETRY_WEBHOOK_LOG_START = "RETRY_WEBHOOK_LOG_START";
export const RETRY_WEBHOOK_LOG_DONE = "RETRY_WEBHOOK_LOG_DONE";
export const RETRY_WEBHOOK_LOG_FAILED = "RETRY_WEBHOOK_LOG_FAILED";

export const GET_WEBHOOK_DETAILS_START = "GET_WEBHOOK_DETAILS_START";
export const GET_WEBHOOK_DETAILS_DONE = "GET_WEBHOOK_DETAILS_DONE";
export const GET_WEBHOOK_DETAILS_FAILED = "GET_WEBHOOK_DETAILS_FAILED";

export const GET_WEBHOOK_LOGS_START = "GET_WEBHOOK_LOGS_START";
export const GET_WEBHOOK_LOGS_DONE = "GET_WEBHOOK_LOGS_DONE";
export const GET_WEBHOOK_LOGS_FAILED = "GET_WEBHOOK_LOGS_FAILED";

export const GENERATE_API_KEY_START = "GENERATE_API_KEY_START";
export const GENERATE_API_KEY_DONE = "GENERATE_API_KEY_DONE";
export const GENERATE_API_KEY_FAILED = "GENERATE_API_KEY_FAILED";
