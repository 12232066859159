import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

// import localStorage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session';  // accessible in tabs only

import authReducer from "./auth/auth.reducer";
import entitiesReducer from "./entities/entities.reducer";
import uiReducer from "./ui/ui.reducer";
import dashboardReducer from "./dashboard/reducer";
import transactionsReducer from "./transactions/reducer";
import transferReducer from "./transfer/reducer";
import accountReducer from "./accounts/reducer"
import loadingReducer from "./loading/reducer"
import cardReducer from "./card/reducer"
import refundReducer from "./refund/reducer"
import billsPaymentReducer from "./bills-payment/reducer"
import convertReducer from "./convert/reducer"
import ecomReducer from './ecom/reducer'
import webhookReducer from './webhooks/reducer'

const rootPersistConfig = {
    key: "root",
    storage: sessionStorage,
    whitelist: [""],
}

const authPersistConfig = {
    key: "auth",
    storage: sessionStorage,
    whitelist: ["userPersist"],
}

const rootReducer = combineReducers({
    entities: entitiesReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    ui: uiReducer,
    dashboard: dashboardReducer,
    transactions: transactionsReducer,
    transfer: transferReducer,
    accounts: accountReducer,
    loading: loadingReducer,
    cards: cardReducer,
    refunds: refundReducer,
    billsPayment: billsPaymentReducer,
    convert: convertReducer,
    ecom: ecomReducer,
    webhook: webhookReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);