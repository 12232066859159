import { Input } from "components/common/form";
import TextArea from "components/common/form/TextArea";
import { ReactComponent as Calender } from "assets/images/icons/Calendar.svg";
import { ReactComponent as Weight } from "assets/images/icons/weight.svg";
import { ReactComponent as Revenue } from "assets/images/icons/revenue.svg";
import ImageCarousel from "./ImageCarousel";
import moment from "moment";

const EditProduct = ({ product }: any) => {

  return (
    <div>
      <div className="flex flex-col-reverse lg:flex-row justify-between items-center lg:items-start">
        <div>

          <div className="bg-shiga-black w-[224px] h-[224px] rounded-2xl mb-6">
            <ImageCarousel product={product} />
          </div>

          <div>
            <div className="mb-4">
              <p className="text-uppercase text-shiga-gray-300 font-[400]">STATS</p>
            </div>

            <p className="flex items-center text-[13px] text-shiga-gray-100 mb-2">
              <Calender className="mr-3" /> Created{" "}
              <span className="text-shiga-gray-300 font-[500] ml-2">
                {moment(product?.createdAt).format('MMM D, YYYY [at] h:mma')}
              </span>
            </p>

            <p className="flex items-center text-[13px] text-shiga-gray-100 mb-2">
              <Weight className="mr-3" /> Weight&nbsp;
              <span className="text-shiga-gray-300 font-[500] ml-2">
                {`${product?.weight}${product?.weightUnit}`}
              </span>
            </p>

            <p className="flex items-center text-[13px] text-shiga-gray-100 mb-2">
              <Revenue className="mr-3" /> Revenue{" "}
              <span className="text-shiga-gray-300 font-[500] ml-2">
                {Number(product?.revenue)?.toLocaleString()} {product?.currency}
              </span>
            </p>

          </div>
        </div>


        <div className="mt-5 lg:0 w-full lg:w-[530px] space-y-4">

          <div className="flex justify-between gap-6">

            <div className="w-full">
              <Input
                disabled
                label="Product Name"
                value={product?.productName}
              />
            </div>

            <div className="w-full">
              <Input
                disabled
                type="text"
                label="Price"
                value={`${product?.price?.toLocaleString()} ${product?.currency}`}
              />
            </div>
          </div>

          <div className="mb-[1.75rem]">
            <TextArea
              label="Description"
              value={product?.description}
            />
          </div>

          <div className="flex justify-between gap-6">

            <div className="w-full">
              <Input
                disabled
                name="category"
                label="Category"
                value={product?.category?.name}
              />
            </div>

            <div className="w-full">
              <Input
                disabled
                name="quantity"
                label="Quantity in Stock"
                value={product?.quantity?.toLocaleString()}
              />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default EditProduct;
