import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";
import { useEffect, useRef, useState } from "react";
import Maybe from "components/common/Maybe";
import useMediaService from "hooks/useMediaService";
import { RootState } from "store/store";
import useToolkit from "hooks/useToolkit";
import * as config from "data/config";
import { resetProfilePicProps, saveProfilePic } from "store/entities/settings/action";


type Props = {
   referenceUrl: string;
   placeholderSrc: string;
   subtitle?: string;
   isPersonalProfile: boolean;
   isVendorUpload?: boolean;
   manualUploadTrigger?: boolean;
   onUploadSuccess?: () => void;
   onImageSelect?: () => void;
   onImageDelete?: () => void;
}

const ProfilePicUploader = ({
   referenceUrl,
   placeholderSrc,
   subtitle,
   isPersonalProfile,
   isVendorUpload,
   manualUploadTrigger,
   onImageSelect,
   onImageDelete,
   onUploadSuccess
}: Props) => {
   const { dispatch, useSelector } = useToolkit();
   const [img, setImg] = useState<any>(null);
   const [file, setFile] = useState<any>(null);
   const fileRef = useRef<HTMLInputElement>(null);

   const { refetchProfiles } = useToolkit();
   const { uploadFile, uploadedFileLoading } = useMediaService();
   const { profilePicLoading, profilePicData } = useSelector((state: RootState) => state.entities.settings)

   const isRefValid = referenceUrl?.length > 0;
   const uploadPath = isPersonalProfile ? config.apiUploadProfilePic : config.apiUploadBusinessLogo;


   const handleChange = (e: any) => {
      const file = e?.target?.files[0];

      setFile(file);
      setImg(URL.createObjectURL(file));

      onImageSelect && onImageSelect();
   }

   const onDelete = () => {
      const inputRef = document.getElementById("fileInput") as HTMLInputElement;

      setImg(null);
      setFile(null);

      if (inputRef) {
         inputRef.value = "";
      }
      onImageDelete && onImageDelete();
   }

   const updateProfilePic = async () => {
      const response: any = await uploadFile(file);
      if (response?.success === true) {
         const url = response?.data?.file?.public;

         dispatch(saveProfilePic({

            url: uploadPath,
            payload: { url }
         }))
      }
   }

   useEffect(() => {
      if (img !== null) {
         if (isVendorUpload) {

            if (manualUploadTrigger === true) {
               updateProfilePic();
            }

         } else {
            updateProfilePic();
         }
      }
      // eslint-disable-next-line
   }, [img, manualUploadTrigger])

   useEffect(() => {
      if (profilePicData !== null) {
         refetchProfiles();

         if (onUploadSuccess) {
            onUploadSuccess();

            setTimeout(() => {
               dispatch(resetProfilePicProps())
            }, 1500);
         }
      }
      // eslint-disable-next-line
   }, [profilePicData, onUploadSuccess])

   return (
      <div className="w-44">
         <div className="w-44 h-44 bg-[#F3F4F5] relative rounded-3xl overflow-hidden">

            <input
               type="file"
               ref={fileRef}
               id="fileInput"
               onChange={handleChange}
               accept=".png, .jpg, .jpeg"
               style={{ display: 'none' }}
            />

            <IconEdit
               data-tooltip-id="dp-remove"
               onClick={() => fileRef?.current?.click()}
               data-tooltip-content={img ? "" : "Change Image"}
               className={`w-8 h-8 p-1.5 absolute z-[1] bottom-2 
               rounded-full hover:bg-opacity-100 cursor-pointer outline-none 
               ${isVendorUpload ? 'bg-shiga-dark-200 bg-opacity-60 right-2' : 'bg-black bg-opacity-50 left-2'}`}
            />

            <Maybe condition={img !== null}>
               <IconRemove
                  onClick={onDelete}
                  data-tooltip-id="dp-remove"
                  data-tooltip-content="Remove Image"
                  className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 
               right-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white"
               />
            </Maybe>

            <Maybe condition={!isRefValid && img === null}>
               <img
                  alt='placeholder'
                  src={placeholderSrc}
                  className="w-full h-full object-cover"
               />
            </Maybe>

            <Maybe condition={isRefValid || img !== null}>

               <img
                  alt='placeholder'
                  src={img || referenceUrl}
                  className="w-full h-full object-cover"
               />
            </Maybe>

         </div>

         <Maybe condition={!isVendorUpload}>
            <div className="mt-4 text-xs text-[#99999C]">
               {uploadedFileLoading ? 'Uploading file...' :
                  profilePicLoading ? 'Saving...' : subtitle || 'Min 400x400px, PNG or JPEG formats'}
            </div>
         </Maybe>

      </div>
   )
}

export default ProfilePicUploader