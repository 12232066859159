import { useEffect } from 'react'
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import { ReactComponent as IconGlobe } from "assets/images/icons/Globe.svg";
import { RootState } from 'store/store';
import { Loader } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import validator from 'validator';
import { resetUpdateWebhooks, updateWebhooks } from 'store/entities/settings/action';


const UrlsTab = () => {
   const { useState, businessProfile, refetchProfiles, useSelector, dispatch } = useToolkit();
   const location = useLocation();
   const navigate = useNavigate();

   const [form, setForm] = useState<any>({
      webhookUrl: "",
      webhookCallback: "",
   })
   const { businessProfileLoading: isLoading } = useSelector((state: RootState) => state.auth.account);
   const { webhooksUpdateLoading, webhooksUpdate } = useSelector((state: any) => state.entities.settings);
   const formIsInvalid = (form?.webhookCallback?.length > 0 && !validator.isURL(form.webhookCallback)) ||
      (form?.webhookUrl?.length > 0 && !validator.isURL(form.webhookUrl));


   const list = [
      {
         title: 'Webhook URL',
         value: form.webhookUrl,
         tag: "webhookUrl",
      },
      {
         title: 'Callback URL',
         value: form.webhookCallback,
         tag: "webhookCallback",
      }
   ]

   useEffect(() => {
      if (webhooksUpdate?.success === true) {
         refetchProfiles();

         setTimeout(() => {
            dispatch(resetUpdateWebhooks())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [webhooksUpdate]);

   useEffect(() => {
      refetchProfiles();
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      if (businessProfile?.webhookCallback && businessProfile?.webhookCallback?.length > 0) {
         setForm({
            webhookUrl: businessProfile?.webhookUrl,
            webhookCallback: businessProfile?.webhookCallback,
         })
      }
      // eslint-disable-next-line
   }, [businessProfile]);

   return (

      <>
         <div className="pb-24">

            <Maybe condition={isLoading}>
               <div className="py-20 flexed">
                  <Loader
                     size='3rem'
                     className="animate-spin"
                  />
               </div>
            </Maybe>


            <Maybe condition={!isLoading}>
               <div>
                  {list?.map((item: any) => {

                     return (
                        <div key={item?.title} className="w-full flex items-center justify-between border-t border-shiga-gray-50 mb-5">

                           <div className="font-medium text-lg text-shiga-dark-100">
                              {item?.title}
                           </div>

                           <div className="w-2/5">
                              <Input
                                 type="text"
                                 label=" "
                                 value={item?.value}
                                 onChange={(e: any) => setForm({ ...form, [item?.tag]: e.target.value })}
                                 leftIcon={<IconGlobe />}
                                 leftIconClass="px-2.5"
                              />
                           </div>

                        </div>
                     )
                  })}
               </div>

               <div className="mt-8 flex justify-end">
                  <ShigaButton
                     darkBg
                     className='px-8'
                     text="Save Changes"
                     disabled={formIsInvalid}
                     loading={webhooksUpdateLoading}
                     onClick={() => dispatch(updateWebhooks(form))}
                  />
               </div>

            </Maybe>
         </div>

      </>
   )
}

export default UrlsTab