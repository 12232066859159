import useTransfersProvider from '../../context/useTransfersProvider'
import CustomAmountField from 'components/custom/CustomAmountField';
import { ReactComponent as IconWallet } from "assets/images/icons/transfer-wallet.svg";
import subtract from "assets/images/icons/Subtract.png";
import userIcon from "assets/images/icons/users_icon.png";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ShigaButton from 'components/common/form/ShigaButton';
import useToolkit from 'hooks/useToolkit';
import CustomNotePad from 'components/custom/CustomNotePad';


const TransferAmountView = () => {
   const { mfaEnabled, refetchProfiles } = useToolkit();
   const {
      transferWallet,
      trfCurrency,
      formattedWalletBalance,
      currencyFlag,
      transferAmount,
      summedAmount,
      setSummedAmount,
      setTransferAmount,
      incrementSingleTrfStep,
      toggleTwoFacPrompt,
      transferNote,
      setTransferNote,
      setTransferFee,
      ngnForm,
      isNgnTransfer,
      isUsdTransfer,
      isAudTransfer,
      isGbpTransfer,
      usdForm,
      audForm,
      gbpForm,
   } = useTransfersProvider();

   const {
      transferFeeData,
      transferLimitData: limit,
      transferFeeLoading: feeLoading,
   } = useSelector((allStates: any) => allStates.transfer);
   const [amountToPay, setamountToPay] = useState(0);
   const [accountName, setAccountName] = useState('');

   const insufficientBalance = transferAmount?.length > 0 &&
      (transferWallet?.balance < Number(transferAmount) || transferWallet?.balance < amountToPay);

   const minimumInvalid = (transferAmount && Number(transferAmount) < 1) ||
      (Number(transferAmount) > 0 && limit?.data?.min > 0 && limit?.data?.min > Number(transferAmount));

   const invalidAmount = minimumInvalid || insufficientBalance;
   const minAmountMsg = `The minimum amount you can send is ${limit?.data?.min || "..."} ${trfCurrency}`;
   const proceedBtnDisabled = Number(transferAmount) < 1 || feeLoading || invalidAmount;

   const onProceed = () => {
      if (mfaEnabled) {
         incrementSingleTrfStep()
      } else {
         toggleTwoFacPrompt()
      }
   };

   useEffect(() => {
      if (isNgnTransfer) {
         setAccountName(ngnForm.accountName)
      } else if (isUsdTransfer) {
         setAccountName(usdForm.accountName)
      } else if (isAudTransfer) {
         setAccountName(audForm.accountName)
      } else if (isGbpTransfer) {
         setAccountName(gbpForm.accountName)
      }
      // eslint-disable-next-line
   }, [])

   useEffect(() => {
      if (transferFeeData?.success === true) {
         const fee = transferFeeData?.data?.feeAmount;
         const totalAmount = transferFeeData?.data?.amountToPay - fee;

         setSummedAmount(totalAmount?.toString());
         setamountToPay(transferFeeData?.data?.amountToPay);
         setTransferFee(fee)
      }
      // eslint-disable-next-line
   }, [transferFeeData]);

   useEffect(() => {
      setamountToPay(0);
      refetchProfiles();
      // eslint-disable-next-line
   }, [])

   return (
      <div>

         <CustomAmountField
            leftLabel='You send'
            isFieldRequired
            setInputAmount={(e: any) => setTransferAmount(e.target.value)}
            inputError={invalidAmount}
            errorText={`${minimumInvalid
               ? minAmountMsg
               : insufficientBalance
                  ? "You cannot send more than your available balance" : minAmountMsg}`}
            flagIconUrl={currencyFlag || ""}
            inputAmount={transferAmount}
            transactionCurrency={trfCurrency || ''}
            rightLabel={
               <div className='mb-0 pb-0'>
                  <p className="text-shiga-dark flex items-center font-inter text-xs mb-0">
                     <span className="mr-1">
                        <IconWallet />
                     </span>

                     You have:&nbsp;
                     <span className="text-shiga-purple font-medium">
                        {formattedWalletBalance}
                     </span>
                     &nbsp;available
                  </p>
               </div>
            }
         />

         <div className="flex gap-2 items-center px-8 relative">
            <span className="border h-24 bg-[#FAFAFA]"></span>
            <div className="absolute flex gap-2 left-[22px] items-center">
               <img src={subtract} alt="subtract icon" className="w-4 h-4" />
               <p className="text-shiga-gray text-sm">
                  <span className="text-shiga-dark-100 font-medium">
                     {(!transferAmount || isNaN(transferFeeData?.data?.feeAmount)) ? 0 : feeLoading
                        ? "..."
                        : Number(transferFeeData?.data?.feeAmount)?.toLocaleString() || 0}{" "}
                     {trfCurrency}&nbsp;
                  </span>
                  {isNgnTransfer ? 'Fee' : 'Transfer fee'}
               </p>
            </div>
         </div>

         <div className="ml-[8px]">
            <div className="flex items-center gap-2.5">
               <img src={userIcon} alt="user icon" className="w-12 h-12" />
               <div className="flex flex-col">
                  <span className="text-sm text-shiga-blackk capitalize">
                     {accountName?.toLowerCase()}
                     <span className="text-shiga-gray lowercase">&nbsp;gets</span>
                  </span>
                  <span className="text-shiga-blacks text-lg font-medium">
                     {!Number(summedAmount) ? transferAmount || 0.00
                        : Number(summedAmount)?.toLocaleString()}&nbsp;{trfCurrency}
                  </span>
               </div>
            </div>
         </div>

         <div>
            <CustomNotePad
               noteValue={transferNote}
               setNoteValue={setTransferNote}
               noteIsRequired={false}
               notePadLeftLabel='Add a Note'
               notePadPlaceholder='Enter note'
            />
         </div>

         <div className="pt-14">
            <ShigaButton
               darkBg
               fullWidth
               text='Continue'
               onClick={onProceed}
               disabled={proceedBtnDisabled}
            />
         </div>

      </div>
   )
}

export default TransferAmountView