import { useState } from "react";
import DeleteCardModal from "./DeleteCard";
import CardRenameModal from "./RenameCard";

import { useNavigate } from "react-router-dom";
import { DataTable } from "components/data-table/data-table";
import { CardColumns } from "./table/CardColumn";
import { useToggle } from "hooks/useToggle";
import CardDetailsModal from "./CardDetails";
import FundCardModal from "./FundCard";
import { DataTableHeader } from "components/data-table/data-table-header";
import useToolkit from "hooks/useToolkit";
import { selectVirtualCard } from "store/card/action";

export const CardsTable: React.FC<{
  cards: any[];
}> = ({ cards }) => {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [showFundCard, toggleShowFund] = useToggle();
  const [showDetails, toggleShowDetails] = useToggle();
  // eslint-disable-next-line
  const [filter, setFilter] = useState("");
  const { dispatch } = useToolkit();

  function handleSelected(item: any) {
    dispatch(selectVirtualCard(item));
    navigate(`/cards/${item.last4}`);
  }

  return (
    <>
      <div>
        <div className="mt-6">
          <DataTable
            data={cards}
            columns={CardColumns}
            renderToolbar={(table) => (
              <DataTableHeader
                filterOptions={["All", "Active", "Locked"]}
                setFilter={setFilter}
                placeholder="Search cards"
                table={table}
              />
            )}
            meta={{
              handleShowDelete: () => setShowDelete(!showDelete),
              handleSelected,
              handleShowDetails: () => toggleShowDetails(),
              handleRename: () => setShowRename(!showRename),
              handleFund: () => toggleShowFund(),
            }}
            handleRowClick={(row) => handleSelected(row)}
          />
        </div>
      </div>
      <DeleteCardModal isOpen={showDelete} setIsOpen={setShowDelete} />
      <CardRenameModal isOpen={showRename} setIsOpen={setShowRename} />
      <CardDetailsModal isOpen={showDetails} setIsOpen={toggleShowDetails} />
      <FundCardModal isOpen={showFundCard} setIsOpen={toggleShowFund} />
    </>
  );
};

export default CardsTable;
