import EmptyOperators from "assets/images/icons/user-avatar.svg";
import ShigaButton from "components/common/form/ShigaButton";
import useToolkit from "hooks/useToolkit";
import { useState } from "react";
import NewOperatorModal from "./NewOperatorModal";
import EmptyUI from "components/common/EmptyUi";

type Props = {}

const OperatorsHomepage = (props: Props) => {
   // eslint-disable-next-line
   const { isLocalEnv } = useToolkit();
   const [addOperatorOpen, setAddOperatorOpen] = useState(false);

   const getOperators = () => { }


   return (
      <div className='w-full py-12 flexed flex-col'>

         <EmptyUI
            icon={EmptyOperators}
            header="No Operators Yet"
            subheader="All operators you generate for your devices on terminals would appear here"
         />

         <ShigaButton
            darkBg
            text="Generate an Operator"
            onClick={() => setAddOperatorOpen(true)}
         />

         <NewOperatorModal
            isOpen={addOperatorOpen}
            getOperators={getOperators}
            setIsOpen={setAddOperatorOpen}
         />
      </div>
   )
}

export default OperatorsHomepage