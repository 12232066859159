import { ReactComponent as IconTest } from "assets/images/icons/env-test.svg";
import { ReactComponent as IconLive } from "assets/images/icons/env-live.svg";
import { useState } from "react";
import Maybe from "components/common/Maybe";
import { Loader } from "lucide-react";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import { useNavigate } from "react-router-dom";
import PATHS from "NavigationRoute";
import { toggleUserEnv } from "store/auth/user/action";

const EnvironmentToggle = () => {
   const navigate = useNavigate();
   const { dispatch, useSelector } = useToolkit();
   const { toggleEnvLoading: isLoading } = useSelector((state: RootState) => state.auth.userPersist)
   // eslint-disable-next-line
   const [isActive, setIsActive] = useState(false);

   const onToggle = () => {
      // setIsActive(!isActive)
      navigate(PATHS.DASHBOARD);
      dispatch(toggleUserEnv({ env: 'live' }))
   }

   return (
      <div className="flex items-center space-x-2">

         <span className="text-lg font-medium text-shiga-dark-main">
            {isActive ? 'Live mode' : 'Test mode'}
         </span>

         <Maybe condition={isLoading}>
            <Loader
               size='1.5rem'
               className="animate-spin"
            />
         </Maybe>

         <Maybe condition={!isLoading}>
            <div className="p-[2px] rounded-xl bg-shiga-gray-50">

               <div className="bg-white p-[2px] rounded-xl">

                  <div className={`relative inline-flex items-center cursor-pointer p-1 transition-colors duration-300 ease-in-out 
                  ${isActive ? 'bg-shiga-green' : 'bg-shiga-gray-50'} rounded-xl w-[52px] h-[25px]`}
                     onClick={onToggle}>

                     <div
                        className={`h-6 w-6 rounded-full shadow-md transform transition-transform ease-in-out duration-300
                  ${isActive ? 'translate-x-6' : 'translate-x-0'}`}>
                        {isActive ? (
                           <IconLive className="inline-block align-middle" />
                        ) : (
                           <IconTest className="inline-block align-middle" />
                        )}
                     </div>
                  </div>

               </div>

            </div>
         </Maybe>
      </div>
   );
};


export default EnvironmentToggle;
