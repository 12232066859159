
const TiledProductImage = ({ data }: any) => {

   return (
      <div className="h-[50px] w-[50px] flex flex-wrap justify-between !rounded-xl overflow-hidden">
         {data?.map((item: any, idx: number) => {

            const isFirstItem = idx === 0;
            const isSecondItem = idx === 1;

            return (
               <div key={idx} className={`w-[48%] h-[50%] ${(isFirstItem || isSecondItem) ? 'mb-[2px]' : ''}`}>
                  <img
                     alt="product"
                     src={item?.url}
                     className="object-cover"
                  />
               </div>
            )
         })}

      </div>
   )
}

export default TiledProductImage