import EmptyOperators from "assets/images/icons/user-avatar.svg";
import ShigaButton from "components/common/form/ShigaButton";
import useToolkit from "hooks/useToolkit";
import { useState } from "react";
import EmptyUI from "components/common/EmptyUi";
import NewAgentModal from "./NewAgentModal";

type Props = {}

const AgentsHomepage = (props: Props) => {
   // eslint-disable-next-line
   const { isLocalEnv } = useToolkit();
   const [addAgentOpen, setAddAgentOpen] = useState(false);

   const getOperators = () => { }


   return (
      <div className='w-full py-12 flexed flex-col'>

         <EmptyUI
            icon={EmptyOperators}
            header="No Agent Yet"
            subheader="All agents you generate to create and assign cards on cards.payshiga.com would appear here"
         />

         <ShigaButton
            darkBg
            text="Generate an Agent"
            onClick={() => setAddAgentOpen(true)}
         />

         <NewAgentModal
            isOpen={addAgentOpen}
            getOperators={getOperators}
            setIsOpen={setAddAgentOpen}
         />
      </div>
   )
}

export default AgentsHomepage