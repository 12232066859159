import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import DynamicHeadTag from 'components/common/DynamicHeadTag';
import DevicesHomepage from './tabs/devices/DevicesHomepage';
import OperatorsHomepage from './tabs/operators/OperatorsHomepage';
import AgentsHomepage from './tabs/agents/AgentsHomepage';


const TerminalsHomepage = () => {
   const { useState, isLocalEnv } = useToolkit();
   const [activeTab, setActiveTab] = useState('devices');

   const isDevices = activeTab === 'devices';
   const isOperators = activeTab === 'operators';
   const isAgents = activeTab === 'agents';

   const tabs = [
      { title: 'Devices', value: 'devices' },
      { title: 'Operators', value: 'operators' },
      { title: 'Agents', value: 'agents' },
   ]

   return (

      <>

         <div className='dashboard-page-container'>

            <DynamicHeadTag
               headerText="Terminals"
            />

            <div className="w-full flex items-center space-x-7 pb-6 border-b border-shiga-gray-75 mb-10">
               {tabs.map((item: any) => {
                  const isActive = activeTab === item?.value;

                  return (
                     <button
                        key={item?.value}
                        onClick={() => isLocalEnv ? setActiveTab(item.value) : null}
                        className={`cursor-not-allowed active:scale-90 transition-transform font-ojah 
                        ${isActive ? 'bg-shiga-dark-100 py-2 px-3.5 rounded-full text-white' : 'text-shiga-gray-100'}`}>
                        {item?.title}
                     </button>
                  )
               })}
            </div>

            <Maybe condition={isDevices}>
               <DevicesHomepage />
            </Maybe>

            <Maybe condition={isOperators}>
               <OperatorsHomepage />
            </Maybe>

            <Maybe condition={isAgents}>
               <AgentsHomepage />
            </Maybe>

         </div>

      </>
   )
}

export default TerminalsHomepage