import { DashboardWallet } from "types/wallet";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function resetEnvProps() {
    return {
        type: actions.RESET_ENV_PROPS,
    }
}

export function toggleUserEnv(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiToggleEnv,
            method: "post",
            data: payload,
            onStart: actions.TOGGLE_ENV_START,
            onSuccess: actions.TOGGLE_ENV_DONE,
            onError: actions.TOGGLE_ENV_FAILED
        },
    }
}

export const setTransferWallet = (payload: DashboardWallet) => {
    return {
        type: actions.SET_TRANSFER_WALLET,
        payload: payload,
    };
};


export function updateKybStep(step: number) {
    return {
        type: actions.UPDATE_KYB_STEP,
        payload: step,
    }
}

export function showIdleTimer() {
    return {
        type: actions.SHOW_IDLE_TIMER,
        payload: {},
    }
}
export function hideIdleTimer() {
    return {
        type: actions.HIDE_IDLE_TIMER,
        payload: {},
    }
}

export function getRefreshToken(params: {
    refreshToken: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetRefreshToken,
            method: "post",
            data: params,
            onStart: actions.GET_REFRESH_TOKEN_START,
            onSuccess: actions.GET_REFRESH_TOKEN_DONE,
            onError: actions.GET_REFRESH_TOKEN_FAILED
        },
    }
}


export function login(params: {
    email: string;
    password: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLoginUser,
            method: "post",
            data: params,
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED
        },
    }
}

export function loginWithMFA(params: {
    email: string;
    token: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLoginUserWithMFA,
            method: "post",
            data: params,
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED
        },
    }
}

export function saveLoginForm(params: any) {
    return {
        type: actions.SAVE_LOGIN_USER_FORM,
        payload: params,
    }
}

export function forgotPassword(params: {
    email: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiForgotPassword,
            method: "post",
            data: params,
            onStart: actions.FORGOT_PASSWORD_START,
            onSuccess: actions.FORGOT_PASSWORD,
            onError: actions.FORGOT_PASSWORD_FAILED
        }
    }
}

export function resetForgotPassword() {
    return {
        type: actions.FORGOT_PASSWORD_RESET,
        payload: {},
    }
}

export function resetPassword(params: {
    token: string;
    password: string;
    confirmPassword: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResetPassword,
            method: "post",
            data: params,
            onStart: actions.RESET_PASSWORD_START,
            onSuccess: actions.RESET_PASSWORD,
            onError: actions.RESET_PASSWORD_FAILED
        }
    }
}

export function resetResetPassword() {
    return {
        type: actions.RESET_PASSWORD_RESET,
        payload: {},
    }
}

export function sendOtp(params: {
    identifier: string;
    type: "EMAIL" | string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSendOtp,
            method: "post",
            data: params,
            onStart: actions.SEND_OTP_EMAIL_START,
            onSuccess: actions.SEND_OTP_EMAIL,
            onError: actions.SEND_OTP_EMAIL_FAILED
        }
    }
}

export function resetSendOtp() {
    return {
        type: actions.SEND_OTP_EMAIL_RESET,
        payload: {},
    }
}

export function resendOtp(params: {
    identifier: string;
    type: "EMAIL" | string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResendOtp,
            method: "post",
            data: params,
            onStart: actions.RESEND_OTP_EMAIL_START,
            onSuccess: actions.RESEND_OTP_EMAIL,
            onError: actions.RESEND_OTP_EMAIL_FAILED
        }
    }
}

export function resetResendOtp() {
    return {
        type: actions.RESEND_OTP_EMAIL_RESET,
        payload: {},
    }
}

export function verifyOtp(params: {
    code: string;
    identifier: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiVerifyOtp,
            method: "post",
            data: params,
            onStart: actions.VERIFY_OTP_EMAIL_START,
            onSuccess: actions.VERIFY_OTP_EMAIL,
            onError: actions.VERIFY_OTP_EMAIL_FAILED
        }
    }
}

export function resetVerifyOtp() {
    return {
        type: actions.VERIFY_OTP_EMAIL_RESET,
        payload: {},
    }
}

export function saveRegisterForm(params: {
    accountType: string;
    [k: string]: any;
} | {}) {
    return {
        type: actions.SAVE_REGISTER_USER_FORM,
        payload: params,
    }
}

export function register(params: {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    accountType: string,
    business: {
        country: string,
        name: string,
        category: string,
        size: string,
        annualVolume: string,
    },
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRegisterUser,
            method: "post",
            data: params,
            onStart: actions.REGISTER_USER_START,
            onSuccess: actions.REGISTER_USER,
            onError: actions.REGISTER_USER_FAILED
        }
    }
}

export function resetRegister() {
    return {
        type: actions.REGISTER_USER_RESET,
        payload: {},
    }
}

export function updateUserInfo(params: {
    businessProfile?: {
        [k: string]: any
    };
    userProfile?: {
        [k: string]: any
    }
}) {
    return {
        type: actions.UPDATE_USER_INFO,
        payload: params,
    }
}

// export function logoutUser(params) {
//     return {
//         type: actions.LOGOUT_USER,
//         payload: {
//             params
//         }
//     }
// }

export function logoutUser() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLogoutUser,
            method: "delete",
            data: {},
            onStart: actions.LOGOUT_USER_START,
            onSuccess: actions.LOGOUT_USER,
            onError: actions.LOGOUT_USER,
        }
    }
}
export function resetLoginData() {
    return {
        type: actions.RESET_LOGIN_DATA
    }
}
