import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export const resetPaymentLinkProps = () => {
  return {
    type: actions.RESET_PAYMENT_LINK_PROPS,
  };
};


export function createPaymentLink(payload: any) {

  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiCreatePaymentLink,
      method: "post",
      data: payload,
      onStart: actions.CREATE_PAYMENT_LINK_START,
      onSuccess: actions.CREATE_PAYMENT_LINK_DONE,
      onError: actions.CREATE_PAYMENT_LINK_FAILED,
    },
  };
}


export function getAllowedCurrencies() {

  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetAllowedCurrencies,
      method: "get",
      data: {},
      onStart: actions.GET_ALLOWED_CURRENCIES_START,
      onSuccess: actions.GET_ALLOWED_CURRENCIES_DONE,
      onError: actions.GET_ALLOWED_CURRENCIES_FAILED,
    },
  };
}

export const selectSettlement = (payload: any) => {
  return {
    type: actions.SELECT_SETTLEMENT,
    payload: payload,
  };
};

export function getSettlementDetails(payload: any) {

  const id = payload?.id;
  const params = payload?.params

  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetSettlementDetails}${id}/details` + ObjectToQueryString(params),
      method: "get",
      data: {},
      onStart: actions.GET_SETTLEMENT_DETAILS_START,
      onSuccess: actions.GET_SETTLEMENT_DETAILS_DONE,
      onError: actions.GET_SETTLEMENT_DETAILS_FAILED,
    },
  };
}

export function getAllSettlements(params?: any) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetAllSettlements + ObjectToQueryString(params),
      method: "get",
      data: {},
      onStart: actions.GET_ALL_SETTLEMENTS_START,
      onSuccess: actions.GET_ALL_SETTLEMENTS_DONE,
      onError: actions.GET_ALL_SETTLEMENTS_FAILED,
    },
  };
}

export function getTransactions(params?: any) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetTransactions + ObjectToQueryString(params),
      method: "get",
      data: {},
      onStart: actions.GET_TRANSACTIONS_START,
      onSuccess: actions.GET_TRANSACTIONS,
      onError: actions.GET_TRANSACTIONS_FAILED,
    },
  };
}
