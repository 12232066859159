import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { Input } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { generateSecretKey } from 'store/webhooks/action';
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";


type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   getOperators: () => void;
}

const defaultForm = {
   password: '',
}

const NewAgentModal = ({ isOpen, setIsOpen, getOperators }: Props) => {

   const [form, setForm] = useState(defaultForm)
   const { dispatch, useSelector } = useToolkit();
   const { generateApiKeyLoading } = useSelector((state: RootState) => state.webhook)

   const formIsValid = form.password?.length > 0;

   const tips = [
      'This action will create a new agent and generate a token for login on ',
      'Once an agent is created for your business, it cannot be edited - only deleted.'
   ]

   const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(generateSecretKey(form))
   }

   useEffect(() => {
      // if (generateApiKeyData?.success === true) {
      setForm(defaultForm)
      setIsOpen(false);
      getOperators()

      // setTimeout(() => {
      //    dispatch(resetWebhookParams())
      // }, 1500);
      // }
      // eslint-disable-next-line
   }, [])

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Generate an Agent'
         isButtonLoading={false}>

         <div className='w-full relative'>

            <div style={{ background: ' linear-gradient(0deg, #FFFBF5, #FFFBF5), #FFF7EB' }}
               className='p-4 rounded-2xl space-y-4 mb-8'>
               {tips.map((item, idx) => (
                  <span key={idx} className="flex items-start">

                     <div className="pt-1 mr-2.5">
                        <IconInfo className="w-4 h-4" />
                     </div>

                     <p className="text-shiga-brown-10">
                        {item}
                        {idx === 0 &&
                           <span className='font-medium underline'>
                              cards.payshiga.com
                           </span>
                        }
                     </p>

                  </span>
               ))}
            </div>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-y border-shiga-gray-50 space-y-7">

                  <Input
                     type="password"
                     required={true}
                     value={form.password}
                     placeholder='Enter password'
                     label='Enter your account password to confirm'
                     onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                  />

               </div>

               <div className="mt-5">
                  <ShigaButton
                     darkBg
                     fullWidth
                     type='submit'
                     text="Generate Agent"
                     disabled={!formIsValid}
                     loading={generateApiKeyLoading}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default NewAgentModal