
export const GET_CARD_TRANSACTIONS_START = "GET_CARD_TRANSACTIONS_START"
export const GET_CARD_TRANSACTIONS_DONE = "GET_CARD_TRANSACTIONS_DONE"
export const GET_CARD_TRANSACTIONS_FAILED = "GET_CARD_TRANSACTIONS_FAILED"

export const DELETE_CARD_START = "DELETE_CARD_START"
export const DELETE_CARD_DONE = "DELETE_CARD_DONE"
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED"

export const RENAME_CARD_START = "RENAME_CARD_START"
export const RENAME_CARD_DONE = "RENAME_CARD_DONE"
export const RENAME_CARD_FAILED = "RENAME_CARD_FAILED"

export const TOGGLE_CARD_STATUS_START = "TOGGLE_CARD_STATUS_START"
export const TOGGLE_CARD_STATUS_DONE = "TOGGLE_CARD_STATUS_DONE"
export const TOGGLE_CARD_STATUS_FAILED = "TOGGLE_CARD_STATUS_FAILED"

export const FUND_CARD_START = "FUND_CARD_START"
export const FUND_CARD_DONE = "FUND_CARD_DONE"
export const FUND_CARD_FAILED = "FUND_CARD_FAILED"

export const SELECT_VIRTUAL_CARD = "SELECT_VIRTUAL_CARD"

export const GET_CARD_DETAILS_START = "GET_CARD_DETAILS_START"
export const GET_CARD_DETAILS_DONE = "GET_CARD_DETAILS_DONE"
export const GET_CARD_DETAILS_FAILED = "GET_CARD_DETAILS_FAILED"

export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_START = "GET_CARDS_START";
export const GET_CARDS_FAILED = "GET_CARDS_FAILED";
export const GET_CARDS_RESET = "GET_CARDS_RESET";

export const CREATE_CARDS = "CREATE_CARDS";
export const CREATE_CARDS_START = "CREATE_CARDS_START";
export const CREATE_CARDS_FAILED = "CREATE_CARDS_FAILED";
export const CREATE_CARDS_RESET = "CREATE_CARDS_RESET";

export const LOCK_CARD = "LOCK_CARD";
export const LOCK_CARD_START = "LOCK_CARD_START";
export const LOCK_CARD_FAILED = "LOCK_CARD_FAILED";
export const LOCK_CARD_RESET = "LOCK_CARD_RESET";

export const UNLOCK_CARD = "UNLOCK_CARD";
export const UNLOCK_CARD_START = "UNLOCK_CARD_START";
export const UNLOCK_CARD_FAILED = "UNLOCK_CARD_FAILED";
export const UNLOCK_CARD_RESET = "UNLOCK_CARD_RESET";
