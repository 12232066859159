import { PageHeading } from "components/common/PageHeading";
import { useEffect, useState } from "react";
import { PaymentLinkTable } from "./table/PaymentLinkTable";
import { usePaymentLinkStore } from "zustand-store/payments/links";
import { processParams } from "utils/processParams";
import useUrlQueryParams from "utils/useUrlQueryParams";
import { useLocation } from "react-router-dom";
import ShigaButton from "components/common/form/ShigaButton";
import InlinePaymentLinkCreationLayout from "components/updates/payment-link/layout/InlinePaymentLinkCreationLayout";

export const PaymentLinks = () => {
  const [loading, setLoading] = useState(false);
  const { urlQueryParams } = useUrlQueryParams();
  const location = useLocation();
  const { fetchPaymentLinks, paymentLinks } =
    usePaymentLinkStore();
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 9,
  });

  const [isCreationModalOopen, setIsCreationModalOopen] = useState(false);

  const fetchLinks = async () => {
    setLoading(true);
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
    params = processParams(urlQueryParams, params, "status", "string", "", "status");
    params = processParams(urlQueryParams, params, "search", "string", "", "search");
    params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
    params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

    setListParams(params);
    fetchPaymentLinks(params);

    try {
      fetchPaymentLinks(params);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLinks();
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="w-full">
      <div className="pt-5 flex items-center justify-between">
        <PageHeading title="Payment Link" />

        <ShigaButton
          darkBg
          text="Create a link"
          className="!whitespace-nowrap"
          onClick={() => setIsCreationModalOopen(true)}
        />
      </div>

      <PaymentLinkTable paymentLinks={paymentLinks} loading={loading} />

      <InlinePaymentLinkCreationLayout
        fetchPaymentLinks={fetchLinks}
        sessionModalOpen={isCreationModalOopen}
        setSessionModalOpen={setIsCreationModalOopen}
      />
    </div>
  );
};
