import store from "store/store";
import * as actions from "./actionTypes";
import * as config from "data/config";


let customHeaders: any = { "Content-Type": "multipart/form-data" };

store.subscribe(() => {
    const info = store.getState().auth.userPersist.userInfo?.businessProfile;

    const key = info?.apiKeys?.secretKey;

    if (key && key?.length > 0) {
        customHeaders = { ...customHeaders, "api-key": key }
    }

}
)

export function uploadFile(data: FormData) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiFileUpload,
            method: "post",
            data,
            onStart: actions.FILE_UPLOAD_START,
            onSuccess: actions.FILE_UPLOAD,
            onError: actions.FILE_UPLOAD_FAILED,
        },
    }
}

export function uploadOjahProductImage(data: FormData) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.ojahBaseUrl,
            url: config.apiUploadOjahProductImage,
            method: "post",
            customHeaders,
            data,
            onStart: actions.FILE_UPLOAD_START,
            onSuccess: actions.FILE_UPLOAD,
            onError: actions.FILE_UPLOAD_FAILED,
        },
    }
}
