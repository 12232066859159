import { useContext } from 'react'
import ContextProps from './ContextProps';

const usePaymentLinksProvider = () => {
   const context = useContext(ContextProps)

   if (!context) {
      throw new Error('usePaymentLinksProvider must be used within a MyProvider');
   }
   return context;
}

export default usePaymentLinksProvider