import React, { Dispatch, SetStateAction } from "react";

interface ContextPropsType {
   paymentLinkType: string;
   setPaymentLinkType: Dispatch<SetStateAction<string>>;
   isCollectionPayment: boolean;
   isTuitionPayment: boolean;

   paymentLinkStep: number;
   incrementPaymentLinkStep: () => void;
   navigatePaymentLinkStep: (value: number) => void;
   decrementPaymentLinkStep: () => void;

   logoFile: any;
   endPaymentLinkSession: () => void;
   setLogoFile: Dispatch<SetStateAction<any>>;
};

const ContextProps = React.createContext<ContextPropsType | null>(null)

export default ContextProps