
export const RESET_PAYMENT_LINK_PROPS = "RESET_PAYMENT_LINK_PROPS";

export const CREATE_PAYMENT_LINK_START = "CREATE_PAYMENT_LINK_START";
export const CREATE_PAYMENT_LINK_DONE = "CREATE_PAYMENT_LINK_DONE";
export const CREATE_PAYMENT_LINK_FAILED = "CREATE_PAYMENT_LINK_FAILED";

export const GET_ALLOWED_CURRENCIES_START = "GET_ALLOWED_CURRENCIES_START";
export const GET_ALLOWED_CURRENCIES_DONE = "GET_ALLOWED_CURRENCIES_DONE";
export const GET_ALLOWED_CURRENCIES_FAILED = "GET_ALLOWED_CURRENCIES_FAILED";

export const SELECT_SETTLEMENT = "SELECT_SETTLEMENT";

export const GET_SETTLEMENT_DETAILS_START = "GET_SETTLEMENT_DETAILS_START";
export const GET_SETTLEMENT_DETAILS_DONE = "GET_SETTLEMENT_DETAILS_DONE";
export const GET_SETTLEMENT_DETAILS_FAILED = "GET_SETTLEMENT_DETAILS_FAILED";

export const GET_ALL_SETTLEMENTS_START = "GET_ALL_SETTLEMENTS_START";
export const GET_ALL_SETTLEMENTS_DONE = "GET_ALL_SETTLEMENTS_DONE";
export const GET_ALL_SETTLEMENTS_FAILED = "GET_ALL_SETTLEMENTS_FAILED";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";
export const GET_TRANSACTIONS_RESET = "GET_TRANSACTIONS_RESET";
