import { useEffect, useState } from "react";
import { ButtonGroupSelector } from "components/common/form"
import LayoutFlowWrapper from "../layout/LayoutFlowWrapper"
import Maybe from "components/common/Maybe";
import FixedAmountLink from "./FixedAmountLink";
import { getDashboardWallets } from "store/dashboard/action";
import useToolkit from "hooks/useToolkit";
import { getDashboard } from "store/dashboard/reducer";
import { useAppSelector } from "store/hooks";
import { getAllowedCurrencies } from "store/transactions/action";
import FlexibleAmountLink from "./FlexibleAmountLink";

const CollectionLinkHomepage = () => {
   const { dispatch } = useToolkit();
   const [linkType, setLinkType] = useState('FIXED');
   const { wallets } = useAppSelector(getDashboard);

   const isFixed = linkType === 'FIXED';
   const isFlexible = linkType === 'FLEXIBLE';

   const toggleOptions = [
      {
         label: "Fixed Amount",
         value: "FIXED",
         selected: true,
      },
      {
         label: "Flexible",
         value: "FLEXIBLE",
         selected: false,
      },
   ]

   useEffect(() => {
      dispatch(getDashboardWallets());
      dispatch(getAllowedCurrencies());
      // eslint-disable-next-line
   }, [])

   return (
      <LayoutFlowWrapper
         title="Add details for this payment"
         subtitle="Provide more information about this payment">

         <div className="w-full p-1 bg-gray-50 rounded-full">
            <ButtonGroupSelector
               design="slide-tab-toggle"
               selectOptions={toggleOptions}
               onValueChange={(e: any) => setLinkType(e)}
            />
         </div>

         <div className="pt-7">
            <Maybe condition={isFixed}>
               <FixedAmountLink
                  wallets={wallets?.data}
               />
            </Maybe>

            <Maybe condition={isFlexible}>
               <FlexibleAmountLink
                  wallets={wallets?.data}
               />
            </Maybe>
         </div>

      </LayoutFlowWrapper>
   )
}

export default CollectionLinkHomepage