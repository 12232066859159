import { useEffect } from 'react'
import CenterModal from 'components/common/CenterModal'
import useToolkit from 'hooks/useToolkit';
import useTransfersProvider from '../../context/useTransfersProvider';
import { deleteBulkTransferItem } from 'store/transfer/action';


const DeleteBulkTransferRecipient = ({ isOpen, setIsOpen, record, refetchRecipients }) => {
   const { dispatch, useSelector } = useToolkit();
   const { bulkRecipients } = useTransfersProvider();
   const { deleteBulkTrfItemLoading: deleteLoading, deleteBulkTrfItemData: deleteData } = useSelector((state) => state.transfer)

   const handleDelete = () => {
      const payload = {
         id: bulkRecipients?.data?.bulkTransfer?.id,
         accountId: record?.id
      }
      dispatch(deleteBulkTransferItem(payload))
   }

   useEffect(() => {
      if (deleteData?.success === true) {
         setIsOpen(false);
         refetchRecipients();
      }
      // eslint-disable-next-line
   }, [deleteData])

   return (
      <CenterModal
         hasButton
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         isButtonLoading={deleteLoading}
         buttonClassName='bg-red-500'
         onButtonClick={handleDelete}
         headerTitle='Delete this recipient'
         buttonText={deleteLoading ? 'Deleting...' : 'Yes, Delete'}>

         <div className="w-full">

            <div className="w-full bg-shiga-gray-40 p-4 pb-2 my-4 rounded-xl">

               <div className="w-full flex items-start justify-between font-medium mb-3">
                  <p className="text-sm text-shiga-gray-300 whitespace-nowrap">Account Name</p>
                  <p className="text-sm text-shiga-dark-100 text-right">
                     {record?.accountName}
                  </p>
               </div>

               <div className="w-full flex items-center justify-between font-medium py-3 border-y border-shiga-gray-50 mb-3">
                  <p className="text-sm text-shiga-gray-300">Account Number</p>
                  <p className="text-sm text-shiga-dark-100 flex">
                     {record?.accountNumber}
                  </p>
               </div>

               <div className="w-full flex items-center justify-between font-medium mb-2">
                  <p className="text-sm text-shiga-gray-300">Bank Name</p>
                  <p className="text-sm text-shiga-dark-100">
                     {record?.bankName}
                  </p>
               </div>

            </div>

         </div>

      </CenterModal>
   )
}

export default DeleteBulkTransferRecipient