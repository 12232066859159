import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { ReactComponent as IconInfo } from "assets/images/icons/info-round-gray.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { Input, SelectInput } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { generateSecretKey, resetWebhookParams } from 'store/webhooks/action';

type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   getApiKeys: () => void;
}

const NewSecretKeyModal = ({ isOpen, setIsOpen, getApiKeys }: Props) => {
   const { dispatch, useSelector, mfaEnabled } = useToolkit();
   const [form, setForm] = useState({ expirationOption: '', plainPassword: '' })
   const { generateApiKeyLoading, generateApiKeyData } = useSelector((state: RootState) => state.webhook)
   const formIsValid = form.expirationOption?.length > 0 && (mfaEnabled ? form.plainPassword?.length === 6 : form.plainPassword?.length >= 10);

   const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(generateSecretKey(form))
   }

   const expiryOptions = [
      { label: 'Never Expires', value: 'Never Expires' },
      { label: 'Expires Immediately', value: 'Expires immediately' },
      { label: 'Expires in 30mins', value: 'Expires in 30 mins' },
      { label: 'Expires in 2hrs', value: 'Expires in 2hrs' },
   ]

   useEffect(() => {
      if (generateApiKeyData?.success === true) {
         setForm({ expirationOption: '', plainPassword: '' })
         setIsOpen(false);
         getApiKeys()

         setTimeout(() => {
            dispatch(resetWebhookParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [generateApiKeyData])

   return (
      <CenterModal
         noHeader
         headerTitle=''
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         isButtonLoading={false}>

         <div className='w-full relative'>

            <div className="w-full flex-between items-start absolute -top-3.5">
               <IconInfo />

               <button onClick={() => setIsOpen(false)}
                  className="w-8 h-8 bg-shiga-gray-40 flex rounded-full">
                  <IconClose className="m-auto svg-stroke-gray" />
               </button>
            </div>

            <div className="min-h-[150px] pt-16">

               <p className="font-medium lg:text-2xl font-ojah mb-5">
                  Generate a new Secret Key
               </p>

               <p className="text-shiga-dark-100 text-lg border-b border-shiga-gray-50 pb-4">
                  This action will terminate both your

                  <span className={`font-medium transition-colors duration-300 ${formIsValid && 'text-shiga-green-100'}`}>
                     &nbsp;LIVE&nbsp;
                  </span>

                  and test secret keys.
                  To keep processing API calls through Payshiga,
                  you will need to update your website and servers with the new keys.
               </p>

            </div>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-b border-shiga-gray-50">

                  <SelectInput
                     required={true}
                     value={form.expirationOption}
                     label="How soon should your old keys expire?"
                     onChange={(e: any) => setForm({ ...form, expirationOption: e.target.value })}>
                     <option value="">Select</option>

                     {expiryOptions.map((item) => (
                        <option value={item.value} key={item.value}>
                           {item.label}
                        </option>
                     ))}
                  </SelectInput>

                  <Input
                     required={true}
                     type="password"
                     value={form.plainPassword}
                     placeholder={`Enter ${mfaEnabled ? '2FA code' : 'password'}`}
                     label={`Enter your account's ${mfaEnabled ? '2FA code' : 'password'} to confirm`}
                     onChange={(e: any) => setForm({ ...form, plainPassword: e.target.value })}
                  />
               </div>

               <div className="mt-5">
                  <ShigaButton
                     danger
                     fullWidth
                     type='submit'
                     text="Generate new secret keys"
                     loading={generateApiKeyLoading}
                     className={`transition-colors duration-300 ${formIsValid ? '!bg-opacity-100' : '!bg-opacity-30'}`}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default NewSecretKeyModal