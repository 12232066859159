import { SelectInput, ButtonLoader, Input } from "components/common/form";
import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as PDFIcon } from "assets/images/icons/pdf-icon.svg";
import { ReactComponent as CSVIcon } from "assets/images/icons/csv-icon.svg";

import { RadioGroup } from "@headlessui/react";
import { Form, FormControl, FormField, FormItem, FormMessage } from "components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
// import { zodValidation } from "utils/zodValidate";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { generateAccountStatement } from "lib/document";
import { DashboardWallet } from "types/wallet";
import { useState } from "react";
// import { pdf } from "@react-pdf/renderer";
// import { AccountStatement } from "lib/document/AccountStatement";
interface GenerateStatementProps {
  open: boolean;
  closeModal: () => void;
  currentWallet: string;
  wallets: DashboardWallet[];
}

const plans = [
  {
    name: ".PDF",
    value: "PDF",
    icon: PDFIcon,
  },
  {
    name: ".CSV",
    value: "CSV",
    icon: CSVIcon,
  },
];

const formSchema = z.object({
  // startDate: zodValidation.startDate,
  // endDate: zodValidation.endDate,
  fileType: z.string(),
  currency: z.string(),
})

type FormType = z.infer<typeof formSchema>;

const SelectedFileType: React.FC<{
  plan: {
    icon: any;
    name: string;
  }
  checked: boolean;
}> = ({ plan, checked }) => (
  <div className="flex w-full items-center justify-between">
    <div className="flex items-center gap-2">
      <RadioGroup.Label as="div">
        <plan.icon />
      </RadioGroup.Label>
      <RadioGroup.Description
        as="span"
        className="font-medium text-[16px]"
      >
        {plan.name}
      </RadioGroup.Description>
    </div>
    {checked && (
      <div className="shrink-0 bg-black rounded-full">
        <CheckIcon className="size-5" />
      </div>
    )}
  </div>
)

export const GenerateStatementModal: React.FC<GenerateStatementProps> = ({
  open,
  closeModal,
  currentWallet,
  wallets
}) => {
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fileType: "PDF",
    }
  });

  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // const startDate = form.watch("startDate")


  const onSubmit = ({ fileType, currency }: FormType) => {
    // if (start.getTime() > end.getTime()) {
    //   form.setError("endDate", {
    //     message: "End Date must be ahead of Start Date",
    //     type: "valueAsDate"
    //   })
    // } else if (end.getTime() > (new Date()).getTime()) {
    //   form.setError('endDate', {
    //     message: "End Date cannot be ahead of current Date"
    //   })
    // } else {

    setIsLoading(true);

    generateAccountStatement(({
      walletId: currency,
      end: end.toString(),
      start: start.toString(),
      fileType
    })).then(data => {
      setIsLoading(false);
      toast.success("Account statement has been requested, please check your mail")
      closeModal()
    }).catch(err => {
      setIsLoading(false);
      toast.error(`Error: ${err.message}`)
    })
    // }
    // You can uncomment to download pdf account statement from the FE
    // const blob = pdf(<AccountStatement/>).toBlob();
    // blob.then(data => {
    //   const blobUrl = window.URL.createObjectURL(data);
    //   const a = document.createElement("a");
    //   a.href = blobUrl;
    //   a.download = "Account Statement";
    //   document.body.appendChild(a);
    //   a.click();
    //   window.URL.revokeObjectURL(blobUrl);
    //   document.body.removeChild(a);
    // })
  }


  // const handleDateDisable = (date: Date) => {
  //   if (startDate) {
  //     return date.getTime() < startDate?.getTime()
  //   }
  //   return date < new Date()
  // }


  return (
    <>
      <FormDrawer
        display="center"
        size="lg"
        isOpen={open}
        setIsOpen={() => closeModal()}
      >
        <div className="p-8">
          <div className="pb-6 flex items-center justify-between border-b border-gray-100">
            <div className="text-2xl font-ojah font-medium">
              Generate a Statement
            </div>
            <div
              onClick={() => closeModal()}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconClose className="m-auto text-white svg-stroke-gray" />
            </div>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 mt-10">
              <div className="mt-10">
                {/* radio options */}
                <FormField
                  control={form.control}
                  name="fileType"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <RadioGroup
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <RadioGroup.Label className="text-[#B4B4B4] text-xs">
                            Select a file type/format
                            <span className="form-input-required">*</span>
                          </RadioGroup.Label>
                          <div className="grid grid-cols-2 gap-5 mt-3">
                            {plans.map((plan) => (
                              <RadioGroup.Option
                                key={plan.name}
                                value={plan.value}
                                className={({ active, checked }) =>
                                  `${active
                                    ? "ring-2 ring-white/60 ring-offset-1 ring-gray-100"
                                    : ""
                                  }
                      ${checked ? "border-2 border-shiga-black" : "border"}
                        relative flex cursor-pointer rounded-2xl px-5 py-4 focus:outline-none`
                                }
                              >
                                {({ active, checked }) => (
                                  <SelectedFileType plan={plan} checked={checked} />
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
                {/* refactor to some sort of divider component */}
                <div className="w-full h-px bg-[#F5F5F5] my-7" />

                <FormField
                  control={form.control}
                  name="currency"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectInput
                          type="text"
                          label="Select a currency balance"
                          placeholder="Select an option"
                          required={true}
                          {...field}
                        >
                          {wallets.map((wallet, idx) => (
                            <option key={idx} value={wallet.id}>{wallet.currency}</option>
                          ))}
                        </SelectInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">

                  <Input
                    type="date"
                    label="Start Date"
                    required={true}
                    value={start}
                    onChange={(e: any) => setStart(e.target.value)}
                  />

                  <Input
                    type="date"
                    value={end}
                    label="End Date"
                    required={true}
                    onChange={(e: any) => setEnd(e.target.value)}
                  />

                  {/* <DateForm form={form as any} field="startDate" label="Start Date" /> */}
                  {/* <DateForm form={form as any} field="endDate" handleDisable={handleDateDisable} label="End Date" /> */}
                </div>
              </div>
              <div className="px-8 pb-8">
                <ButtonLoader
                  type="submit"
                  loading={isLoading}
                  disabled={!start?.length || !end?.length}
                  className="btn btn-lg btn-block btn-shiga-black"
                >
                  Generate Statement
                </ButtonLoader>
              </div>
            </form>
          </Form>
        </div>

      </FormDrawer>
    </>
  );
};

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
