import { useEffect } from "react";
import { NoCard } from "./NoCard";
import CardsTable from "./CardsTable";
import { useNavigate } from "react-router-dom";
import useToolkit from "hooks/useToolkit";
import ShigaButton from "components/common/form/ShigaButton";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCards } from "store/card/action";

const Index = () => {
  const navigate = useNavigate();
  const { dispatch, useSelector } = useToolkit()
  const { isLoadingCards, cards } = useSelector((state: any) => state.cards);

  useEffect(() => {
    dispatch(getCards({ page: 1 }));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
      <div className="flex items-center justify-between pb-5 mb-5 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Cards
        </div>
        <div>

          <ShigaButton
            darkBg
            text="Create a card"
            disabled={isLoadingCards}
            onClick={() => navigate("/cards/create-a-card")}
          />

        </div>
      </div>

      <Maybe condition={isLoadingCards}>
        <div className="w-full">

          <div className="table-info">
            <FontAwesomeIcon icon="spinner" spin />
            <div className="font-medium">
              Fetching Cards
            </div>
          </div>

        </div>
      </Maybe>

      <Maybe condition={!isLoadingCards}>
        {cards?.data?.cards?.length ? <CardsTable cards={cards?.data?.cards} /> : <NoCard />}
      </Maybe>
    </div>
  );
};

export default Index;
