import Table from "components/common/Table";
import { Menu } from "@headlessui/react";
import Dropdown from "utils/dropdown";
import { ReactComponent as IconStar } from "assets/images/icons/Star.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import IconBank from "assets/images/icons/Bank.png";
import { TableContent } from "components/common/TableContent";
import EmptyUI from "components/common/EmptyUi";
import moment from "moment";
import CustomSwitch from "components/common/form/CustomSwitch";
import useToolkit from "hooks/useToolkit";
import { toggleCourierStatus } from "store/ecom/action";

type CourierProps = {
  loading: boolean;
  couriers: any;
  toggleLoading: boolean;
  toggleData: any;
  getCouriers: () => any;
}

const DeliveryAgentTable = ({ loading, couriers, toggleLoading }: CourierProps) => {
  const { toastError, dispatch } = useToolkit()

  function handleToggle(itemId: any) {
    const items = couriers?.data;

    const itemIndex = items?.findIndex((item: any) => item.id === itemId);
    if (itemIndex === -1) return;

    if (items[itemIndex].active && items.filter((item: any) => item.active).length === 1) {
      toastError('One active delivery agent is required');
      return;
    }
    dispatch(toggleCourierStatus(itemId))
  }


  return (
    <div>

      <div className="mt-6">

        <Table tableClass="pb-24">
          <Table.Head className="text-uppercase">
            <th>Name </th>
            <th>Status</th>
            <th>Date Created</th>
          </Table.Head>

          <Table.Body>
            <TableContent
              colspan={3}
              loading={loading}
              data={couriers?.data}
              total={couriers?.data?.length}
              loadingText="Fetching agents"
              emptyUI={
                <EmptyUI
                  icon={IconBank}
                  header="No Delivery Agents"
                  subheader="You have no delivery agents yet"
                />
              }
            />

            {loading === false && couriers?.data?.length > 0 && couriers?.data?.map((agent: any, index: number) => {

              return (
                <tr key={index}>

                  <td className="">
                    <div className="flex items-center">

                      <div className="mr-2 bg-gray-200 rounded-2xl">
                        <IconUserProfile className="w-8 h-8" />
                      </div>

                      <div className="font-medium">
                        {agent?.name ?? "-"}
                      </div>
                    </div>
                  </td>

                  <td>
                    <CustomSwitch
                      hideText
                      active={agent?.active}
                      loading={toggleLoading}
                      onChange={() => handleToggle(agent?.id)}
                    />
                  </td>

                  <td>
                    <div className="flex items-center">
                      {moment(agent?.createdAt).format('MMM D, YYYY [at] h:mmA')}
                      <div className="ml-8">
                        <Dropdown dropdownClass="dropdown-right">
                          <></>
                          <div>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon"
                            >
                              <IconStar />
                              View
                            </Menu.Item>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon text-shiga-warn"
                            >
                              <IconDelete />
                              Delete Product
                            </Menu.Item>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </td>


                  {/* <td className="">
                            <Dropdown dropdownClass="dropdown-right">
                              <></>
                              <div>
                                <Menu.Item
                                  as="div"
                                  className="dropdown-item dropdown-item-icon"
                                >
                                  <IconStar />
                                  View
                                </Menu.Item>
                                <Menu.Item
                                  as="div"
                                  className="dropdown-item dropdown-item-icon text-shiga-warn"
                                >
                                  <IconDelete />
                                  Delete Product
                                </Menu.Item>
                              </div>
                            </Dropdown>
                          </td> */}
                </tr>

              )
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default DeliveryAgentTable;
