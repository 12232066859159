export const DivInput = ({
    value,
    label,
    required = false,
    capitalize
}: {
    value: string | number;
    label: string;
    required?: boolean
    capitalize?: boolean
}) => {
    return (
        <div className="w-full h-[76px] flex-col justify-start items-start gap-2 inline-flex">
            <div className="self-stretch">
                <span className="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight">
                    {label}
                </span>
                {
                    required ?
                        <>
                            <span className="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight">
                                {" "}
                            </span>
                            <span className="text-red-500 text-sm font-normal font-['Inter'] leading-tight">
                                *
                            </span>
                        </> : <></>
                }
            </div>
            <div className="self-stretch h-12 px-3 py-3 bg-neutral-50 rounded-2xl justify-center items-center gap-3 inline-flex">
                <div className="p-[1.80px] justify-center items-center flex" />
                <div className={`grow shrink basis-0 text-shiga-black text-base font-medium font-['Inter'] leading-snug ${capitalize && 'capitalize'}`}>
                    {value}
                </div>
            </div>
            <div className="w-5 h-5 justify-center items-center gap-2.5 inline-flex" />
        </div>
    );
};

export default DivInput;