import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconLock } from "assets/images/icons/Lock.svg";
import { useEffect, useState } from "react";
import { ButtonLoader, Input } from "components/common/form";
import { useCardStore } from "zustand-store/cards";
import { Form, FormControl, FormField, FormItem } from "components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useSelector } from "react-redux";
import { getCardDetails, renameCard } from "store/card/action";
import { useDispatch } from "react-redux";

const formSchema = z.object({
    currentName: z.string(),
    newName: z.string()
});

type FormType = z.infer<typeof formSchema>;
export const CardRenameModal: React.FC<{
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {

    const dispatch = useDispatch();
    const { selectedCard } = useCardStore();
    const form = useForm<FormType>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            currentName: "",
            newName: ""
        },
        mode: "onBlur",
    });
    const { selectedCard: activeCard, renameCardLoading, renameCardData } = useSelector((state: any) => state.cards)
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (selectedCard.name) {
            form.setValue("currentName", selectedCard.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCard])

    const onSubmit = () => {
        const params = {
            id: activeCard.id,
            payload: { label }
        }
        dispatch(renameCard(params))
    }

    useEffect(() => {
        if (renameCardData?.success === true) {
            setIsOpen(false);
            dispatch(getCardDetails(activeCard?.id))
        }
        // eslint-disable-next-line
    }, [renameCardData])

    return (
        <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="p-8 pb-6">
                        <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                            <div className="text-2xl font-ojah font-medium">
                                Rename this Card
                            </div>
                            <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                <IconClose className="m-auto svg-stroke-gray" />
                            </div>
                        </div>
                        <div className="pt-6 space-y-4">
                            <FormField
                                control={form.control}
                                name="currentName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                leftIcon={<IconLock className="w-5 h-5 mr-1" />}
                                                disabled
                                                leftIconClass="ml-2"
                                                type="text"
                                                label="Current Name"
                                                placeholder={activeCard?.laben || 'N/A'}
                                                inputClass="focus:outline-0 active:outline-0"
                                                {...field}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="newName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                type="text"
                                                label="New Card name"
                                                placeholder="Enter a name"
                                                inputClass="focus:outline-0 active:outline-0"
                                                onChange={(e: any) => setLabel(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className="border-t border-gray-100 p-6 pt-3">
                        <ButtonLoader disabled={label?.length < 3 || renameCardLoading} loading={form.formState.isSubmitting}
                            type="submit" className="btn btn-block btn-lg btn-shiga-black">
                            Save Changes
                        </ButtonLoader>
                    </div>
                </form>
            </Form>
        </FormDrawer>
    )
}

export default CardRenameModal;