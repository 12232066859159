import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";


const LogsFilterPagination = ({ logs }: { logs: any }) => {

   return (
      <div className="mb-4 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
         <div className="w-full lg:w-2/4">
            <CustomTableFilter
               dropdownClass=''
               filters={[
                  {
                     title: "Status",
                     name: "messageStatus",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Successful",
                           payload: "success",
                        },
                        {
                           display: "Failed",
                           payload: "failed",
                        },
                     ],
                  },
                  // {
                  //    title: "Environment",
                  //    name: "environment",
                  //    dataType: "select",
                  //    options: [
                  //       {
                  //          display: "Show all",
                  //          payload: "-",
                  //       },
                  //    ],
                  // },
               ]}
            />
         </div>

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={{
               ...logs?.meta,
               size: logs?.meta?.totalItems
            }} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  extraUrlParams='page=1'
                  placeholder="Search Events"
               />
            </div>
         </div>
      </div>
   )
}

export default LogsFilterPagination 