import React, { Dispatch, MutableRefObject, SetStateAction } from "react";
import { defaultProductForm } from './export'

interface ContextPropsType {
   sessionStep: number;
   setSessionStep: Dispatch<SetStateAction<number>>;
   endSession: () => void;

   decrementSessionStep: () => void;
   incrementSessionStep: () => void;
   isEditingProduct: boolean;

   productForm: typeof defaultProductForm;
   setProductForm: Dispatch<SetStateAction<typeof defaultProductForm>>;
   productImgs: any[];
   setProductImgs: Dispatch<SetStateAction<any[]>>
   isEditing: MutableRefObject<boolean>;

   img1: any;
   setImg1: Dispatch<SetStateAction<any>>;
   img2: any;
   setImg2: Dispatch<SetStateAction<any>>;
   img3: any;
   setImg3: Dispatch<SetStateAction<any>>;
   img4: any;
   setImg4: Dispatch<SetStateAction<any>>;
   img5: any;
   setImg5: Dispatch<SetStateAction<any>>;
   img6: any;
   setImg6: Dispatch<SetStateAction<any>>;
};

const ContextProps = React.createContext<ContextPropsType | null>(null)

export default ContextProps