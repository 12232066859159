import { Transaction } from "types/transaction"
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import { ReactComponent as IconFlag } from "assets/images/icons/flag.svg";
import { ReactComponent as IconGiftCard } from "assets/images/icons/Gift-Card.svg";
import { ReactComponent as IconStampDuty } from "assets/images/icons/stamp-duty.svg";
import { ReactComponent as IconTransferDebit } from "assets/images/icons/arrow-out.svg";
import { ReactComponent as IconInwardTransfer } from "assets/images/icons/arrow-in.svg";
import { ReactComponent as IconSwap } from "assets/images/icons/swap.svg";
import { ReactComponent as IconCurSwap } from "assets/images/icons/trx-swap.svg";
import { ReactComponent as IconGotv } from "assets/images/icons/bills/gotv.svg";
import { ReactComponent as IconDstv } from "assets/images/icons/bills/dstv.svg";
import { ReactComponent as IconStartimes } from "assets/images/icons/bills/Startimes.svg";
import { ReactComponent as IconGlo } from "assets/images/icons/bills/glo.svg";
import { ReactComponent as IconMtn } from "assets/images/icons/bills/mtn.svg";
import { ReactComponent as IconIkedc } from "assets/images/icons/bills/ikedc.svg";
import { ReactComponent as IconAirtel } from "assets/images/icons/bills/airtel.svg";
import { ReactComponent as IconWarning } from "assets/images/icons/warning-triangle.svg";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import Maybe from "components/common/Maybe";
import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";

type NarrationProps = {
   transaction: Transaction;
   isDetailsView?: boolean;
   isDashboardInline?: boolean;
}


export const TransactionNarration = ({ transaction, isDetailsView, isDashboardInline }: NarrationProps) => {
   const isCreditTransaction = transaction?.type === 'CREDIT';
   const isCreditBankTransfer = isCreditTransaction && transaction?.channel === 'BANK_TRANSFER';
   const isDebitBankTransfer = transaction?.type === 'DEBIT' && transaction?.method === 'BANK_TRANSFER';
   const isDirectDebit = transaction?.channel === 'DIRECT_DEBIT';
   const isBillPayment = transaction?.method === 'BILL_PAYMENT';
   const isGiftCard = transaction?.method === 'GIFT_CARD';
   const isCardTrx = transaction?.channel === 'CARD';
   const isCardCreation = transaction?.description === 'Card Creation'
   const isCurrencySwap = transaction?.method === 'SWAP'
   const descriptionIsSwap = transaction?.description?.includes('>');
   const swapCurrencies = descriptionIsSwap ? transaction?.description?.split('>')?.map(currency => currency?.trim()) : [];
   const [fromCurrency, toCurrency] = swapCurrencies;
   const isApiTrx = transaction?.medium === 'API'
   const isFailedTransaction = transaction?.status === 'FAILED'
   const isGotv = transaction?.description?.toLowerCase()?.includes('gotv')
   const isDstv = transaction?.description?.toLowerCase()?.includes('dstv')
   const isStartimes = transaction?.description?.toLowerCase()?.includes('startimes')
   const isMtn = transaction?.description?.toLowerCase()?.includes('mtn')
   const isAirtel = transaction?.description?.toLowerCase()?.includes('airtel')
   const isGlo = transaction?.description?.toLowerCase()?.includes('glo')
   const isIkedc = transaction?.description?.toLowerCase()?.includes('ikedc')
   const isStampDuty = transaction?.description === 'Stamp duty'

   const inwardTransferName = `${transaction?.bankTransfer?.debitAccountName || ''}`
   const payersName = transaction?.bankTransfer?.debitAccountName?.toLowerCase();
   const sdkDirectDebitDepositor = `${transaction?.paymentCustomer?.firstName} ${transaction?.paymentCustomer?.lastName}`

   return (
      <div className={`flex items-center font-inter ${isDashboardInline && 'mb-3'}`}>

         <div className={`w-10 h-10 rounded-full flexed font-medium mr-4 
            ${isCurrencySwap && isDetailsView ? 'mr-6' : ''} 
            ${(isFailedTransaction || isStampDuty) ? 'bg-shiga-red-40' : 'bg-shiga-gray-40'}`}>

            <Maybe condition={isBillPayment}>
               {
                  isGotv ? <IconGotv /> : isDstv ? <IconDstv /> :
                     isStartimes ? <IconStartimes /> : isIkedc ? <IconIkedc /> :
                        isMtn ? <IconMtn /> : isAirtel ? <IconAirtel /> :
                           isGlo ? <IconGlo /> : transaction?.description?.length > 0 && transaction?.description[0]
               }
            </Maybe>

            <Maybe condition={isFailedTransaction || isStampDuty}>
               {isStampDuty ? <IconStampDuty /> :
                  <IconWarning />}
            </Maybe>

            <Maybe condition={!isBillPayment && !isFailedTransaction}>

               <Maybe condition={isGiftCard}>
                  <IconGiftCard />
               </Maybe>

               <Maybe condition={isDebitBankTransfer || isCardCreation}>
                  <IconTransferDebit />
               </Maybe>

               <Maybe condition={isDirectDebit || isCardTrx || isCreditBankTransfer}>
                  <IconInwardTransfer />
               </Maybe>

               <Maybe condition={isCurrencySwap}>
                  {
                     isDetailsView
                        ?
                        <div className="relative rounded-full h-8 w-8">
                           <img src={`/flags/${fromCurrency?.toLowerCase()}.svg`} alt="flag" className="object-cover" />

                           <span className="absolute top-2.5 left-3 h-[34px] w-[34px] rounded-full p-[3px] bg-white">
                              <img src={`/flags/${toCurrency?.toLowerCase()}.svg`} alt="flag" className="object-cover" />
                           </span>
                        </div>
                        :
                        <IconSwap />
                  }

               </Maybe>
            </Maybe>
         </div>

         <div className="flex flex-col">
            <p className={`text-shiga-gray-300 text-xs capitalize ${isDetailsView ? 'order-2' : 'mb-px'}`}>
               {isFailedTransaction && 'Failed - '}
               {
                  (isDetailsView && isApiTrx && isCardTrx) ? `API: Card ${isCreditTransaction ? 'Credit' : 'Debit'}`
                     : (isDetailsView && isDirectDebit && isApiTrx) ? 'API: Direct Debit'
                        : isBillPayment ? `${isDetailsView ? 'Bill Payment' : 'Bills payment for'}`
                           : isCurrencySwap ? `${isDetailsView ? 'Currency Conversion' : 'Currency swap from'}`
                              : isGiftCard ? 'Giftcard Purchase' : (isCreditBankTransfer || isDirectDebit) ? 'Received from bank'
                                 : isDebitBankTransfer ? 'Payment to bank' : isCardTrx ? 'Payment from Card'
                                    : isCardCreation ? payersName : isStampDuty ? 'Charge for' : ''
               }
            </p>
            <p className={`text-shiga-dark-100 font-medium text-[14px] capitalize ${isDetailsView && 'order-1 mb-px'}`}>

               <Maybe condition={isCurrencySwap}>
                  <span className="flex items-center">
                     {fromCurrency}

                     <span className="mx-1.5">
                        <IconCurSwap />
                     </span>

                     {toCurrency}
                  </span>
               </Maybe>

               <Maybe condition={isCreditBankTransfer || isDebitBankTransfer || isDirectDebit}>
                  {isDebitBankTransfer ? payersName : (isApiTrx && isDirectDebit) ? sdkDirectDebitDepositor : inwardTransferName?.toLowerCase()}
               </Maybe>

               <Maybe condition={!isCreditBankTransfer && !isDebitBankTransfer && !isCurrencySwap && !isDirectDebit}>
                  {(!isDetailsView && transaction?.description?.length > 25) ? `${transaction?.description?.substring(0, 25)}...` : transaction?.description}
               </Maybe>

            </p>
         </div>

      </div>
   )
}


export const TransactionType = ({ transaction }: { transaction: Transaction }) => {
   const isBankTransfer = transaction?.method === 'BANK_TRANSFER' || transaction?.channel === 'BANK_TRANSFER';
   const isBillPayment = transaction?.method === 'BILL_PAYMENT';
   const isDirectDebit = transaction?.channel === 'DIRECT_DEBIT';
   const isGiftCard = transaction?.method === 'GIFT_CARD'
   const isApiTrx = transaction?.medium === 'API'
   const isCreditTransaction = transaction?.type === 'CREDIT';
   const isApiCardTrx = isApiTrx && transaction?.channel === 'CARD';
   const isStampDuty = transaction?.description === 'Stamp duty'


   return (
      <div className="border border-shiga-gray-75 rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium">
         {isApiTrx && <span className="text-shiga-gray-300">SDK:&nbsp;</span>}
         {
            isBankTransfer ? 'Bank Transfer'
               : isDirectDebit ? 'Direct Debit'
                  : isBillPayment ? 'Bills Payment'
                     : isGiftCard ? 'Giftcards'
                        : isApiCardTrx ? `Card ${isCreditTransaction ? 'Credit' : 'Debit'}`
                           : isStampDuty ? 'Stamp Charges' : transaction?.method?.toLowerCase() || 'N/A'
         }
      </div>
   )
}

export const TransactionDetailTrigger = ({ transaction }: { transaction: Transaction }) => {

   return (
      <Dropdown dropdownClass="dropdown-right" dropdownIconClassName="rotate-90">
         <></>
         <div>
            {
               transaction.status === "SUCCESSFUL" ?
                  <Menu.Item
                     as="div"
                     onClick={() => { }}
                     className="dropdown-item dropdown-item-icon"
                  >
                     <IconDownload />
                     Share Receipt
                  </Menu.Item> : <></>
            }
            <Menu.Item
               as="div"
               // onClick={toggleRenameCard}
               className="dropdown-item dropdown-item-icon text-shiga-warn">
               <IconFlag />
               Report Transaction
            </Menu.Item>
         </div>
      </Dropdown>
   )
}

export const TransactionFilterHandler = ({ transactions }: { transactions: any }) => {

   return (
      <div className="my-6 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
         <div className="w-full lg:w-2/4">
            <CustomTableFilter
               dropdownClass=''
               filters={[
                  {
                     title: "Status",
                     name: "status",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Successful",
                           payload: "SUCCESSFUL",
                        },
                        {
                           display: "Failed",
                           payload: "FAILED",
                        },
                        // {
                        //    display: "Refunded",
                        //    payload: "REFUNDED",
                        // },
                        // {
                        //    display: 'Reversed',
                        //    payload: 'REVERSED',
                        // }
                     ],
                  },
                  {
                     title: "Currency",
                     name: "currency",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "NGN",
                           payload: "NGN",
                        },
                        {
                           display: "USD",
                           payload: "USD",
                        },
                        {
                           display: 'AUD',
                           payload: 'AUD',
                        },
                        {
                           display: 'GBP',
                           payload: 'GBP',
                        }
                     ],
                  },
                  {
                     title: "Type",
                     name: "method",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Card",
                           payload: "CARD",
                        },
                        {
                           display: "Bank transfer",
                           payload: "BANK_TRANSFER",
                        },
                        {
                           display: 'Bill payment',
                           payload: 'BILL_PAYMENT',
                        },
                        {
                           display: 'Tution payment',
                           payload: 'TUITION',
                        },
                        {
                           display: 'Deposit',
                           payload: 'DEPOSIT',
                        },
                        {
                           display: 'Refund',
                           payload: 'REFUND',
                        },
                        {
                           display: 'Spendings',
                           payload: 'SPENDINGS',
                        },
                        {
                           display: 'Swap',
                           payload: 'SWAP',
                        },
                        {
                           display: 'Topup',
                           payload: 'TOPUP',
                        },
                        {
                           display: 'Shiga QR',
                           payload: 'SHIGA_QR',
                        },
                        {
                           display: 'Giftcard',
                           payload: 'GIFT_CARD',
                        },
                        {
                           display: 'Stamp Duty',
                           payload: 'STAMP_DUTY',
                        }
                     ]
                  },
                  {
                     title: "Channel",
                     name: "channel",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Card",
                           payload: "CARD",
                        },
                        {
                           display: "Bank transfer",
                           payload: "BANK_TRANSFER",
                        },
                        {
                           display: 'Direct debit',
                           payload: 'DIRECT_DEBIT',
                        },
                        {
                           display: 'Giftcards',
                           payload: 'GIFT_CARD',
                        },
                        // {
                        //    display: 'API: Card debit',
                        //    payload: 'api_card_debit',
                        // },
                        // {
                        //    display: 'API: Bank Transfer',
                        //    payload: 'api_bank_transfer',
                        // },
                        // {
                        //    display: 'API: Direct debit',
                        //    payload: 'api_direct_debit',
                        // },
                        // {
                        //    display: 'API: Shiga QR',
                        //    payload: 'api_shiga_qr',
                        // }
                     ],
                  },
                  {
                     title: "Date Range",
                     dataType: "date-range",
                     options: {
                        from: {
                           name: "startDate",
                           placeholder: "Start Date",
                        },
                        to: {
                           name: "endDate",
                           placeholder: "End Date",
                        },
                     },
                  },
               ]}
            />
         </div>

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={transactions?.data?.meta} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  extraUrlParams='page=1'
                  placeholder="Search transactions"
               />
            </div>
         </div>
      </div>
   )
}