import Maybe from 'components/common/Maybe';
import { ReactComponent as IconWarning } from "assets/images/icons/triangle-exclamation-orange.svg";
import useToolkit from 'hooks/useToolkit';
import DynamicHeadTag from 'components/common/DynamicHeadTag';
import KeysTab from './tabs/hook-keys/KeysTab';
import LogsTab from './tabs/hook-logs/LogsTab';
import UrlsTab from './tabs/urls/UrlsTab';


const WebhooksHomepage = () => {
   const { useState, isLocalEnv, isSandboxEnv } = useToolkit();
   const [activeTab, setActiveTab] = useState('keys');

   const isApiKeys = activeTab === 'keys'
   const isLogs = activeTab === 'logs'
   const isUrls = activeTab === 'urls'

   const tabs = [
      { title: 'API Keys', value: 'keys' },
      { title: 'Webhook Logs', value: 'logs' },
      { title: 'Manage URLs', value: 'urls' },
   ]

   return (

      <>

         <Maybe condition={isLocalEnv || isSandboxEnv}>
            <SandboxBanner />
         </Maybe>

         <div className='dashboard-page-container'>

            <DynamicHeadTag
               headerText="API & Webhooks"
            />

            <div className="w-full lg:w-2/4 flex items-center space-x-7 pb-6">
               {tabs.map((item: any) => {
                  const isActive = activeTab === item?.value;

                  return (
                     <button
                        key={item?.value}
                        onClick={() => setActiveTab(item.value)}
                        className={`active:scale-90 transition-transform font-ojah 
                        ${isActive ? 'bg-shiga-dark-100 py-2 px-3.5 rounded-full text-white' : 'text-shiga-gray-100'}`}>
                        {item?.title}
                     </button>
                  )
               })}
            </div>

            <Maybe condition={isApiKeys}>
               <KeysTab />
            </Maybe>

            <Maybe condition={isLogs}>
               <LogsTab />
            </Maybe>

            <Maybe condition={isUrls}>
               <UrlsTab />
            </Maybe>

         </div>

      </>
   )
}

const SandboxBanner = () => (
   <div className="bg-outline-orange px-6 py-3 flex items-center justify-center flex-wrap">
      <span className="flex items-start md:items-center text-shiga-orange">
         <IconWarning className="hidden md:block" /> &nbsp;
         These API keys are for testing only. Ensure you or your team does not use them while in production.
      </span>
   </div>
)

export default WebhooksHomepage