import { SetStateAction, useState } from 'react'
import CenterModal from 'components/common/CenterModal'
import { ReactComponent as IconUser } from "assets/images/icons/user-cornered.svg";
import { ReactComponent as IconEye } from "assets/images/icons/eye-plain.svg";
import ProfilePicUploader from 'components/custom/ProfilePicUploader';
import iconBusinessProfile from "assets/images/icons/Business.svg";
import useMediaService from 'hooks/useMediaService';
import useToolkit from 'hooks/useToolkit';
import { RootState } from 'store/store';


type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   setLogoModalShown: React.Dispatch<SetStateAction<boolean>>;
   setCreateProductOpen: React.Dispatch<SetStateAction<boolean>>;
}

const LogoUploadModal = ({
   isOpen,
   setIsOpen,
   setCreateProductOpen,
   setLogoModalShown,
}: Props) => {
   const [uploadPic, setUploadPic] = useState(false);
   const { uploadedFileLoading } = useMediaService();

   const [isImageValid, setIsImageValid] = useState(false);
   const { useSelector } = useToolkit();
   const { profilePicLoading } = useSelector((state: RootState) => state.entities.settings);

   const list = [
      {
         title: 'Upload your business iamge with the format 400x400px in PNG or JPEG formats.',
         Icon: IconUser,
      },
      {
         title: 'Make sure your photo is clear and easy to see, to help buyers identify your business.',
         Icon: IconEye,
      }
   ]

   const handleUploadSuccess = () => {
      setLogoModalShown(false);
      setCreateProductOpen(true);
   }

   return (
      <CenterModal
         hasButton
         isOpen={isOpen}
         buttonText='Continue'
         setIsOpen={setIsOpen}
         buttonDisabled={!isImageValid}
         headerTitle='Upload your Business logo'
         onButtonClick={() => setUploadPic(true)}
         isButtonLoading={uploadedFileLoading || profilePicLoading}>

         <div className='w-full'>

            <div className="w-full flexed">
               <ProfilePicUploader
                  isVendorUpload
                  referenceUrl={''}
                  isPersonalProfile={false}
                  manualUploadTrigger={uploadPic}
                  placeholderSrc={iconBusinessProfile}
                  onUploadSuccess={handleUploadSuccess}
                  onImageSelect={() => setIsImageValid(true)}
                  onImageDelete={() => setIsImageValid(false)}
               />
            </div>

            <div className="pt-7 w-10/12 mx-auto">
               {list?.map(({ Icon, title }: any) => (

                  <div className="w-full flex mb-7" key={title}>
                     <Icon width={48} />

                     <span className='ml-4 text-shiga-dark-100'>
                        {title}
                     </span>
                  </div>

               ))}
            </div>

         </div>

      </CenterModal>
   )
}

export default LogoUploadModal