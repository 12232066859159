import { useState } from "react";
import "./ImageCarousel.css";
import Img from "assets/images/blog-img-1.png";
import Img2 from "assets/images/blog-img-2.png";
import { ReactComponent as ArrowRight } from "assets/images/icons/long-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/images/icons/long-arrow-left.svg";
import Maybe from "components/common/Maybe";

type CarouselProps = {
  product: any;
}

const ImageCarousel = ({ product }: CarouselProps) => {
  const images = [Img, Img, Img2];
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="bg-shiga-black w-full h-full rounded-2xl mb-6 relative overflow-hidden">

      <Maybe condition={product !== null}>
        <img
          alt='slide'
          src={product?.photos[currentIndex]?.url}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500`}
        />
      </Maybe>

      <div className="px-4">

        <button
          onClick={() => setCurrentIndex((prevIndex) => prevIndex === 0 ? images.length - 1 : prevIndex - 1)}
          className="absolute top-1/2 left-3 transform -translate-y-1/2  p-3 rounded-full bg-shiga-dark-10">
          <ArrowLeft />
        </button>

        <button
          onClick={() => setCurrentIndex((prevIndex) => prevIndex === images.length - 1 ? 0 : prevIndex + 1)}
          className="absolute top-1/2 right-3 transform -translate-y-1/2  p-3 rounded-full bg-shiga-dark-10">
          <ArrowRight />
        </button>

      </div>
    </div>
  );
};

export default ImageCarousel;
