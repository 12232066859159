import FormDrawer from "components/common/form-drawer";
import copy from "copy-to-clipboard";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { completeMFA, verifyMFA } from "store/auth/account/action";
import { ReactComponent as IconKey } from "assets/images/icons/Key.svg";
import { ReactComponent as IconScanner } from "assets/images/icons/Scanner.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/Check-Circle.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import PinInput from "react-pin-input";
import { ButtonLoader } from "components/common/form";
import QRCode from "react-qr-code";
import useToolkit from "hooks/useToolkit";

const TwoFacSetupModal = (props: any) => {

   const { isOpen, setIsOpen, mfaInitialize, onClose, onMfaSetupComplete } = props;
   const { mfaCompleteLoading, mfaComplete } = useSelector((s: any) => s.auth.account);
   const { refetchProfiles } = useToolkit();
   const pinLength = 6;

   let elementRef: any = useRef();
   const dispatch = useDispatch();
   const [pin, setPin] = useState("");
   const [step, setStep] = useState(1);
   const backupCodesSample = ["hde37d382v", "znjw9s8ced", "nch923fh39", "9hcur48brc", "hde37d382v", "znjw9s8ced", "nch923fh39", "9hcur48brc", "nch923fh39", "9hcur48brc"];

   const copyText = (text: string) => {
      copy(text);
      toast.success("Copied: " + text);
   }

   const clearInputPin = () => {
      if (elementRef.currentIndex !== undefined) {
         elementRef.clear();
         setPin("");
      }
   }

   const submitForm = async (e: any) => {
      e.preventDefault();
      const params = {
         token: pin
      }
      dispatch(completeMFA(params));
      dispatch(verifyMFA(params));

      setTimeout(() => {
         refetchProfiles();
      }, 1000);
   }

   const downloadBackupCode = () => {
      copy(backupCodesSample.join(" "));
      toast.success("Backup codes copied. Please keep these safe.");
   }

   useEffect(() => {
      if (isOpen === true) {
         clearInputPin();
         setStep(1);
      }
   }, [isOpen]);

   useEffect(() => {
      if (mfaComplete?.success === true) {
         onMfaSetupComplete && onMfaSetupComplete();
         clearInputPin();
         setStep(2);
      }
      // eslint-disable-next-line
   }, [mfaComplete]);

   return (
      <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
         {mfaInitialize?.data &&
            <form onSubmit={submitForm}>
               {step === 1 &&
                  <>
                     <div className="p-8">
                        <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                           <div className="text-2xl font-ojah font-medium ellipsis">
                              Enable Two-Factor Authentication
                           </div>
                           <div onClick={() => { setIsOpen(false); onClose() }} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                              <IconClose className="m-auto svg-stroke-gray" />
                           </div>
                        </div>

                        <div className="mt-10">
                           <div className="mx-4 flex">
                              <div className="w-14 flex-shrink-0">
                                 <IconKey className="w-8 h-8" />
                              </div>
                              <div className="text-sm">
                                 Before you continue, Install an authenticator app like Authy or Google Authenticator.
                              </div>
                           </div>
                           <div className="mt-4 mx-4 flex">
                              <div className="w-14 flex-shrink-0">
                                 <IconScanner className="w-8 h-8" />
                              </div>
                              <div className="text-sm">
                                 <span className="mr-1">Scan the QR Code or enter the text code below if you can't scan it </span>
                                 <span onClick={() => copyText(mfaInitialize?.data?.secret ?? "-")} className="w-max pl-1.5 pr-7 py-px text-xs relative whitespace-nowrap items-center border-[1px] border-[#E2E3E5] rounded-md hover:bg-gray-200 cursor-pointer">
                                    {mfaInitialize?.data?.secret}
                                    <IconCopy className="h-3.5 absolute top-px right-0.5" />
                                 </span>
                              </div>
                           </div>
                        </div>

                        <div className="w-52 h-52 mt-6 bg-white mx-auto overflow-hidden">
                           {/* <img src={ImageQRCode} alt="QRCode" /> */}
                           <QRCode
                              value={mfaInitialize?.data?.otpUrl}
                              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                           />
                        </div>

                        <div className="mt-6">
                           <div className="mb-2 text-shiga-gray text-sm">
                              Enter Code generated by App
                              <span className="form-input-required">*</span>
                           </div>
                           <PinInput
                              length={pinLength}
                              initialValue={pin}
                              onChange={(value) => setPin(value)}
                              type="numeric"
                              inputMode="number"
                              inputStyle={{ width: '48px', height: '48px', background: "#FAFAFB", border: "2px solid transparent", marginRight: "6px", fontSize: "40px", fontWeight: "bold", paddingBottom: "6px", borderRadius: "1rem" }}
                              inputFocusStyle={{ border: "2px solid #343433" }}
                              onComplete={(value, index) => { }}
                              autoSelect={true}
                              focus={true}
                              secret={true}
                              ref={(n) => (elementRef = n)}
                           />
                        </div>
                     </div>

                     <div className="border-t border-gray-100 p-8">
                        <ButtonLoader loading={mfaCompleteLoading} disabled={pin.length !== 6} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                           Enable 2FA
                        </ButtonLoader>
                     </div>
                  </>
               }

               {step === 2 &&
                  <div className="p-8">
                     <div className="pb-6 flex items-center justify-between">
                        <div className="text-2xl font-ojah font-medium ellipsis"></div>
                        <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                           <IconClose className="m-auto svg-stroke-gray" />
                        </div>
                     </div>

                     <div className="text-center">
                        <div>
                           <IconCheck className="w-24 h-24 mx-auto" />
                        </div>
                        <div className="mt-8 text-3xl font-ojah font-bold">
                           Two-Factor Enabled
                        </div>
                        <div className="mt-8 pt-8 border-t border-gray-100 text-sm text-shiga-gray">
                           Download and keep these backup code safe. If you lose access to your Authentication device, you can use any of these codes to log in to your account.
                        </div>
                        <div className="mt-8 pt-8 border border-gray-100 rounded-2xl overflow-hidden">
                           <div className="grid grid-cols-2 gap-4">
                              {backupCodesSample.map((code: string) =>
                                 <div className="font-medium">
                                    {code}
                                 </div>
                              )}
                           </div>
                           <div onClick={downloadBackupCode} className="p-3 mt-8 bg-gray-100 hover:bg-gray-200 flex items-center justify-center cursor-pointer">
                              <IconDownload className="mr-2" />
                              Download backup codes
                           </div>
                        </div>
                     </div>
                  </div>
               }
            </form>
         }
      </FormDrawer>
   )
}

export default TwoFacSetupModal;