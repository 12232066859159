import { useEffect } from "react";
import DeliveryAgentTable from "./table/DeliveryAgentTable";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import { fetchCouriers } from "store/ecom/action";
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";


const DeliveryAgent = () => {
  const { dispatch, useSelector } = useToolkit();
  const {
    couriersLoading,
    couriersData,
    toggleCourierData,
    toggleCourierLoading
  } = useSelector((state: RootState) => state.ecom)

  const getCouriers = () => dispatch(fetchCouriers());

  useEffect(() => {
    getCouriers()
    // eslint-disable-next-line
  }, [])

  return (
    <div>

      <div className="flex items-center justify-between pb-12 mb-12 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Delivery Agent
        </div>
      </div>

      <div className="p-3 rounded-lg w-full bg-shiga-orange-10 flex items-center">
        <div className="pt-1 mr-2 pb-[2px]">
          <IconInfo className="w-4 h-4" />
        </div>

        <div className="text-[#523000] text-sm whitespace-nowrap">
          Please note that one of these must be toggled on at all times to enable you receive customer orders on Ojah.
        </div>
      </div>

      <DeliveryAgentTable
        couriers={couriersData}
        getCouriers={getCouriers}
        loading={couriersLoading}
        toggleData={toggleCourierData}
        toggleLoading={toggleCourierLoading}
      />
    </div>
  );
};

export default DeliveryAgent;
