import { Switch } from '@headlessui/react';
import Maybe from "../Maybe";
import { Loader } from 'lucide-react';

/**
 * <CustomSwitch
    active={true}
    activeText="Enabled"
    inactiveText="Disabled"
    onChange={(e:any) => {}}
/>
 */

const CustomSwitch = (props) => {
    const { active, onChange, hideText, activeText, inactiveText, disabled, loading } = props;

    return (
        <div style={{ opacity: disabled ? "40%" : "100%" }} className="flex items-center">

            <Maybe condition={loading}>
                <Loader className="animate-spin" size='1.3rem' />
            </Maybe>

            <Maybe condition={!loading}>
                <Switch
                    loading
                    as="button"
                    checked={active}
                    disabled={disabled}
                    onChange={onChange}
                    className={`relative inline-flex w-11 h-6 items-center rounded-full transition-all duration-300
                    ${disabled && "cursor-not-allowed"} ${active ? 'bg-[#00C46C]' : 'bg-[#C8C8C8]'}`}
                >
                    <span className={`inline-block w-5 h-5 transform rounded-full bg-white ${active ? 'absolute right-0.5' : 'ml-0.5'}`} />
                </Switch>
            </Maybe>

            <Maybe condition={hideText === undefined || hideText === false}>
                <span className="ml-3">
                    {active ? activeText || "Enabled" : inactiveText || "Disabled"}
                </span>
            </Maybe>

        </div>
    )
}

export default CustomSwitch