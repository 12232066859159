import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/file-upload.svg";
import { useRef, useState } from "react";
import Maybe from "components/common/Maybe";
import useToolkit from "hooks/useToolkit";
import { FileUploader } from "react-drag-drop-files";
import usePaymentLinksProvider from "../context/usePaymentLinksProvider";


const LinkLogoHandler = () => {
   const { toastError } = useToolkit();
   const [logo, setLogo] = useState(null);
   const [img, setImg] = useState<any>(null);

   const logoRef = useRef<HTMLInputElement>(null);
   const { setLogoFile } = usePaymentLinksProvider();

   const fileTypes = ['jpeg', 'png', 'jpg'];

   const handleChange = (file: any) => {
      setLogo(file);
      setLogoFile(file);
      setImg(URL.createObjectURL(file))
   }

   return (
      <div className={`w-full h-full border-2 border-shiga-gray-50 rounded-2xl ${logo === null ? 'px-5 py-10' : ''}`}>

         <Maybe condition={logo !== null}>
            <div className="relative w-full h-full">
               <img
                  src={img}
                  alt='logo'
                  className="rounded-2xl object-cover"
               />

               <input
                  type="file"
                  ref={logoRef}
                  accept=".png, .jpg, .jpeg"
                  style={{ display: 'none' }}
                  onChange={(e: any) => handleChange(e.target.files[0])}
               />

               <IconEdit
                  onClick={() => logoRef?.current?.click()}
                  data-tooltip-content={logo ? "" : "Change Image"}
                  className="w-8 h-8 p-1.5 bg-black bg-opacity-50 
                     absolute z-[1] bottom-2 left-2 rounded-full hover:bg-opacity-100 
                     cursor-pointer outline-none"
               />

               <IconRemove
                  data-tooltip-id="dp-remove"
                  data-tooltip-content="Remove Image"
                  onClick={() => { setImg(null); setLogo(null) }}
                  className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 
               right-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white"
               />
            </div>
         </Maybe>

         <Maybe condition={logo === null}>
            <div className="w-full h-full relative rounded-3xl flexed flex-col">

               <FileUploader
                  ref={logoRef}
                  multiple={false}
                  classes="file_upload_zone"
                  handleChange={handleChange}
                  types={fileTypes}
                  onTypeError={(err: any) => toastError(err)}
                  onSizeError={(err: any) => toastError(err)}>

                  <div className="flexed flex-col space-y-6 text-center">
                     <div className="w-20 h-20 flex">
                        <IconFileUpload className="m-auto w-full h-full" />
                     </div>

                     <div className="mt-4 text-shiga-gray-100">
                        {'Drag and drop files or click here to upload'}
                     </div>
                  </div>
               </FileUploader>

            </div>
         </Maybe>

      </div>
   )
}

export default LinkLogoHandler