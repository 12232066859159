import CustomTableFilter from "components/common/CustomTableFilter"

export const OrdersFilter = () => {

   return (
      <div className="w-full lg:w-2/4">
         <CustomTableFilter
            dropdownClass=''
            filters={[
               {
                  title: "Status",
                  name: "status",
                  dataType: "select",
                  options: [
                     {
                        display: "Show all",
                        payload: "-",
                     },
                     {
                        display: "Not shipped",
                        payload: "NOT_SHIPPED",
                     },
                     {
                        display: "Cancelled",
                        payload: "CANCELED",
                     },
                     {
                        display: "In transit",
                        payload: "IN_TRANSIT",
                     },
                     {
                        display: "Delivered",
                        payload: "DELIVERED",
                     },
                  ],
               },
               {
                  title: "Custom Date",
                  dataType: "date-range",
                  options: {
                     from: {
                        name: "startDate",
                        placeholder: "Start Date",
                     },
                     to: {
                        name: "endDate",
                        placeholder: "End Date",
                     },
                  },
               },
            ]}
         />
      </div>
   )
}