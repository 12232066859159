import { useEffect, useState } from 'react'
import CenterModal from 'components/common/CenterModal'
import CustomAmountField from 'components/custom/CustomAmountField'
import useToolkit from 'hooks/useToolkit';
import { getDashboard } from 'store/dashboard/reducer';
import { ReactComponent as IconWallet } from "assets/images/icons/transfer-wallet.svg";
import { updateBulkTransferAmount } from 'store/transfer/action';
import useTransfersProvider from '../../context/useTransfersProvider';

const EditBulkTransfer = ({ isOpen, setIsOpen, record, refetchRecipients }) => {
   const { bulkRecipients } = useTransfersProvider();
   const [transferAmount, setTransferAmount] = useState('');
   const { dispatch, useSelector } = useToolkit();
   const { updateBulkTrfAmountLoading: updateLoading, updateBulkTrfAmountData: updateData } = useSelector((state) => state.transfer)
   const { wallets } = useSelector(getDashboard);

   const ngnWallet = wallets?.data?.find((wallet) => wallet?.currency === 'NGN')
   const insufficientBalance = transferAmount?.length > 0 && ngnWallet?.balance < Number(transferAmount);
   const minimumInvalid = (transferAmount && Number(transferAmount) < 1);
   const invalidAmount = minimumInvalid || insufficientBalance;

   const minAmountMsg = `The minimum amount you can send is 1 NGN`;
   const proceedBtnDisabled = Number(transferAmount) < 1 || invalidAmount;
   const balance = Number(ngnWallet?.balance)?.toLocaleString();

   const handleAmountUpdate = () => {
      const payload = {
         id: bulkRecipients?.data?.bulkTransfer?.id,
         payload: [{ id: record?.id, newAmount: Number(transferAmount) }]
      }
      dispatch(updateBulkTransferAmount(payload))
   }

   useEffect(() => {
      if (record?.id?.length > 0) {
         setTransferAmount(record?.amount)
      }
   }, [record])

   useEffect(() => {
      if (updateData?.success === true) {
         setIsOpen(false);
         refetchRecipients();
      }
      // eslint-disable-next-line
   }, [updateData])

   return (
      <CenterModal
         hasButton
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         onButtonClick={handleAmountUpdate}
         headerTitle='Edit recipient'
         isButtonLoading={updateLoading}
         buttonText={updateLoading ? 'Saving...' : 'Save Changes'}
         buttonDisabled={proceedBtnDisabled}>
         <div className="w-full">

            <div className="w-full bg-shiga-gray-40 p-4 pb-2 my-5 rounded-xl">

               <div className="w-full flex items-start justify-between font-medium mb-3">
                  <p className="text-sm text-shiga-gray-300 whitespace-nowrap">Account Name</p>
                  <p className="text-sm text-shiga-dark-100 text-right">
                     {record?.accountName}
                  </p>
               </div>

               <div className="w-full flex items-center justify-between font-medium py-3 border-y border-shiga-gray-50 mb-3">
                  <p className="text-sm text-shiga-gray-300">Account Number</p>
                  <p className="text-sm text-shiga-dark-100 flex">
                     {record?.accountNumber}
                  </p>
               </div>

               <div className="w-full flex items-center justify-between font-medium mb-2">
                  <p className="text-sm text-shiga-gray-300">Bank Name</p>
                  <p className="text-sm text-shiga-dark-100">
                     {record?.bankName}
                  </p>
               </div>

            </div>

            <CustomAmountField
               leftLabel='You send'
               isFieldRequired
               setInputAmount={(e) => setTransferAmount(e.target.value)}
               inputError={invalidAmount}
               errorText={`${minimumInvalid
                  ? minAmountMsg
                  : insufficientBalance
                     ? "You cannot send more than your available balance" : minAmountMsg}`}
               flagIconUrl={'/flags/ngn.svg'}
               inputAmount={transferAmount}
               transactionCurrency='NGN'
               rightLabel={
                  <div className='mb-0 pb-0'>
                     <p className="text-shiga-dark flex items-center font-inter text-xs mb-0">
                        <span className="mr-1">
                           <IconWallet />
                        </span>

                        You have:&nbsp;
                        <span className="text-shiga-purple font-medium">
                           {balance} NGN
                        </span>
                        &nbsp;available
                     </p>
                  </div>
               }
            />

         </div>

      </CenterModal>
   )
}

export default EditBulkTransfer