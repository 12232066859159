import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { copyText } from "utils/handleCopy";
import { ButtonLoader } from "components/common/form";
import { useSelector } from "react-redux";
import useToolkit from "hooks/useToolkit";

export const CardDetailsModal: React.FC<{
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
    const { showValue } = useToolkit();
    const { selectedCard: activeCard, cardDetailsData } = useSelector((state: any) => state.cards)
    const details = cardDetailsData?.data;

    const address = showValue(`${details?.billingAddress?.street}, ${details?.billingAddress?.city}, 
    ${details?.billingAddress?.state} ${details?.billingAddress?.zipCode}, ${details?.billingAddress?.country}`)

    const cardDetailsInfo = [
        {
            title: "Card Name",
            detail: showValue(activeCard?.label),
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "Card Number",
            detail: showValue(details?.number?.replace(/(\d{4})(?=\d)/g, "$1 ")),
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "Expires",
            detail: showValue(`${details?.expiryMonth}/${details?.expiryYear?.slice(2)}`),
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "CVV",
            detail: showValue(details?.cvv),
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "Billing Address",
            detail: address
        }
    ]

    return (
        <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="p-8 pb-6">
                <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                    <div className="text-2xl font-ojah font-medium">
                        Card Details
                    </div>
                    <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                        <IconClose className="m-auto svg-stroke-gray" />
                    </div>
                </div>
                <div className="pt-6 space-y-4">
                    {
                        cardDetailsInfo.map((item, idx) => (
                            <div className="flex justify-between items-center" key={idx}>
                                <div className="flex flex-col">
                                    <span className="text-sm text-shiga-gray">{item.title}</span>
                                    <span className="font-medium">{item.detail}</span>
                                </div>
                                <div className="cursor-pointer" onClick={copyText(item.detail + "", item.title)}>
                                    {item.icon}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="border-t border-gray-100 p-6 pt-3">
                <ButtonLoader onClick={() => {
                    setIsOpen(false)
                }} className="btn btn-block btn-lg btn-shiga-black">
                    Done
                </ButtonLoader>
            </div>
        </FormDrawer>
    )
}

export default CardDetailsModal;