import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export const initializeBulkTransfer = (id: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiInitiateBulkTransfer + id,
      method: "post",
      data: {},
      onStart: actions.PROCESS_BULK_TRANSFER_START,
      onSuccess: actions.PROCESS_BULK_TRANSFER_DONE,
      onError: actions.PROCESS_BULK_TRANSFER_FAILED,
    },
  };
};

export const updateBulkTransferAmount = ({ id, payload }: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiUpdateBulkTransferAmount + `/${id}/update-amount`,
      method: "patch",
      data: payload,
      onStart: actions.UPDATE_BULK_TRANSFER_AMOUNT_START,
      onSuccess: actions.UPDATE_BULK_TRANSFER_AMOUNT_DONE,
      onError: actions.UPDATE_BULK_TRANSFER_AMOUNT_FAILED,
    },
  };
};

export const deleteBulkTransferItem = ({ id, accountId }: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url:
        config.apiDeleteBulkTransferItem +
        `/${id}/account-details/${accountId}`,
      method: "delete",
      data: {},
      onStart: actions.DELETE_BULK_TRANSFER_ITEM_START,
      onSuccess: actions.DELETE_BULK_TRANSFER_ITEM_DONE,
      onError: actions.DELETE_BULK_TRANSFER_ITEM_FAILED,
    },
  };
};

export const fetchBulkTransferContent = ({ id, params }: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url:
        config.apiFetchBulkTransferContent +
        `/${id}/account-details` +
        ObjectToQueryString(params),
      method: "get",
      data: {},
      onStart: actions.FETCH_BULK_TRANSFER_CONTENT_START,
      onSuccess: actions.FETCH_BULK_TRANSFER_CONTENT_DONE,
      onError: actions.FETCH_BULK_TRANSFER_CONTENT_FAILED,
    },
  };
};

export const deleteBulkTrfFile = () => {
  return {
    type: actions.DELETE_BULK_TRF_FILE,
  };
};

export const uploadBulkTransferFile = (payload: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiUploadBulkTransferFile,
      method: "post",
      data: payload,
      onStart: actions.UPLOAD_BULK_TRANSFER_FILE_START,
      onSuccess: actions.UPLOAD_BULK_TRANSFER_FILE_DONE,
      onError: actions.UPLOAD_BULK_TRANSFER_FILE_FAILED,
    },
  };
};

export const resetTransferParams = () => {
  return {
    type: actions.RESET_TRANSFER_PARAMS,
  };
};

export const getNgnVirtualAcct = (payload: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.getNgnVirtualAccount,
      method: "post",
      data: payload,
      onStart: actions.GET_NGN_VIRTUAL_ACCT_START,
      onSuccess: actions.GET_NGN_VIRTUAL_ACCT_DONE,
      onError: actions.GET_NGN_VIRTUAL_ACCT_FAILED,
    },
  };
};

export const initiateBulkTransfer = (payload: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiRequestBulkTransfer,
      method: "post",
      data: payload,
      onStart: actions.PROCESS_BULK_TRANSFER_START,
      onSuccess: actions.PROCESS_BULK_TRANSFER_DONE,
      onError: actions.PROCESS_BULK_TRANSFER_FAILED,
    },
  };
};

export const requestTransferOtp = () => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiRequestTransferOtp,
      method: "post",
      data: {},
      onStart: actions.REQUEST_TRANSFER_OTP_START,
      onSuccess: actions.REQUEST_TRANSFER_OTP_DONE,
      onError: actions.REQUEST_TRANSFER_OTP_FAILED,
    },
  };
};

export const getTransferLimit = (currency: string, type: string) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetTransferLimit}${currency}?type=${type}`,
      method: "get",
      data: {},
      onStart: actions.GET_TRANSFER_LIMIT_START,
      onSuccess: actions.GET_TRANSFER_LIMIT_DONE,
      onError: actions.GET_TRANSFER_LIMIT_FAILED,
    },
  };
};

export const clearBankTransferForm = () => {
  return {
    type: actions.CLEAR_BANK_TRANSFER_FORM,
  };
};

export const saveBankTransferForm = (payload: BankTransferFormProps) => {
  return {
    type: actions.SAVE_BANK_TRANSFER_FORM,
    payload: payload,
  };
};

export const getTransferFee = (params: string) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetTransferFee}?${params}`,
      method: "get",
      onStart: actions.REQUEST_TRANSFER_OTP_START,
      onSuccess: actions.GET_TRANSFER_FEE_DONE,
      onError: actions.GET_TRANSFER_FEE_FAILED,
    },
  };
};

export const getTransferBanks = (currency: string) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetBanks}${currency}`,
      method: "get",
      data: {},
      onStart: actions.TRANSFER_GET_BANK_START,
      onSuccess: actions.TRANSFER_GET_BANK,
      onError: actions.TRANSFER_GET_BANK_FAILED,
    },
  };
};

export const updateSelectedCurrency = (newBank: any) => {
  return {
    type: actions.UPDATE_TRANSFER_CURERENCY,
    payload: newBank,
  };
};

export const updateDynamicField = (fieldName: string, fieldValue: any) => {
  return {
    type: actions.UPDATE_TRANSFER_DYNAMIC_FIELD,
    payload: {
      [fieldName]: fieldValue,
    },
  };
};

export const transferNameEnquiry = (
  accountNumber: string,
  bankCode: string
) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiNameEnquiry,
      method: "post",
      data: {
        accountNumber: accountNumber,
        bankCode: bankCode,
      },
      onStart: actions.TRANSFER_NAME_ENQUIRY_START,
      onSuccess: actions.TRANSFER_NAME_ENQUIRY,
      onError: actions.TRANSFER_NAME_ENQUIRY_FAILED,
    },
  };
};

export const makeTransfer = (data: any) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiMakeTranssfer,
      method: "post",
      data: {
        ...data,
      },
      onStart: actions.TRANSFER_PAYMENT_START,
      onSuccess: actions.TRANSFER_PAYMENT,
      onError: actions.TRANSFER_PAYMENT_FAILED,
    },
  };
};

export const updateSelectedWaletForTransfer = (wallet: any) => {
  return {
    type: actions.UPDATE_SELECTED_WALLET_FOR_TRANSFER,
    payload: wallet,
  };
};

export const getTransferBeneficiaryList = () => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetTransferBeneficiary,
      method: "get",
      data: {},
      onStart: actions.TRANSFER_BENEFICIARY_START,
      onSuccess: actions.TRANSFER_BENEFICIARY,
      onError: actions.TRANSFER_BENEFICIARY_FAILED,
    },
  };
};

export const updateTransferAmount = (amount: string) => {
  return {
    type: actions.UPDATE_TRANSFER_AMOUNT,
    payload: amount,
  };
};

export const updateTransferNote = (note: string) => {
  return {
    type: actions.UPDATE_TRANSFER_NOTE,
    payload: note,
  };
};

export const updateMfa = (mfaCode: string) => {
  return {
    type: actions.UPDATE_TRANSFER_MFA_CODE,
    payload: mfaCode,
  };
};
export const resetTransferData = () => {
  return {
    type: actions.RESET_TRANSFER_DATA,
  };
};

export const updateFromTransferFromBeneficaiary = (
  accountNo: string,
  accountName: string,
  accountBank: string,
  bankCode: string,
  currency: string,
  routingNo: string,
  accountType: string,
  isBeneficiery: boolean
) => {
  return {
    type: actions.UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY,
    payload: {
      accountNumber: accountNo,
      accountName: accountName,
      bankName: accountBank,
      bankCode: bankCode,
      currency: currency,
      routingNo: routingNo,
      accountType: accountType,
      isBeneficiery: isBeneficiery,
    },
  };
};

export const getTransferFields = (currency: string) => {
  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetTransferFields}${currency}`,
      method: "get",
      data: {},
      onStart: actions.TRANSFER_GET_FIELDS_START,
      onSuccess: actions.TRANSFER_GET_FIELDS,
      onError: actions.TRANSFER_GET_FIELDS_FAILED,
    },
  };
};

export function confirmPaymentTransfer(walletId: string, params: any) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiConfirmTransfer + `/${walletId}/fund`,
      method: "post",
      data: params,
      onStart: actions.CONFIRM_TRANSFER_PAYMENT_START,
      onSuccess: actions.CONFIRM_TRANSFER_PAYMENT,
      onError: actions.CONFIRM_TRANSFER_PAYMENT_FAILED,
    },
  };
}
