import { RootState } from "store/store";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { toast } from "react-toastify";

interface InitialState {
    generateApiKeyLoading: boolean;
    generateApiKeyData: any;

    webhookLogsLoading: boolean;
    webhookLogsData: any;

    webhookDetailsLoading: boolean;
    webhookDetailsData: any;

    retryLoading: boolean;
    retryWebhookData: any;
}

const initialState: InitialState = {
    webhookLogsLoading: false,
    webhookLogsData: null,

    webhookDetailsLoading: false,
    webhookDetailsData: null,

    retryLoading: false,
    retryWebhookData: null,

    generateApiKeyLoading: false,
    generateApiKeyData: null,
}


export default function reducer(state = initialState, { type, payload }: any): InitialState {
    switch (type) {

        case actions.GENERATE_API_KEY_START:
            return {
                ...state,
                generateApiKeyLoading: true,
                generateApiKeyData: null,
            }

        case actions.GENERATE_API_KEY_DONE:
            toast(payload?.message)
            return {
                ...state,
                generateApiKeyLoading: false,
                generateApiKeyData: payload,
            }

        case actions.GENERATE_API_KEY_FAILED:
            errorReducer(payload)
            return {
                ...state,
                generateApiKeyLoading: false,
                generateApiKeyData: payload,
            }

        case actions.RETRY_WEBHOOK_LOG_START:
            return {
                ...state,
                retryLoading: true,
                retryWebhookData: null,
            }

        case actions.RETRY_WEBHOOK_LOG_DONE:
            toast(payload?.message)
            return {
                ...state,
                retryLoading: false,
                retryWebhookData: payload,
            }

        case actions.RETRY_WEBHOOK_LOG_FAILED:
            errorReducer(payload)
            return {
                ...state,
                retryLoading: false,
                retryWebhookData: payload,
            }

        case actions.GET_WEBHOOK_DETAILS_START:
            return {
                ...state,
                webhookDetailsLoading: true,
                webhookDetailsData: null,
            }

        case actions.GET_WEBHOOK_DETAILS_DONE:
            return {
                ...state,
                webhookDetailsLoading: false,
                webhookDetailsData: payload,
            }

        case actions.GET_WEBHOOK_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                webhookDetailsLoading: false,
                webhookDetailsData: payload,
            }

        case actions.GET_WEBHOOK_LOGS_START:
            return {
                ...state,
                webhookLogsLoading: true,
                webhookLogsData: null,
            }

        case actions.GET_WEBHOOK_LOGS_DONE:
            return {
                ...state,
                webhookLogsLoading: false,
                webhookLogsData: payload,
            }

        case actions.GET_WEBHOOK_LOGS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                webhookLogsLoading: false,
                webhookLogsData: payload,
            }

        case actions.RESET_WEBHOOK_PARAMS:
            return {
                ...state,
                generateApiKeyData: null,
                retryWebhookData: null,
            }

        default:
            return state;
    }
}


export const getRefundsStore = (state: RootState) => state.refunds;