import PATHS from 'NavigationRoute';
import useTransfersProvider from '../context/useTransfersProvider'
import ShigaButton from 'components/common/form/ShigaButton';
import useToolkit from 'hooks/useToolkit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteBulkTrfFile, initializeBulkTransfer } from 'store/transfer/action';

type WrapperProps = {
   title: string,
   value: string,
   hideBorder: boolean
}

const ConfirmBulkTransfer = () => {
   const navigate = useNavigate();
   const { dispatch, useSelector, showCustomSuccessToast } = useToolkit()
   const { bulkRecipients, endTransferSession, bulkRecipientsCountText, setTrfModalOpen } = useTransfersProvider();
   const {
      bulkTransferLoading: requestLoading,
      bulkTransferResponse,
      bulkTrfRecipientsData
   } = useSelector((state: any) => state.transfer)

   const bulkTrfId = bulkRecipients?.data?.bulkTransfer?.id;
   const totalBulkAmount = bulkTrfRecipientsData?.data?.data.reduce((total: any, transaction: any) => total + transaction.amount, 0);
   const formattedAmount = `${totalBulkAmount?.toLocaleString()} NGN`;

   const bulkReview = [
      { title: 'Amount', value: formattedAmount },
      { title: 'Transaction Type', value: 'Bank Transfer' },
      { title: 'Sent to', value: bulkRecipientsCountText },
      { title: 'From', value: 'NGN Wallet' },
   ]

   const onProceed = () => {
      dispatch(initializeBulkTransfer(bulkTrfId))
   };

   useEffect(() => {
      if (bulkTransferResponse?.success === true) {

         showCustomSuccessToast({
            message: `You have successfully completed a bulk payment of  ${formattedAmount} to ${bulkRecipientsCountText}`,
            onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
         });

         setTrfModalOpen(false);
         endTransferSession();

         setTimeout(() => {
            dispatch(deleteBulkTrfFile());
         }, 500);
      }
      // eslint-disable-next-line
   }, [bulkTransferResponse])

   return (
      <div className='w-full lg:w-3/6'>

         <div className='rounded-xl border border-shiga-gray-50 mb-12'>

            <div className="p-4">
               {bulkReview.map(({ title, value }, idx) => {
                  return (
                     <ReviewWrapper
                        key={title}
                        title={title}
                        value={value}
                        hideBorder={idx === bulkReview?.length - 1}
                     />
                  )
               })}
            </div>
         </div>

         <ShigaButton
            darkBg
            fullWidth
            onClick={onProceed}
            loading={requestLoading}
            text={requestLoading ? 'Submitting...' : 'Confirm & Pay'}
         />
      </div>
   )
}

const ReviewWrapper = ({ title, value, hideBorder }: WrapperProps) => {
   return (
      <div className={`flex-between w-full py-3 ${hideBorder ? 'pb-0' : 'border-b border-shiga-gray-50'}`}>
         <p className="mb-0 text-shiga-gray-250">{title}</p>
         <p className="mb-0 text-shiga-dark-200 font-medium capitalize">{value}</p>
      </div>
   )
}

export default ConfirmBulkTransfer