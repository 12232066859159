import { Business } from "types/business";
import * as actions from "./actionTypes";
import { RootState } from "store/store";
import { UserProfile } from "types/userProfile";
import { DashboardWallet } from "types/wallet";

interface InitialState {
    userLogin: boolean;
    userInfo: {
        token?: string;
        userProfile?: UserProfile;
        businessProfile?: Business
    }
    businessProfile?: Business;
    currentKybStep: number;
    transferWallet: DashboardWallet;

    toggleEnvLoading: boolean;
    toggleEnvData: any;
}

const initialState: InitialState = {
    userLogin: false,
    userInfo: {},
    currentKybStep: 1,

    transferWallet: {
        currency: '',
        balance: 0,
        status: '',
        id: ''
    },

    toggleEnvLoading: false,
    toggleEnvData: null,
};


export default function reducer(state = initialState, action: any) {

    switch (action.type) {

        case actions.RESET_ENV_PROPS:
            state = {
                ...state,
                toggleEnvLoading: false,
                toggleEnvData: null,
            }
            return state;

        case actions.TOGGLE_ENV_START:
            state = {
                ...state,
                toggleEnvLoading: true,
                toggleEnvData: null,
            }
            return state;

        case actions.TOGGLE_ENV_DONE:
            state = {
                ...state,
                toggleEnvLoading: false,
                toggleEnvData: action?.payload,
            }
            return state;

        case actions.TOGGLE_ENV_FAILED:
            state = {
                ...state,
                toggleEnvLoading: false,
                toggleEnvData: null
            }
            return state;

        case actions.SET_TRANSFER_WALLET:
            state = {
                ...state,
                transferWallet: action.payload,
            }
            return state;

        case actions.UPDATE_KYB_STEP:
            state = {
                ...state,
                currentKybStep: action.payload,
            }
            return state;


        case actions.LOGIN_USER:
            state = {
                ...state,
                userLogin: action.payload?.data?.token ? true : false,
                userInfo: {
                    token: action.payload?.data?.token ?? undefined,
                },
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.REGISTER_USER:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.REGISTER_USER_START:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.REGISTER_USER_FAILED:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.UPDATE_USER_INFO:
            state = {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...action.payload,
                }
            }
            return state;
        case actions.LOGOUT_USER:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            // window.location.href = "/login";
            return state;


        default:
            return state;
    }
}

export const getAuthPersist = (state: RootState) => state.auth.userPersist