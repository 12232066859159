import { toast } from "react-toastify";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

interface DataResponse<T> {
    success: boolean;
    statusCode: number;
    message: string;
    data: T;
}

interface InitialState {
    isLoadingCards: boolean;
    cards: DataResponse<any[]> | null;
    createCardLoading: boolean;

    cardDetailsLoading: boolean;
    cardDetailsData: any;
    selectedCard: any;

    fundCardLoading: boolean;
    fundCardData: any;

    toggleCardLoading: boolean;
    toggleCardData: any;

    renameCardLoading: boolean;
    renameCardData: any;

    deleteCardLoading: boolean;
    deleteCardData: any;

    cardTrxLoading: boolean;
    cardTrxData: any;
}

const initialState: InitialState = {
    cards: null,
    isLoadingCards: false,
    createCardLoading: false,

    cardDetailsLoading: false,
    cardDetailsData: null,
    selectedCard: null,

    fundCardLoading: false,
    fundCardData: null,

    toggleCardLoading: false,
    toggleCardData: null,

    renameCardLoading: false,
    renameCardData: null,

    deleteCardLoading: false,
    deleteCardData: null,

    cardTrxLoading: false,
    cardTrxData: null,
}


export default function reducer(state = initialState, action: any): InitialState {
    switch (action.type) {

        case actions.GET_CARD_TRANSACTIONS_START:
            return {
                ...state,
                cardTrxLoading: true,
                cardTrxData: null,
            }

        case actions.GET_CARD_TRANSACTIONS_DONE:
            return {
                ...state,
                cardTrxData: action.payload,
                cardTrxLoading: false
            }

        case actions.GET_CARD_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                cardTrxData: null,
                cardTrxLoading: false
            }

        case actions.DELETE_CARD_START:
            return {
                ...state,
                deleteCardLoading: true,
                deleteCardData: null,
            }

        case actions.DELETE_CARD_DONE:
            return {
                ...state,
                deleteCardData: action.payload,
                deleteCardLoading: false
            }

        case actions.DELETE_CARD_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                deleteCardData: null,
                deleteCardLoading: false
            }

        case actions.RENAME_CARD_START:
            return {
                ...state,
                renameCardLoading: true,
                renameCardData: null,
            }

        case actions.RENAME_CARD_DONE:
            toast.success(action.payload.message);
            return {
                ...state,
                renameCardData: action.payload,
                renameCardLoading: false,
                selectedCard: {
                    ...state.selectedCard,
                    name: action?.payload?.data?.name
                }
            }

        case actions.RENAME_CARD_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                renameCardData: null,
                renameCardLoading: false
            }

        case actions.TOGGLE_CARD_STATUS_START:
            return {
                ...state,
                toggleCardLoading: true,
                toggleCardData: null,
            }

        case actions.TOGGLE_CARD_STATUS_DONE:
            toast.success(action.payload.message);
            const cardIsActive = state.cardDetailsData?.data?.status === 'active';

            return {
                ...state,
                toggleCardData: action.payload,
                toggleCardLoading: false,
                cardDetailsData: {
                    ...state.cardDetailsData,
                    data: {
                        ...state.cardDetailsData.data,
                        status: cardIsActive ? 'inactive' : 'active'
                    },
                }
            }

        case actions.TOGGLE_CARD_STATUS_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                toggleCardData: null,
                toggleCardLoading: false
            }

        case actions.FUND_CARD_START:
            return {
                ...state,
                fundCardLoading: true,
                fundCardData: null,
            }

        case actions.FUND_CARD_DONE:
            toast.success(action.payload.message);
            return {
                ...state,
                fundCardData: action.payload,
                fundCardLoading: false
            }

        case actions.FUND_CARD_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                fundCardData: null,
                fundCardLoading: false
            }

        case actions.SELECT_VIRTUAL_CARD:
            return {
                ...state,
                selectedCard: action.payload,
            }

        case actions.GET_CARD_DETAILS_START:
            return {
                ...state,
                renameCardData: null,
                cardDetailsLoading: true,
                cardDetailsData: null,
            }

        case actions.GET_CARD_DETAILS_DONE:
            return {
                ...state,
                cardDetailsData: action.payload,
                cardDetailsLoading: false
            }

        case actions.GET_CARD_DETAILS_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                cardDetailsData: null,
                cardDetailsLoading: false
            }

        case actions.GET_CARDS_START:
            return {
                ...state,
                isLoadingCards: true
            }

        case actions.GET_CARDS:
            return {
                ...state,
                cards: action.payload,
                isLoadingCards: false
            }

        case actions.GET_CARDS_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                cards: null,
                isLoadingCards: false
            }

        case actions.CREATE_CARDS_FAILED:
            errorReducer(action.payload);
            return {
                ...state,
                createCardLoading: false
            }

        case actions.CREATE_CARDS_START:
            return {
                ...state,
                createCardLoading: true
            }

        case actions.CREATE_CARDS:
            toast(action?.payload?.message)
            return {
                ...state,
                createCardLoading: false
            }

        default:
            return state;
    }
}