const defaultProductForm = {
   productName: '',
   categoryId: '4395d195-5578-4f44-a29b-504f63455aeb',
   price: '',
   weightUnit: '',
   weight: '',
   quantity: '',
}

export {
   defaultProductForm
}