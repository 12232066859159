import { useEffect, useState } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import ShigaButton from 'components/common/form/ShigaButton';
import { useSelector } from 'react-redux';
import Maybe from 'components/common/Maybe';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { ReactComponent as IconKey } from "assets/images/icons/Key.svg";
import { getKeys } from 'store/entities/settings/action';
import NewSecretKeyModal from './NewSecretKeyModal';
import { useLocation, useNavigate } from 'react-router-dom';


const KeysTab = () => {
   const { copyItem, dispatch } = useToolkit();
   const [keysModalOpen, setKeysModalOpen] = useState(false);
   const { keysLoading, keys } = useSelector((s: any) => s.entities.settings);
   const location = useLocation();
   const navigate = useNavigate();
   const getApiKeys = () => dispatch(getKeys());

   useEffect(() => {
      getApiKeys();
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);

   return (

      <>
         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg-flex-nowrap lg:border-y lg:border-shiga-gray-50 py-4 mb-10 mt-2">

            <div className="page-title w-full lg:w-max">
               API Configuration
            </div>

            <div className="w-full lg:w-max pt-5 lg:pt-0 flex items-center">
               <ShigaButton
                  whiteBg
                  text={
                     <div className='flex items-center'>
                        <IconKey className="w-[18px] h-[18px] mr-2" />
                        <span className="text-sm">
                           Generate new Key
                        </span>
                     </div>
                  }
                  onClick={() => setKeysModalOpen(true)}
               />
            </div>

         </div>

         <div className="pb-24">

            <Table>

               <Maybe condition={(keysLoading === false && !keys?.data?.id) || (keys?.data?.id?.length > 0)}>
                  <Table.Head>
                     <th>Name</th>
                     <th>Token</th>
                     <th>Date Created</th>
                     <th></th>
                     <th></th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     colspan={6}
                     loading={keysLoading}
                     data={keys?.data?.id?.length}
                     loadingText="Fetching API Config"
                     total={keys?.data?.id?.length || 0}
                     emptyUI={
                        <div>
                           Empty API config
                        </div>
                     }
                  />

                  {keysLoading === false && keys?.data?.id?.length > 0 && (
                     <>
                        <tr>
                           <td>
                              Secret Key
                           </td>

                           <td>
                              <div className="flex items-center">
                                 {[...Array(keys?.data?.secretKey?.length)].map((item: any) => (
                                    <span key={item}>
                                       &#8226;
                                    </span>
                                 ))}

                                 <button className='ml-1.5' onClick={() => copyItem(keys?.data?.secretKey, 'Secret Key')}>
                                    <CopyIcon />
                                 </button>
                              </div>
                           </td>

                           <td>
                              {moment(keys?.data?.updatedAt).format('MMM D, YYYY [at] h:mma')}
                           </td>
                        </tr>

                        <tr>
                           <td>
                              Public Key
                           </td>

                           <td>
                              <div className="flex items-center">
                                 {keys?.data?.publicKey ?? 'N/A'}

                                 <button className='ml-1.5' onClick={() => copyItem(keys?.data?.publicKey, 'Public Key')}>
                                    <CopyIcon />
                                 </button>
                              </div>
                           </td>

                           <td>
                              {moment(keys?.data?.updatedAt).format('MMM D, YYYY [at] h:mma')}
                           </td>
                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                     </>
                  )}
               </Table.Body>
            </Table>
         </div>

         <NewSecretKeyModal
            isOpen={keysModalOpen}
            getApiKeys={getApiKeys}
            setIsOpen={setKeysModalOpen}
         />

      </>
   )
}

export default KeysTab