import { useEffect, useState } from 'react'
import { SelectInput, FileUploadForm } from 'components/common/form'
import useToolkit from 'hooks/useToolkit';
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import validate from 'utils/validate';
import ShigaButton from 'components/common/form/ShigaButton';
import useTransfersProvider from '../context/useTransfersProvider';
import { jsonToFormData } from 'utils/jsonToFormData';
import { deleteBulkTrfFile, uploadBulkTransferFile } from 'store/transfer/action';


const UploadBulkRecipients = () => {
   const [file, setFile] = useState<any>(null);
   const { dispatch, useSelector } = useToolkit();
   const { bulkTrfFileUploadLoading, bulkTrfFileUploadResponse } = useSelector((state: any) => state.transfer)

   const { incrementBulkTrfStep, setBulkRecipients } = useTransfersProvider();
   const options = [{ label: 'NGN', value: 'NGN', }]

   const instructions = [
      "You can upload .xlsx or .csv file",
      "Make sure your file has correct headers such as Bank Account No, Bank Name, Account Name and Amount",
      "Number of row should not exceed 1,000"
   ]

   const onProceed = () => {
      const params = jsonToFormData({ file });
      dispatch(uploadBulkTransferFile(params))
   }

   useEffect(() => {
      if (bulkTrfFileUploadResponse?.success === true) {
         setBulkRecipients(bulkTrfFileUploadResponse);
         incrementBulkTrfStep();

         setTimeout(() => {
            dispatch(deleteBulkTrfFile());
         }, 300);
      }
      // eslint-disable-next-line
   }, [bulkTrfFileUploadResponse])

   return (
      <div className="w-full">

         <div className="flex flex-col space-y-7">

            <div className="">
               <h6 className="text-shiga-dark font-medium mb-4">FOLLOW THE GUIDELINES BELOW</h6>
               {instructions.map((item, idx) => (
                  <span key={idx} className="flex items-start mt-4">
                     <div className="pt-1 mr-2.5">
                        <IconInfo className="w-3 h-3" />
                     </div>
                     <p className="text-shiga-black text-sm">{item}</p>
                  </span>
               ))}
            </div>

            <SelectInput
               disabled
               required
               type="text"
               label="Pay from"
               value="NGN"
               // value={form.currency}
               onChange={(e: any) => { }}>
               {options.map((option: any) => (
                  <option key={option?.label} value={option?.label}>{option?.label}</option>
               ))}
            </SelectInput>

            <div className="w-full relative">

               <FileUploadForm
                  required
                  filename=''
                  data={file}
                  filetype={["csv"]}
                  label="Upload a bulk sheet"
                  checkFormValidation={validate}
                  placeholder={file?.name || ''}
                  onchange={(e: any) => setFile(e)}
               />

               <div className="absolute right-0 top-0">
                  <a href='https://storage.googleapis.com/shiga-app.appspot.com/DOCS%2Fbulk_transfer_sample.csv' target="_blank" rel="noreferrer" download className="w-max text-xs text-shiga-purple-50 underline font-medium">
                     Sample CSV file
                  </a>

                  <a href="https://res.cloudinary.com/dvfxrgvcr/raw/upload/v1720538184/Bank_Codes_tqanpa.xlsx" target="_blank" rel="noreferrer" download className="w-max underline text-xs text-shiga-purple-50 font-medium ml-3.5">
                     Bank codes
                  </a>
               </div>

            </div>
         </div>

         <div className="w-full pt-10">
            <ShigaButton
               darkBg
               fullWidth
               onClick={onProceed}
               disabled={file === null}
               loading={bulkTrfFileUploadLoading}
               text={bulkTrfFileUploadLoading ? 'Uploading bulk file' : 'Continue'}
            />
         </div>

      </div>
   )
}

export default UploadBulkRecipients