import React, { useEffect, useState } from "react";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyUI from "components/common/EmptyUi";
import { Menu } from "@headlessui/react";
import Dropdown from "utils/dropdown";
import DateFormat from "utils/dateFormat";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import EmptyOrder from "assets/images/icons/empty-order.svg";
import Pagination from "components/common/Pagination";
import Search from "components/common/Search";
import { CancelOrderModal } from "./CancelOrderModal";
import { useToggle } from "hooks/useToggle";
import useUrlQueryParams from "utils/useUrlQueryParams";
import processParams from 'utils/processParams';
import useToolkit from "hooks/useToolkit";
import { getProductOrders } from "store/ecom/action";
import { RootState } from "store/store";


const ProductOrderTable = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showDelete, toggleDelete] = useToggle(false);
  const [currentOrder, setCurrentOrder] = useState<any>({});
  const { urlQueryParams } = useUrlQueryParams();
  const { dispatch, useSelector } = useToolkit();
  const { productOrdersData: orders, productOrdersLoading: isLoading, activeProduct: product, productDetailsLoading: detailsLoading } = useSelector((state: RootState) => state.ecom)
  const [listParams, setListParams] = useState({ "page": 1, "limit": 9, });

  const handleShowDelete = (arg: any) => {
    setCurrentOrder(arg);
    toggleDelete();
  };

  const fetchOrders = () => {
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
    params = processParams(urlQueryParams, params, "search", "string", "", "search");

    setListParams(params);
    if (product !== null) {
      dispatch(getProductOrders(product?.id));
    }
  }

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [location]);

  return (
    <div>

      <div className="flex justify-between items-center mb-2">
        <Pagination meta={orders?.data?.meta} limit={9} />

        <div className="pb-[26px]">
          <Search
            value={searchQuery}
            placeholder="Search orders"
            onSearchChange={(e: any) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div>
        <Table tableClass="pb-24">

          <Table.Head>
            <th>S/N </th>
            <th>Email</th>
            <th>Quantity</th>
            <th>Amount</th>
            <th>Date</th>
          </Table.Head>

          <Table.Body>
            <TableContent
              colspan={5}
              data={orders?.data}
              total={orders?.data?.length}
              loading={detailsLoading || isLoading}
              loadingText="Fetching product orders"
              emptyUI={
                <EmptyUI
                  icon={EmptyOrder}
                  header="No Orders yet"
                  subheader="You have not received any orders for this product yet"
                />
              }
            />

            {isLoading === false && orders?.data && orders?.data?.map((product: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div>
                      <div>
                        <div className="font-medium ">
                          {product?.email ?? "-"}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{product?.name ?? "_-"}</td>
                  <td>
                    {product?.amount ?? "-"} {product?.currency ?? "-"}
                  </td>

                  <td>
                    <div className="flex items-center">
                      {DateFormat(product?.dateCreated) ?? "-"}
                      <div className="ml-2">
                        <Dropdown dropdownClass="dropdown-right">
                          <></>
                          <div>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon text-shiga-warn"
                              onClick={() => handleShowDelete(product)}
                            >
                              <IconClose />
                              Cancel Order
                            </Menu.Item>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </Table.Body>
        </Table>
      </div>

      <CancelOrderModal
        open={showDelete}
        order={currentOrder}
        closeModal={toggleDelete}
      />
    </div>
  );
};

export default ProductOrderTable;
