import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { ReactComponent as TrxAvatar } from "assets/images/icons/trx-avatar.svg";
import useToolkit from "hooks/useToolkit";
import Maybe from "components/common/Maybe";

type Props = {
  order: any;
  details: any;
  activeTab: string;
};

const OrderDetail = ({ order, activeTab, details }: Props) => {
  const { copyItem } = useToolkit();
  const showDetails = activeTab === "details";

  const isCancelled = details?.order?.status === 'CANCELED';
  const inTransit = details?.order?.status === 'IN_TRANSIT';
  const notShipped = details?.order?.status === 'NOT_SHIPPED';

  return (
    <div className="w-full">
      <Maybe condition={showDetails}>
        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Status</div>

          <div className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                    ${isCancelled ? 'border-shiga-red-40 text-shiga-red-100'
              : inTransit ? 'border-shiga-orange-50 text-shiga-orange-100'
                : notShipped ? 'border-shiga-gray-75 text-shiga-gray-200'
                  : ''
            }`}>
            {isCancelled ? 'Cancelled' : inTransit ? 'In transit' : notShipped ? 'Not Shipped' : details?.order?.status}
          </div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Customer's Name</div>

          <div className="flex items-center capitalize">
            {`${details?.user?.firstName} ${details?.user?.lastName}`}
          </div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Country</div>

          <div className="flex items-center">{order?.email ?? 'N/A'}</div>
        </div>
        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Courier</div>

          <div className="flex items-center">
            <TrxAvatar />

            <span className="capitalize pt-1 pl-1">
              {details?.provider?.toLowerCase()}
            </span>
          </div>
        </div>

        <div className={`transaction-detail-inline-wrapper-last`}>
          <div className="transaction-detail-inline-key">Transaction ID</div>

          <div className="transaction-detail-inline-value">
            <div className="flex items-center font-medium">
              {details?.order?.transactionId?.substring(0, 20)}
              <button
                className="ml-1.5"
                onClick={() => copyItem(details?.order?.transactionId, "Transaction ID")}>
                <CopyIcon width={20} />
              </button>
            </div>
          </div>
        </div>
      </Maybe>

    </div>
  );
};

export default OrderDetail;
