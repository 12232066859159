import { Input } from "components/common/form";
import ShigaButton from "components/common/form/ShigaButton";
import { useEffect, useState } from "react";
import useTransfersProvider from "../../context/useTransfersProvider";
import { defaultGbpForm } from "../../context/exports";


const GbpAccountDetails = () => {
   const { incrementSingleTrfStep, gbpForm, setGbpForm } = useTransfersProvider()
   // eslint-disable-next-line
   const [formValid, setFormValid] = useState(false);

   const onSubmit = (e: any) => {
      e.preventDefault();
      incrementSingleTrfStep();
   }

   useEffect(() => {
      setGbpForm(defaultGbpForm)
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      let hasEmptyValues = false;

      for (let key in gbpForm) {
         if (gbpForm[key] === "" || gbpForm[key].length < 4) {
            setFormValid(false)
            hasEmptyValues = true;
         }
      }
      if (!hasEmptyValues) {
         setFormValid(true);
      }
      // eslint-disable-next-line
   }, [gbpForm])

   return (
      <div>
         <form onSubmit={onSubmit}>
            <div className="w-full space-y-7s">

               <Input
                  required
                  type="text"
                  label="Account Name"
                  value={gbpForm.accountName}
                  placeholder="Enter account Name"
                  onChange={(e: any) => setGbpForm({ ...gbpForm, accountName: e.target.value })}
               />

               <Input
                  required
                  type="number"
                  label="Account Number"
                  value={gbpForm.accountNumber}
                  placeholder="0000000000"
                  onChange={(e: any) => setGbpForm({ ...gbpForm, accountNumber: e.target.value })}
               />

               <Input
                  required
                  type="number"
                  label="UK Sort Code"
                  value={gbpForm.sortCode}
                  placeholder="00000"
                  onChange={(e: any) => setGbpForm({ ...gbpForm, sortCode: e.target.value })}
               />

               <div className="pt-8">
                  <ShigaButton
                     darkBg
                     disabled
                     fullWidth
                     type='submit'
                     text='Continue'
                  // disabled={!formValid}
                  />
               </div>
            </div>
         </form>

      </div>
   )
}

export default GbpAccountDetails