import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { Input, SelectInput } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';
import { generateSecretKey } from 'store/webhooks/action';

type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   getDevices: () => void;
}

const defaultForm = {
   deviceType: '',
   deviceCode: '',
   deviceName: ''
}

const NewDeviceModal = ({ isOpen, setIsOpen, getDevices }: Props) => {
   const [form, setForm] = useState(defaultForm)
   const { dispatch, useSelector } = useToolkit();
   const { generateApiKeyLoading } = useSelector((state: RootState) => state.webhook)

   const formIsValid = form.deviceName?.length > 0 && form.deviceType?.length > 0 && form.deviceCode?.length > 0;

   const handleSubmit = (e: any) => {
      e.preventDefault();
      dispatch(generateSecretKey(form))
   }

   useEffect(() => {
      // if (generateApiKeyData?.success === true) {
      setForm(defaultForm)
      setIsOpen(false);
      getDevices()

      // setTimeout(() => {
      //    dispatch(resetWebhookParams())
      // }, 1500);
      // }
      // eslint-disable-next-line
   }, [])

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Add a Device'
         isButtonLoading={false}>

         <div className='w-full relative'>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-b border-shiga-gray-50 space-y-7">

                  <Input
                     required={true}
                     value={form.deviceName}
                     placeholder='Enter a device name'
                     label='Device Name'
                     onChange={(e: any) => setForm({ ...form, deviceName: e.target.value })}
                  />

                  <SelectInput
                     required={true}
                     value={form.deviceType}
                     label='Device Type'
                     onChange={(e: any) => setForm({ ...form, deviceType: e.target.value })}>
                     <option value="">Select</option>
                     <option value="type1">Type 1</option>

                     {/* {expiryOptions.map((item) => (
                        <option value={item.value} key={item.value}>
                           {item.label}
                        </option>
                     ))} */}
                  </SelectInput>

                  <Input
                     required={true}
                     label='Device Code'
                     value={form.deviceCode}
                     placeholder='ie 8490000398473'
                     onChange={(e: any) => setForm({ ...form, deviceCode: e.target.value })}
                  />

               </div>

               <div className="mt-5">
                  <ShigaButton
                     darkBg
                     fullWidth
                     type='submit'
                     text="Add Device"
                     disabled={!formIsValid}
                     loading={generateApiKeyLoading}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default NewDeviceModal