import { useEffect, useState } from "react";
import Table from 'components/common/Table';
import { TableContent } from "components/common/TableContent";
import useTransfersProvider from '../context/useTransfersProvider'
import { ReactComponent as BackButton } from "assets/images/icons/arrow-left-bordered.svg";
import { ReactComponent as IconEmptyTransactions } from "assets/images/icons/empty-transactions.svg";
import { ReactComponent as IconEdit } from "assets/images/icons/trf-edit.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/trf-delete.svg";
import useToolkit from "hooks/useToolkit";
import { fetchBulkTransferContent } from "store/transfer/action";
import EditBulkTransfer from "./actions/EditBulkTransfer";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { useLocation } from "react-router-dom";
import Pagination from "components/common/Pagination";
import SearchBox from "components/common/SearchBox";
import DeleteBulkTransferRecipient from "./actions/DeleteBulkTransferRecipient";
import ShigaButton from "components/common/form/ShigaButton";


const ReviewBulkTransferRecipients = () => {
   const { decrementBulkTrfStep, bulkRecipients, incrementBulkTrfStep } = useTransfersProvider();
   const [selected, setSelected] = useState(null);
   const location = useLocation();

   const [editOpen, setEditOpen] = useState(false);
   const [deleteOpen, setDeleteOpen] = useState(false);
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 10,
   });

   const { dispatch, useSelector } = useToolkit();
   const { urlQueryParams } = useUrlQueryParams();
   const {
      bulkTrfRecipientsLoading: isLoading,
      bulkTrfRecipientsData,
      bulkTransferLoading: requestLoading,
   } = useSelector((state: any) => state.transfer)

   const recipientsArray = bulkTrfRecipientsData?.data?.data;
   const bulkTrfId = bulkRecipients?.data?.bulkTransfer?.id;

   const toggleEditModal = () => setEditOpen(!editOpen);
   const toggleDeleteModal = () => setDeleteOpen(!deleteOpen);

   const fetchRecipients = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 10, "limit");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "sortBy", "string", "", "sortBy");
      setListParams(params);

      dispatch(fetchBulkTransferContent({ id: bulkTrfId, params }))
   }

   useEffect(() => {
      fetchRecipients();
      // eslint-disable-next-line
   }, [location])

   return (
      <div className='pt-[100px]'>

         {/* <button onClick={decrementBulkTrfStep}>
            <BackButton />
         </button> */}

         <FilterSearch
            transactions={bulkTrfRecipientsData}
            decrementBulkTrfStep={decrementBulkTrfStep}
         />

         <div>

            <Table>

               <Table.Head>
                  <th>S/N</th>
                  <th>ACCOUNT NAME</th>
                  <th>ACCOUNT NUMBER</th>
                  <th>BANK NAME</th>
                  <th>AMOUNT</th>
                  <th></th>
               </Table.Head>

               <Table.Body>

                  <TableContent
                     total={recipientsArray?.length || 0}
                     loading={isLoading}
                     loadingText="Fetching recipients"
                     data={recipientsArray}
                     colspan={6}
                     emptyUI={
                        <EmptyTransactions />
                     }
                  />

                  {recipientsArray?.map((item: any, idx: number) => {

                     return (
                        <tr className='font-inter' key={item.id}>

                           <td>
                              {(idx + 1).toString().padStart(2, '0')}
                           </td>

                           <td>
                              {item?.accountName}
                           </td>

                           <td className='font-medium'>
                              {item?.accountNumber}
                           </td>

                           <td>
                              {item?.bankName}
                           </td>

                           <td>
                              {item?.amount?.toLocaleString()} NGN
                           </td>

                           <td>
                              <div className="flex items-center">
                                 <button onClick={() => { setSelected(item); toggleEditModal() }}>
                                    <IconEdit />
                                 </button>

                                 <div className="h-4 w-[1px] bg-shiga-gray-50 mx-3" />

                                 <button onClick={() => { setSelected(item); toggleDeleteModal() }}>
                                    <IconDelete />
                                 </button>
                              </div>
                           </td>

                        </tr>
                     )
                  })}
               </Table.Body>
            </Table>

            <div className="mt-8 w-full flex justify-end">
               <ShigaButton
                  darkBg
                  className="!px-20"
                  text={
                     <span className="px-12">
                        {requestLoading ? 'Submitting...' : 'Continue'}
                     </span>
                  }
                  onClick={() => incrementBulkTrfStep()}
                  disabled={isLoading || requestLoading || recipientsArray?.length < 1}
               />
            </div>

         </div>

         <EditBulkTransfer
            isOpen={editOpen}
            record={selected}
            setIsOpen={setEditOpen}
            refetchRecipients={fetchRecipients}
         />

         <DeleteBulkTransferRecipient
            record={selected}
            isOpen={deleteOpen}
            setIsOpen={setDeleteOpen}
            refetchRecipients={fetchRecipients}
         />

      </div>
   )
}

const EmptyTransactions = () => {
   return (
      <div className="flex items-center justify-center flex-col mt-40 text-center">
         <IconEmptyTransactions />
         <h4 className="font-ojah text-[26px] leading-8 mt-6 mb-2">
            No Recipients Found
         </h4>
         <p className="text-shiga-dark w-full">
            No recipients were found in the file you uploaded
         </p>
      </div>
   )
}


export const FilterSearch = ({ transactions, decrementBulkTrfStep }: { transactions: any; decrementBulkTrfStep: () => void }) => {

   return (
      <div className="my-6 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">


         <button onClick={decrementBulkTrfStep}>
            <BackButton />
         </button>


         {/* <div className="w-full lg:w-2/4">
            <CustomTableFilter
               filterTitle="Sort by"
               dropdownClass=''
               filters={[
                  {
                     title: "Status",
                     name: "sortBy",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Invalid",
                           payload: "INVALID",
                        },
                        {
                           display: "Verified",
                           payload: "VERIFIED",
                        },
                     ],
                  },
               ]}
            />
         </div> */}

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={transactions?.data?.meta} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  extraUrlParams='page=1'
                  placeholder="Search recipients"
               />
            </div>
         </div>
      </div>
   )
}

export default ReviewBulkTransferRecipients