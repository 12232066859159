import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Maybe from "./Maybe";
import { ReactNode } from "react";


interface TableContentProps {
    loading: boolean,
    loadingText?: string,
    data: any,
    colspan: number,
    total: number,
    emptyUI?: any,
    customLoader?: ReactNode,
    customErrorMsg?: ReactNode,
}


export const TableContent = (props: TableContentProps) => {
    const { loading, loadingText, data, colspan, total, emptyUI, customLoader, customErrorMsg } = props;
    // let thisCurrentPage = 1;
    // let thisPreviousPage = null;
    // let thisTotalPages = 1;
    let thisTotal = total;

    return (
        <>


            <Maybe condition={loading === true}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>
                        <Maybe condition={customLoader !== undefined}>
                            {customLoader}
                        </Maybe>

                        <Maybe condition={customLoader === undefined}>
                            <div className="table-info">
                                <FontAwesomeIcon icon="spinner" spin />
                                <div className="font-medium">
                                    {loadingText || "Loading"}
                                </div>
                            </div>
                        </Maybe>
                    </td>
                </tr>
            </Maybe>


            <Maybe condition={loading === false && !data}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>
                        <div className="table-info">
                            <FontAwesomeIcon icon="unlink" />
                            <div className="font-medium">
                                <Maybe condition={customErrorMsg !== undefined && customErrorMsg !== null}>
                                    {customErrorMsg}
                                </Maybe>

                                <Maybe condition={!customErrorMsg}>
                                    An error occurred
                                    <br />
                                    Please try again later.
                                </Maybe>
                            </div>
                        </div>
                    </td>
                </tr>
            </Maybe>


            <Maybe condition={loading === false && data?.length <= 0}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>

                        <Maybe condition={emptyUI === undefined}>
                            <Maybe condition={thisTotal === 0}>
                                <div className="table-info">
                                    <FontAwesomeIcon icon="list" />
                                    <div className="font-medium">
                                        No results found
                                    </div>
                                </div>
                            </Maybe>
                        </Maybe>
                        <Maybe condition={emptyUI !== undefined}>
                            {emptyUI}
                        </Maybe>

                        <Maybe condition={thisTotal !== 0}>
                            <div className="table-info">
                                <FontAwesomeIcon icon="exclamation-triangle" />
                                <div className="font-medium">
                                    Invalid Page Number
                                </div>
                                <div className="mt-2">
                                    <Link to="?page=1" className="btn btn-vetiva-brown btn-sm mx-auto">Goto Page 1</Link>
                                </div>
                            </div>
                        </Maybe>

                    </td>
                </tr>
            </Maybe>


        </>
    )
}
