import useToolkit from "hooks/useToolkit";
import { Loader } from "lucide-react";
import { RootState } from "store/store";

const EnvironmentToggleModal = () => {
	const { useSelector } = useToolkit();
	const { toggleEnvLoading } = useSelector((state: RootState) => state.auth.userPersist)

	return toggleEnvLoading ? (
		<section
			style={{ zIndex: "900" }}
			className={`fixed top-0 left-0 right-0 bottom-0 backdrop-blur-[2px] flexed min-w-full z-50 bg-[#000000] bg-opacity-[32%] p-4"`}>

			<div onClick={(e) => e.stopPropagation()} className='z-50 flex items-center justify-center flex-col'>

				<div className="pb-2">
					<Loader
						size='4rem'
						className="animate-spin text-white"
					/>
				</div>

				<div className="text-white font-medium">
					Switching environments...
				</div>

			</div>
		</section>
	) : null;
};

export default EnvironmentToggleModal;
