import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import moment from 'moment';
import EmptyTransactions from 'pages/transactions/components/EmptyTransactions';
import { ReactComponent as IconShare } from "assets/images/icons/Share.svg";
import TableDropdown from 'components/common/TableDropdown';
import { useState } from 'react';
import { currencyFormat } from 'utils/CurrencyFormat';
import Pagination from 'components/common/Pagination';
import SearchBox from 'components/common/SearchBox';
import Maybe from 'components/common/Maybe';

type Props = {
   loading: boolean;
   data: any;
   meta: any;
}

const CardTransactionsTable = ({ loading, data, meta }: Props) => {
   // eslint-disable-next-line
   const [selected, setSelected] = useState(null);

   return (
      <>
         <Maybe condition={!loading}>
            <div className="flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
               <Pagination meta={meta} />

               <div className="lg:pb-[26px]">
                  <SearchBox
                     name='search'
                     extraUrlParams='page=1'
                     placeholder="Search transactions"
                  />
               </div>
            </div>
         </Maybe>

         <Table>
            <Table.Head>
               <th>Narration</th>
               <th>Amount</th>
               <th>Type</th>
               <th>Reference</th>
               <th>Date</th>
               <th></th>
            </Table.Head>

            <Table.Body>

               <TableContent
                  total={data?.ength || 0}
                  loading={loading}
                  loadingText="Fetching card transactions"
                  data={data}
                  colspan={6}
                  emptyUI={
                     <EmptyTransactions />
                  }
               />

               {loading === false && data?.map((transaction: any) => {
                  return (
                     <tr className='cursor-pointer font-inter' key={transaction.id}>
                        <td>
                           {transaction?.description}
                        </td>
                        <td className={`font-medium`}>
                           {currencyFormat(transaction?.amount, transaction?.currency)}
                        </td>
                        <td>
                           <div className="border border-shiga-gray-75 rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium">
                              {transaction?.type}
                           </div>
                        </td>
                        <td>
                           <div className='flex items-center font-medium'>
                              {transaction.reference}
                           </div>
                        </td>
                        <td>
                           <div className='font-medium'>
                              {moment(transaction?.createdAt).format('MMM D, YYYY [at] h:mma')}
                           </div>
                        </td>

                        <td>
                           <div className='hover:bg-gray-200 py-1 px-px rounded-md' onClick={(e: any) => e.stopPropagation()}>
                              <TableDropdown
                                 allowClick={false}
                                 onRowClick={() => setSelected(transaction)}
                                 options={[
                                    {
                                       permission: false,
                                       title: 'Share receipt',
                                       Icon: IconShare,
                                       onClick: () => { },
                                    },
                                    // {
                                    //    permission: allowRefund,
                                    //    title: 'Refund payment',
                                    //    Icon: IconRefund,
                                    //    onClick: () => triggerRefund()
                                    // },
                                 ]}
                              />
                           </div>
                        </td>
                     </tr>
                  )
               }
               )}
            </Table.Body>
         </Table>
      </>
   )
}

export default CardTransactionsTable