import { ReactComponent as IconArrowCurve } from "assets/images/icons/ArrowCurve.svg";
import { ReactComponent as SidebarIcon } from "assets/images/icons/ecom.svg";
import useEcomProvider from "../context/useEcomProvider";

const LayoutSidebar = () => {
   const { sessionStep } = useEcomProvider()
   const navSteps = ["Upload product images", "Product Details"]

   return (
      <div className="w-80 pt-10 pl-10 pr-6 min-h-screen flex-shrink-0 hidden lg:block border-r border-gray-100 fixed top-20">

         <div className="flex items-center justify-between">

            <div className="flex items-center">
               <div className="w-8 h-8 mr-2 flex items-center justify-center overflow-hidden">
                  <SidebarIcon />
               </div>

               <div className="font-medium">
                  Add a Product
               </div>
            </div>

         </div>

         <div className="mt-3">
            {navSteps.map((step: string, index: number) => {

               return (
                  <div
                     key={step}
                     onClick={() => { }}
                     className={`flex items-center ${sessionStep > (index + 1) && "cursor-pointer group"}`}>
                     <div className="w-8 h-12 mr-3 flex items-center justify-center overflow-hidden">
                        {sessionStep > (index + 1) && <div className="w-0.5 h-full bg-shiga-purple opacity-35"></div>}
                        {sessionStep === (index + 1) && <IconArrowCurve className="relative -top-4 left-2" />}
                     </div>
                     <div className={`
                              h-12 flex flex-grow items-center
                              ${sessionStep === (index + 1) ? "text-shiga-purple" : "text-[#99999C]"}
                              group-hover:bg-gray-100`}>
                        {step}
                     </div>
                  </div>
               )
            })}
         </div>

      </div>
   )
}


export default LayoutSidebar