import * as actions from "./actionTypes";
import * as config from "data/config";

export function getCardTransactions(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCardTransactions + `${id}`,
            method: "get",
            data: {},
            onStart: actions.GET_CARD_TRANSACTIONS_START,
            onSuccess: actions.GET_CARD_TRANSACTIONS_DONE,
            onError: actions.GET_CARD_TRANSACTIONS_FAILED
        }
    }
}

export function renameCard({ id, payload }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRenameCard + `${id}/name`,
            method: "patch",
            data: payload,
            onStart: actions.RENAME_CARD_START,
            onSuccess: actions.RENAME_CARD_DONE,
            onError: actions.RENAME_CARD_FAILED
        }
    }
}

export function fundCard({ id, payload }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiFundCard + `${id}/topup`,
            method: "post",
            data: payload,
            onStart: actions.FUND_CARD_START,
            onSuccess: actions.FUND_CARD_DONE,
            onError: actions.FUND_CARD_FAILED
        }
    }
}

export function toggleCardStatus({ id, path }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiToggleCardStatus + `${id}/${path}`,
            method: "patch",
            data: {},
            onStart: actions.TOGGLE_CARD_STATUS_START,
            onSuccess: actions.TOGGLE_CARD_STATUS_DONE,
            onError: actions.TOGGLE_CARD_STATUS_FAILED
        }
    }
}

export function selectVirtualCard(payload: any) {
    return {
        type: actions.SELECT_VIRTUAL_CARD,
        payload
    }
}

export function getCardDetails(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCardDetails + `${id}/details`,
            method: "get",
            data: {},
            onStart: actions.GET_CARD_DETAILS_START,
            onSuccess: actions.GET_CARD_DETAILS_DONE,
            onError: actions.GET_CARD_DETAILS_FAILED
        }
    }
}

export function getCards(paylaod: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCards,
            method: "get",
            data: {},
            onStart: actions.GET_CARDS_START,
            onSuccess: actions.GET_CARDS,
            onError: actions.GET_CARDS_FAILED
        }
    }
}

export function createCard(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateCard,
            method: "post",
            data: payload,
            onStart: actions.CREATE_CARDS_START,
            onSuccess: actions.CREATE_CARDS,
            onError: actions.CREATE_CARDS_FAILED
        }
    }
}

export function lockCard() {
    return ({
        type: config.apiRequestStart,
        payload: {
            url: config.lockCard,
            method: "patch",
            data: {},
            onStart: actions.LOCK_CARD_START,
            onSuccess: actions.LOCK_CARD,
            onError: actions.LOCK_CARD_FAILED
        }
    })
}

export function unlockCard() {
    return ({
        type: config.apiRequestStart,
        payload: {
            url: config.unlockCard,
            method: "patch",
            data: {},
            onStart: actions.UNLOCK_CARD_START,
            onSuccess: actions.UNLOCK_CARD,
            onError: actions.UNLOCK_CARD_FAILED
        }
    })
}