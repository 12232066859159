import { useEffect } from "react";
import BackBtnIcon from "assets/images/icons/BackBtn";
import { useNavigate } from "react-router-dom";
import ProductOrderTable from "./table/ProductOrderTable";
import EditProduct from "./form/EditProduct";
import { ReactComponent as IconEllipsisHorizontal } from "assets/images/icons/ellipsis-horizontal.svg";
import { Button } from "components/ui/button";
import { RootState } from "store/store";
import useToolkit from "hooks/useToolkit";
import CustomSwitch from "components/common/form/CustomSwitch";
import { toggleProductAvailability } from "store/ecom/action";
import { Loader } from "lucide-react";
import Maybe from "components/common/Maybe";

const ViewProduct = () => {
  const navigate = useNavigate();
  const { dispatch, useSelector } = useToolkit();
  const { activeProduct, productDetailsLoading: detailsLoading, updateProductLoading } = useSelector((state: RootState) => state.ecom);
  const isAvailable = activeProduct && activeProduct.status === 'AVAILABLE';
  const isToggling = updateProductLoading || detailsLoading;

  const goBack = () => {
    navigate("/ojah/products");
  };

  useEffect(() => {
    if (detailsLoading === false && activeProduct === null) navigate("/ojah/products");
    // eslint-disable-next-line
  }, [detailsLoading, activeProduct])

  return (
    <div>
      <header className="flex justify-between items-center pb-5">

        <Maybe condition={!detailsLoading}>
          <BackBtnIcon className="cursor-pointer" onClick={goBack} />
        </Maybe>

        <Maybe condition={detailsLoading}>
          <div className="py-20 w-full flexed">
            <Loader className="animate-spin" size='5rem' />
          </div>
        </Maybe>

        <Maybe condition={!detailsLoading}>
          <div className="flex justify-end items-center">
            <div className="mr-8 flex items-center">

              <p className="text-zinc-800 text-[15px] font-medium font-inter mr-4">
                {isToggling ? 'Updating' : 'Status'}
              </p>

              <CustomSwitch
                hideText
                loading={isToggling}
                active={isAvailable}
                disabled={detailsLoading}
                onChange={() => dispatch(toggleProductAvailability(activeProduct?.id))}
              />

            </div>
            <Button
              variant="ghost"
              className="flex bg-[#f5f5f5] size-8 p-0 data-[state=open]:bg-muted">

              <IconEllipsisHorizontal className="size-4" />
              <span className="sr-only">Open menu</span>

            </Button>
          </div>
        </Maybe>

      </header>

      <Maybe condition={!detailsLoading}>
        <div className="mt-6 mb-4">
          <EditProduct product={activeProduct} />
        </div>
      </Maybe>

      <div className="mt-2 mb-12">
        <ProductOrderTable />
      </div>

    </div>
  );
};

export default ViewProduct;
