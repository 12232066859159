import { useEffect, useState } from "react";
import Table from "components/common/Table";
import Pagination from "components/common/Pagination";
import { TableContent } from "components/common/TableContent";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import EmptyOrder from "assets/images/icons/empty-order.svg";
import EmptyUI from "components/common/EmptyUi";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { useLocation } from "react-router-dom";
import { getAllOrders, getProductOrderDetails } from "store/ecom/action";
import { OrdersFilter } from "./OrdersFilter";
import moment from "moment";
import SearchBox from "components/common/SearchBox";
import OrderDetailModal from "../OrderDetailModal";
import TiledProductImage from "../TiledProductImage";


const OrderTable = () => {
  const location = useLocation();
  const { urlQueryParams } = useUrlQueryParams();
  const [detailsShown, setDetailsShown] = useState(false);
  const [selected, setSelected] = useState(null);

  const { dispatch, useSelector, formatCurrency } = useToolkit();
  const [listParams, setListParams] = useState({ "page": 1, "limit": 9, });
  const { allOrdersLoading, allOrdersData } = useSelector((state: RootState) => state.ecom)

  const toggleDetailsShown = () => setDetailsShown(!detailsShown);

  const fetchOrders = () => {
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
    params = processParams(urlQueryParams, params, "search", "string", "", "search");
    params = processParams(urlQueryParams, params, "status", "string", "", "status");
    params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
    params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

    setListParams(params);
    dispatch(getAllOrders(params));
  }

  const handleClick = (product: any) => {
    setSelected(product);
    toggleDetailsShown();
    dispatch(getProductOrderDetails(product?.id))
  }

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, [location]);


  return (
    <div>

      <div className=" items-center justify-between pb-12 mb-12 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Orders
        </div>
      </div>

      <div className="flex justify-between items-center mb-8">
        <OrdersFilter />

        <div className=" basis-2/5 flex items-center justify-between">
          <div className="mr-6">
            <Pagination meta={allOrdersData?.data?.meta} limit={9} />
          </div>

          <div className="lg:pb-[26px]">
            <SearchBox
              name='search'
              extraUrlParams='page=1'
              placeholder="Search orders"
            />
          </div>
        </div>
      </div>

      <div>
        <Table tableClass="pb-14">

          <Table.Head className="text-uppercase">
            <th>Product Name</th>
            <th>Price</th>
            <th>Status</th>
            <th>Date Ordered</th>
          </Table.Head>

          <Table.Body>
            <TableContent
              colspan={4}
              loading={allOrdersLoading}
              loadingText="Fetching orders"
              data={allOrdersData?.data?.data}
              total={allOrdersData?.data?.data?.length}
              emptyUI={
                <EmptyUI
                  icon={EmptyOrder}
                  header="No orders yet"
                  subheader=" You have not received any order on this account at this time"
                />
              }
            />

            {allOrdersLoading === false && allOrdersData?.data?.data && allOrdersData?.data?.data?.map((product: any, index: number) => {

              const isCancelled = product?.order?.status === 'CANCELED';
              const inTransit = product?.order?.status === 'IN_TRANSIT';
              const notShipped = product?.order?.status === 'NOT_SHIPPED';

              return (
                <tr key={index} onClick={() => handleClick(product)} className="cursor-pointer">
                  <td>
                    <div className="flex items-center">

                      <div className="mr-4 rounded-xl">
                        <TiledProductImage data={product?.product?.photos} />
                      </div>

                      <div className="font-medium">
                        {product?.product?.productName ?? "-"}
                      </div>
                    </div>
                  </td>

                  <td>{formatCurrency(product?.price)} NGN</td>

                  <td>
                    <div className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                    ${isCancelled ? 'border-shiga-red-40 text-shiga-red-100'
                        : inTransit ? 'border-shiga-orange-50 text-shiga-orange-100'
                          : notShipped ? 'border-shiga-gray-75 text-shiga-gray-200'
                            : ''
                      }`}>
                      {isCancelled ? 'Cancelled' : inTransit ? 'In transit' : notShipped ? 'Not Shipped' : product?.order?.status}
                    </div>
                  </td>

                  <td>
                    <div className="flex items-center">
                      {moment(product?.createdAt).format('MMM D, YYYY [at] h:mma')}
                    </div>
                  </td>
                </tr>
              )
            })}
          </Table.Body>
        </Table>
      </div>

      <OrderDetailModal
        order={selected}
        open={detailsShown}
        fetchOrders={fetchOrders}
        closeModal={toggleDetailsShown}
      />
    </div>
  );
};

export default OrderTable;