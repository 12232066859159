import { ChangeEvent, useEffect, useState } from "react";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as IconProfile } from "assets/images/icons/profile.svg";
import { ReactComponent as Rectangle } from "assets/images/icons/rectangle.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as FileType } from "assets/images/icons/FileType.svg";
import * as settingsActions from "store/entities/settings/action";
import { ReactComponent as FileType2 } from "assets/images/icons/FileType2.svg";
import { Input, SelectInput } from "components/common/form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalletsAndBalance } from "store/ui/dashboard/action";
import { useSharedStore } from "zustand-store";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { useAppSelector } from "store/hooks";
import { getDashboard } from "store/dashboard/reducer";
import { DashboardWallet } from "types/wallet";
import ShigaButton from "components/common/form/ShigaButton";
import CustomAmountField from "components/custom/CustomAmountField";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { getTransferFee } from "store/transfer/action";

const formSchema = z.object({
  currency: z.string(),
  amount: z.number().min(1, "Amount must be greater than 0"),
  teamMember: z.string(),
  cardLabel: z.string(),
});

type FormType = z.infer<typeof formSchema>;

export const CreateCard = () => {
  const navigate = useNavigate();
  const { currencies } = useSharedStore();
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: 0,
      currency: "USD",
    },
    context: "onChange",
    criteriaMode: "all",
    mode: "onChange",
  });
  const cardLabel = form.watch("cardLabel");

  // const amount = form.watch("amount");
  const currency = form.watch("currency");

  // eslint-disable-next-line
  const amount = form.watch("amount");
  const teamMember = form.watch("teamMember");
  // const currency = form.watch("currency")
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentCard, setCurrentCard] = useState<"dollar" | "naira">("dollar");
  const { teamMembersLoading, teamMembers: teamMembersBE } = useSelector(
    (s: any) => s.entities.settings
  );
  const { wallets } = useAppSelector(getDashboard);
  const [amt, setAmt] = useState("");
  const currentWallet = wallets?.data?.find(
    (wallet: DashboardWallet) => wallet.currency === "USD"
  );

  const amtBelowMin = amt !== "" && Number(amt) < 5;
  const amtExceedsBal =
    currentWallet?.balance !== undefined &&
    Number(amt) > currentWallet?.balance;
  const inputError = amtBelowMin || amtExceedsBal;
  const submitDisabled =
    !amt?.length || Number(amt) < 5 || !teamMember || !cardLabel || inputError;

  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    setAmt(event.target.value);
  };

  useEffect(() => {
    if (amt?.length && Number(amt) > 0) {
      const queryParams = new URLSearchParams({
        amount: amt,
        currency: "USD",
        service: "VIRTUAL_CARD_ISSUANCE",
      }).toString();

      dispatch(getTransferFee(queryParams));
    }
    // eslint-disable-next-line
  }, [amt]);

  useEffect(() => {
    dispatch(settingsActions.getTeamMembers());
    dispatch(getWalletsAndBalance());
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="space-y-8 pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
      <div className="flex items-center justify-between pb-5 border-b border-gray-100">
        <div
          onClick={() => navigate("/cards")}
          className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
        >
          <IconNavigation className="w-full h-full" />
        </div>
        <div className="flex items-center space-x-3">
          <div className="flex space-x-2 items-center cursor-pointer">
            <div
              className={`w-6 h-6 rounded-full flex items-center justify-center ${
                true ? "bg-black" : "bg-[#E7E7E7]"
              }`}
            >
              <IconProfile />
            </div>
            <div className={true ? "text-black" : "text-[#E7E7E7]"}>
              Card Details
            </div>
          </div>
          <div>
            <IconArrowRight />
          </div>
          <div className="flex space-x-2 items-center cursor-pointer">
            <div
              className={`w-6 h-6 rounded-full flex items-center justify-center ${
                false ? "bg-black" : "bg-[#E7E7E7]"
              }`}
            >
              <Rectangle />
            </div>
            <div className={false ? "text-black" : "text-[#E7E7E7]"}>
              Review & Create
            </div>
          </div>
        </div>
        <div
          onClick={() => navigate("/cards")}
          className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
        >
          <IconClose className="m-auto svg-stroke-gray" />
        </div>
      </div>
      <div className="max-w-md space-y-8 mx-auto">
        <div className="text-4xl font-ojah font-bold pb-5 border-b border-gray-100">
          Create a virtual card
        </div>
        <Form {...form}>
          <form {...form}>
            <div className="flex flex-col space-y-7">
              <div className="flex w-full items-center space-x-4">
                <div
                  // onClick={() => setCurrentCard("naira")}
                  className={`flex flex-1 items-center rounded-2xl border-2 p-4 cursor-not-allowed ${
                    currentCard === "naira"
                      ? "border-shiga-black"
                      : "border-gray-300"
                  }`}
                >
                  <FileType className="m-auto mr-2" />
                  <div className="flex items-center flex-1 justify-between">
                    <div>Naira Card</div>
                    {currentCard === "naira" && (
                      <FontAwesomeIcon
                        icon="check-circle"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
                <div
                  onClick={() => setCurrentCard("dollar")}
                  className={`flex flex-1 items-center rounded-2xl border-2 p-4 cursor-pointer ${
                    currentCard === "dollar"
                      ? "border-shiga-black"
                      : "border-gray-300"
                  }`}
                >
                  <FileType2 className="m-auto mr-2" />
                  <div className="flex items-center flex-1 justify-between">
                    <div>Dollar Card</div>
                    {currentCard === "dollar" && (
                      <FontAwesomeIcon
                        icon="check-circle"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FormField
                control={form.control}
                name="teamMember"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectInput label="Assign to" required={true} {...field}>
                        <option value="" disabled>
                          Select Team member
                        </option>
                        {!teamMembersLoading &&
                          teamMembersBE.data?.map((member: any) => (
                            <option
                              value={member.id}
                              key={member.id}
                            >{`${member.firstName} ${member.lastName}`}</option>
                          ))}
                      </SelectInput>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="cardLabel"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="text"
                        label="Card label"
                        placeholder="Dollar Card"
                        required
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <CustomAmountField
                leftLabel="Amount to Fund"
                isFieldRequired
                setInputAmount={updateAmount}
                inputError={inputError}
                errorText={
                  amtBelowMin
                    ? "Minimum Amount is $5"
                    : amtExceedsBal
                    ? "Amount cannot exceed current balance"
                    : "Minimum Amount is $5"
                }
                flagIconUrl={
                  currencies.find((curr) => curr.currency === "USD")?.icon || ""
                }
                inputAmount={amt}
                transactionCurrency={currentWallet?.currency || ""}
                rightLabel={
                  <div>
                    <p className="text-shiga-dark flex items-center font-inter">
                      <span className="mr-1">
                        <FontAwesomeIcon icon={faWallet} />
                      </span>
                      You have&nbsp;
                      <span className="text-shiga-purple font-medium">
                        ${`${Number(currentWallet?.balance).toLocaleString()}`}
                        &nbsp;
                      </span>
                      available
                    </p>
                  </div>
                }
              />
            </div>
          </form>
        </Form>

        <ShigaButton
          darkBg
          fullWidth
          type="button"
          text="Continue"
          disabled={submitDisabled}
          onClick={() =>
            navigate(
              `/cards/create-a-card/review-details-and-create?label=${cardLabel}&amount=${amt}&memberID=${teamMember}&cardType=${"Dollar"}`
            )
          }
        />
      </div>
    </div>
  );
};

export default CreateCard;
