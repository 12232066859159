
export const DELETE_BULK_TRANSFER_ITEM_START = "DELETE_BULK_TRANSFER_ITEM_START"
export const DELETE_BULK_TRANSFER_ITEM_DONE = "DELETE_BULK_TRANSFER_ITEM_DONE"
export const DELETE_BULK_TRANSFER_ITEM_FAILED = "DELETE_BULK_TRANSFER_ITEM_FAILED"

export const INITIATE_BULK_TRANSFER_START = "INITIATE_BULK_TRANSFER_START"
export const INITIATE_BULK_TRANSFER_DONE = "INITIATE_BULK_TRANSFER_DONE"
export const INITIATE_BULK_TRANSFER_FAILED = "INITIATE_BULK_TRANSFER_FAILED"

export const UPDATE_BULK_TRANSFER_AMOUNT_START = "UPDATE_BULK_TRANSFER_AMOUNT_START"
export const UPDATE_BULK_TRANSFER_AMOUNT_DONE = "UPDATE_BULK_TRANSFER_AMOUNT_DONE"
export const UPDATE_BULK_TRANSFER_AMOUNT_FAILED = "UPDATE_BULK_TRANSFER_AMOUNT_FAILED"

export const FETCH_BULK_TRANSFER_CONTENT_START = "FETCH_BULK_TRANSFER_CONTENT_START"
export const FETCH_BULK_TRANSFER_CONTENT_DONE = "FETCH_BULK_TRANSFER_CONTENT_DONE"
export const FETCH_BULK_TRANSFER_CONTENT_FAILED = "FETCH_BULK_TRANSFER_CONTENT_FAILED"

export const DELETE_BULK_TRF_FILE = "DELETE_BULK_TRF_FILE"
export const UPLOAD_BULK_TRANSFER_FILE_START = "UPLOAD_BULK_TRANSFER_FILE_START"
export const UPLOAD_BULK_TRANSFER_FILE_DONE = "UPLOAD_BULK_TRANSFER_FILE_DONE"
export const UPLOAD_BULK_TRANSFER_FILE_FAILED = "UPLOAD_BULK_TRANSFER_FILE_FAILED"

export const SET_TRANSFER_WALLET = "SET_TRANSFER_WALLET"

export const GET_NGN_VIRTUAL_ACCT_START = "GET_NGN_VIRTUAL_ACCT_START"
export const GET_NGN_VIRTUAL_ACCT_DONE = "GET_NGN_VIRTUAL_ACCT_DONE"
export const GET_NGN_VIRTUAL_ACCT_FAILED = "GET_NGN_VIRTUAL_ACCT_FAILED"

export const PROCESS_BULK_TRANSFER_START = "PROCESS_BULK_TRANSFER_START"
export const PROCESS_BULK_TRANSFER_DONE = "PROCESS_BULK_TRANSFER_DONE"
export const PROCESS_BULK_TRANSFER_FAILED = "PROCESS_BULK_TRANSFER_FAILED"

export const REQUEST_TRANSFER_OTP_START = "REQUEST_TRANSFER_OTP_START"
export const REQUEST_TRANSFER_OTP_DONE = "REQUEST_TRANSFER_OTP_DONE"
export const REQUEST_TRANSFER_OTP_FAILED = "REQUEST_TRANSFER_OTP_FAILED"

export const GET_TRANSFER_LIMIT_START = "GET_TRANSFER_LIMIT_START"
export const GET_TRANSFER_LIMIT_DONE = "GET_TRANSFER_LIMIT_DONE"
export const GET_TRANSFER_LIMIT_FAILED = "GET_TRANSFER_LIMIT_FAILED"

export const SAVE_BANK_TRANSFER_FORM = "SAVE_BANK_TRANSFER_FORM"
export const CLEAR_BANK_TRANSFER_FORM = "CLEAR_BANK_TRANSFER_FORM"

export const GET_TRANSFER_FEE_START = "GET_TRANSFER_FEE_START"
export const GET_TRANSFER_FEE_DONE = "GET_TRANSFER_FEE_DONE"
export const GET_TRANSFER_FEE_FAILED = "GET_TRANSFER_FEE_FAILED"

//GET BANK LiST
export const TRANSFER_GET_BANK = "TRANSFER_GET_BANKS"
export const TRANSFER_GET_BANK_START = "TRANSFER_GET_BANKS_START"
export const TRANSFER_GET_BANK_FAILED = "TRANSFER_GET_BANKS_FAILED"

//NAME VALIDATION
export const TRANSFER_NAME_ENQUIRY = "TRANSFER_NAME_ENQUIRY"
export const TRANSFER_NAME_ENQUIRY_START = "TRANSFER_NAME_ENQUIRY_START"
export const TRANSFER_NAME_ENQUIRY_FAILED = "TRANSFER_NAME_ENQUIRY_FAILED"

//GET BENEFICIARIES
export const TRANSFER_BENEFICIARY = "TRANSFER_BENEFICIARY"
export const TRANSFER_BENEFICIARY_START = "TRANSFER_BENEFICIARY_START"
export const TRANSFER_BENEFICIARY_FAILED = "TRANSFER_BENEFICIARY_FAILED"

//Updates Fields
export const UPDATE_TRANSFER_CURERENCY = "UPDATE_TRANSFER_CURERENCY"
export const UPDATE_SELECTED_WALLET_FOR_TRANSFER = "UPDATE_SELECTED_WALLET_FOR_TRANSFER"
export const UPDATE_TRANSFER_AMOUNT = "UPDATE_TRANSFER_AMOUNT"
export const UPDATE_TRANSFER_NOTE = "UPDATE_TRANSFER_NOTE"
export const UPDATE_TRANSFER_MFA_CODE = "UPDATE_TRANSFER_MFA_CODE"
export const RESET_TRANSFER_DATA = "RESET_TRANSFER_DATA"
export const UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY = "UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY"
export const UPDATE_TRANSFER_DYNAMIC_FIELD = "UPDATE_TRANSFER_DYNAMIC_FIELD"

//Make Transfer
export const TRANSFER_PAYMENT = "TRANSFER_PAYMENT_ENQUIRY"
export const TRANSFER_PAYMENT_START = "TRANSFER_PAYMENT_START"
export const TRANSFER_PAYMENT_FAILED = "TRANSFER_PAYMENT_FAILED"
export const RESET_TRANSFER_PARAMS = "RESET_TRANSFER_PARAMS"

export const TRANSFER_GET_FIELDS = "TRANSFER_GET_FIELDS"
export const TRANSFER_GET_FIELDS_START = "TRANSFER_GET_FIELDS_START"
export const TRANSFER_GET_FIELDS_FAILED = "TRANSFER_GET_FIELDS_FAILED"


// confirm payment
export const CONFIRM_TRANSFER_PAYMENT = "CONFIRM_TRANSFER_PAYMENT"
export const CONFIRM_TRANSFER_PAYMENT_START = "CONFIRM_TRANSFER_PAYMENT_START"
export const CONFIRM_TRANSFER_PAYMENT_FAILED = "CONFIRM_TRANSFER_PAYMENT_FAILED"