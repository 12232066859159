import EmptyDevices from "assets/images/icons/empty-terminal.svg";
import EmptyUI from "components/common/EmptyUi"
import ShigaButton from "components/common/form/ShigaButton";
import useToolkit from "hooks/useToolkit";
import { useState } from "react";
import { toast } from "react-toastify";
import NewDeviceModal from "./NewDeviceModal";

type Props = {}

const DevicesHomepage = (props: Props) => {
   const { isLocalEnv } = useToolkit();
   const [addDeviceOpen, setAddDeviceOpen] = useState(false);

   const getDevices = () => { }


   return (
      <div className='w-full py-12 flexed flex-col'>

         <EmptyUI
            icon={EmptyDevices}
            header="No Device Yet"
            subheader="All devices linked to this business account will be displayed here"
         />

         <ShigaButton
            darkBg
            text="Add a Device"
            onClick={() => isLocalEnv ? setAddDeviceOpen(true) : toast('Coming soon')}
         />

         <NewDeviceModal
            isOpen={addDeviceOpen}
            getDevices={getDevices}
            setIsOpen={setAddDeviceOpen}
         />
      </div>
   )
}

export default DevicesHomepage