import { ReactNode, SetStateAction, useState } from 'react'
import useToolkit from 'hooks/useToolkit';
import ContextProps from './ContextProps';
import { resetPaymentLinkProps } from 'store/transactions/action';

type Props = {
   children: ReactNode;
   sessionModalOpen: boolean;
   fetchPaymentLinks: () => Promise<void>;
   setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const ContextProvider = ({ sessionModalOpen, setSessionModalOpen, children, fetchPaymentLinks }: Props) => {
   const { dispatch } = useToolkit();
   const [paymentLinkType, setPaymentLinkType] = useState('');
   const [paymentLinkStep, setpaymentLinkStep] = useState(1);
   const [logoFile, setLogoFile] = useState<any>(null);

   const incrementPaymentLinkStep = () => setpaymentLinkStep((step: any) => step + 1)
   const decrementPaymentLinkStep = () => setpaymentLinkStep((step: any) => step - 1)
   const navigatePaymentLinkStep = (value: number) => setpaymentLinkStep(value)

   const isCollectionPayment = paymentLinkType === 'COLLECTION';
   const isTuitionPayment = paymentLinkType === 'TUITION';

   const endPaymentLinkSession = () => {
      setPaymentLinkType('');
      setpaymentLinkStep(1);
      setSessionModalOpen(false);
      fetchPaymentLinks();
      setLogoFile(null);

      setTimeout(() => {
         dispatch(resetPaymentLinkProps())
      }, 500);
   }

   return (
      <ContextProps.Provider
         value={{
            paymentLinkType,
            setPaymentLinkType,
            isCollectionPayment,
            isTuitionPayment,

            paymentLinkStep,
            decrementPaymentLinkStep,
            incrementPaymentLinkStep,
            navigatePaymentLinkStep,

            logoFile,
            setLogoFile,
            endPaymentLinkSession,
         }}>
         {children}
      </ContextProps.Provider>
   )
}

export default ContextProvider