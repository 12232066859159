const defaultFixedLinkForm = {
   name: '',
   slug: '',
   amount: '',
   currency: 'NGN',
   acceptOtherCurrencies: false,
}


export {
   defaultFixedLinkForm
}