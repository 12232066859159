import { SetStateAction } from "react";
import { Dialog } from '@headlessui/react';
import DynamicHeadTag from "components/common/DynamicHeadTag";
import LayoutDisplay from "./LayoutDisplay";
import ContextProvider from "../context/ContextProvider";

type Props = {
    sessionModalOpen: boolean;
    fetchPaymentLinks: () => Promise<void>;
    setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const InlinePaymentLinkCreationLayout = ({ sessionModalOpen, setSessionModalOpen, fetchPaymentLinks }: Props) => {

    return (
        <ContextProvider
            sessionModalOpen={sessionModalOpen}
            fetchPaymentLinks={fetchPaymentLinks}
            setSessionModalOpen={setSessionModalOpen}>

            <DynamicHeadTag
                headerText="Create Payment Link"
            />

            {(sessionModalOpen === true) &&
                <Dialog
                    open={sessionModalOpen}
                    className="fixed inset-0 z-50"
                    onClose={() => setSessionModalOpen(false)}>

                    <div className="fixed inset-0 bg-black/40" aria-hidden="true" />

                    <div
                        className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl 
                        rounded-tl-3xl overflow-hidden shadow-xl animate__animated animate__faster          
                        ${sessionModalOpen ? "animate__slideInUp" : "animate__slideOutDown"}`}>

                        <LayoutDisplay setSessionModalOpen={setSessionModalOpen} />
                    </div>

                </Dialog>
            }
        </ContextProvider>
    );
}


export default InlinePaymentLinkCreationLayout;
