import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { ButtonLoader, InputAmount } from "components/common/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSharedStore } from "zustand-store";
import { currencyFormat } from "lib/format.amount";
import { Form } from "components/ui/form";
import useToolkit from "hooks/useToolkit";
import { fundCard } from "store/card/action";
import { useEffect } from "react";

const formSchema = z.object({
    currency: z.string(),
    amount: z.number(),
});

type FormType = z.infer<typeof formSchema>;

export const FundCardModal: React.FC<{
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
    const { currencies } = useSharedStore();
    const form = useForm<FormType>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            amount: 0,
            currency: "USD"
        },
    });

    const { toastError, dispatch, useSelector } = useToolkit();
    const { fundCardLoading, fundCardData, selectedCard: activeCard } = useSelector((state: any) => state.cards)

    const onSubmit = (data: FormType) => {
        if (data?.amount < 5) {
            toastError('Minimum funding amount is $5')
        } else {
            const params = {
                id: activeCard.id,
                payload: { amount: data.amount }
            }
            dispatch(fundCard(params))
        }
    }

    useEffect(() => {
        if (fundCardData?.success === true) {
            setIsOpen(false)
        }
        // eslint-disable-next-line
    }, [fundCardData])

    return (
        <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="p-8 pb-6">
                        <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                            <div className="text-2xl font-ojah font-medium">
                                Fund this Card
                            </div>
                            <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                <IconClose className="m-auto svg-stroke-gray" />
                            </div>
                        </div>
                        <div className="pt-6 space-y-4">
                            <InputAmount currencies={currencies} disableCurrency
                                form={form as any} minAmount={1000}
                                header={({ currentWallet: { currency, balance, } }) => (
                                    currency.length ?
                                        <div className="flex mb-4 justify-between">
                                            <p className="">You send
                                                <span className="form-input-required">*</span>
                                            </p>
                                            <p className="text-shiga-dark">
                                                <span><FontAwesomeIcon icon={faWallet} /></span>
                                                You have <span className="text-shiga-purple">{currencyFormat(balance, currency)}</span> available
                                            </p>
                                        </div> : <></>
                                )}
                            />
                        </div>
                    </div>
                    <div className="border-t border-gray-100 p-6 pt-3">
                        <ButtonLoader type="submit" loading={fundCardLoading} className="btn btn-block btn-lg btn-shiga-black">
                            Fund Card
                        </ButtonLoader>
                    </div>
                </form>
            </Form>
        </FormDrawer>
    )
}

export default FundCardModal;