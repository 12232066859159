import React, { useEffect, useState } from "react";
import CustomNotePad from "components/custom/CustomNotePad";
import OrderDetail from "./OrderDetail";
import Maybe from "components/common/Maybe";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import ojahIcon from "assets/images/icons/ojah-xs.svg";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import { Loader } from "lucide-react";
import moment from "moment";
import TiledProductImage from "./TiledProductImage";
import ShigaButton from "components/common/form/ShigaButton";
import { cancelProductOrder, resetVendorParams } from "store/ecom/action";

type Props = {
  order: any;
  open: boolean;
  closeModal: () => void;
  fetchOrders: () => void
};

const apiTrxNavItems: InlineRouterNavItemProps[] = [
  {
    title: "More Details",
    navValue: "details",
  },
];

const OrderDetailModal = (props: Props) => {
  const { order, open, closeModal, fetchOrders } = props;
  const { useSelector, copyItem, dispatch, businessProfile } = useToolkit();
  const [noteValue, setNoteValue] = useState('');

  const [reason, setReason] = useState('');
  const [isCancelling, setIsCancelling] = useState(false);

  const { OrderDetailRouter, activeTab } = useOrderDetailRouter(apiTrxNavItems[0].navValue);
  const {
    orderDetailsData:
    details,
    orderDetailsLoading:
    isLoading,
    cancelOrderLoading,
    cancelOrderData
  } = useSelector((state: RootState) => state.ecom)

  const notShipped = details?.data?.order?.status === 'NOT_SHIPPED';
  const isCancelled = order?.order?.status === 'CANCELED' || details?.data?.status === 'CANCELED';
  const isVendorCancelled = details?.data?.canceledBy?.who === 'VENDOR';

  const handleCancel = () => {
    if (isCancelling) {
      dispatch(cancelProductOrder({ id: details?.data?.id, reason }))
    } else {
      setIsCancelling(true)
    }
  }

  useEffect(() => {
    if (cancelOrderData?.success === true) {
      closeModal();
      fetchOrders();

      setTimeout(() => {
        dispatch(resetVendorParams());
      }, 1500);
    }
    // eslint-disable-next-line
  }, [cancelOrderData])

  useEffect(() => {
    if (details?.data?.order?.note?.length > 0) {
      setNoteValue(details?.data?.order?.note)
    }
  }, [details?.data?.order?.note])


  return (
    <Dialog open={open} onOpenChange={() => closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {isCancelled ? 'Cancelled Order' : isCancelling ? 'Cancel this Order' : 'Order Details'}
          </DialogTitle>
        </DialogHeader>

        <Maybe condition={isLoading}>
          <div className="py-20 w-full flexed flex-col">
            <Loader className="animate-spin" size='3rem' />
            <p className="mt-3 font-medium">
              Fetching details
            </p>
          </div>
        </Maybe>

        <Maybe condition={!isLoading}>
          <div>

            <div className="mt-8 mb-5">
              <div className="flex items-center">

                <div className="mr-4 rounded-xl">
                  <TiledProductImage data={order?.product?.photos} />
                </div>

                <div className="w-full flex justify-between pt-2">

                  <div>

                    <div className="font-medium ">
                      {order?.product?.productName ?? "-"}
                    </div>

                    <p className="text-shiga-gray-300 flex items-center">
                      Order ID: {order?.order?.orderNumber?.substring(0, 7)}...
                      <span
                        className="ml-px"
                        onClick={() => copyItem(order?.order?.orderNumber, "Order ID")}>
                        <CopyIcon width={20} />
                      </span>
                    </p>

                  </div>

                  <div className=" flex flex-col justify-end">
                    <div className="font-medium text-right ">
                      {Number(order?.price)?.toLocaleString()} NGN
                    </div>

                    <p className="text-shiga-gray-300 whitespace-nowrap">
                      {moment(order?.createdAt).format('MMM D, YYYY [at] h:mma')}
                    </p>
                  </div>

                </div>
              </div>
            </div>

            <div className="w-full h-px  border-b border-shiga-gray-50" />

            <Maybe condition={!isCancelling && !isCancelled}>
              <div className="mt-3 flex-btw pb-3 pt-2 relative">
                <div className="pb-3">
                  <OrderDetailRouter navItems={apiTrxNavItems} />
                </div>
              </div>

              <div className="p-3.5 border border-shiga-gray-50 rounded-xl">
                <OrderDetail
                  order={order}
                  activeTab={activeTab}
                  details={details?.data}
                />
              </div>

              <CustomNotePad
                noteValue={noteValue}
                noteIsRequired={false}
                setNoteValue={setNoteValue}
                notePadLeftLabel="Delivery Instruction"
                notePadPlaceholder="No additional delivery instruction was added"
              />
            </Maybe>

            <Maybe condition={isCancelling}>
              <CustomNotePad
                noteValue={reason}
                noteIsRequired={true}
                setNoteValue={setReason}
                notePadLeftLabel="Reason for Cancellation"
                notePadPlaceholder="Add a reason for Cancellation"
              />
            </Maybe>

            <Maybe condition={isCancelled}>
              <CustomNotePad
                disableEdit
                customBg="bg-shiga-red-60"
                custommTextColor="text-shiga-red-100"
                noteValue={details?.data?.cancelReason}
                noteIsRequired={false}
                setNoteValue={setReason}
                LabelIcon={
                  <div className="flex items-center pl-2 pb-2">

                    <img
                      alt='logo'
                      width={14}
                      src={isVendorCancelled ? businessProfile?.logo : ojahIcon}
                    />

                    <span className="ml-1 font-medium text-shiga-dark-100 ">
                      {isVendorCancelled ? 'You' : 'Ojah'}
                    </span>
                  </div>
                }
                notePadLeftLabel="Reason for Cancellation by"
                notePadPlaceholder="Add a reason for Cancellation"
              />
            </Maybe>
          </div>

          <Maybe condition={notShipped}>
            <ShigaButton
              danger={isCancelling}
              onClick={handleCancel}
              lightRedBg={!isCancelling}
              loading={cancelOrderLoading}
              disabled={isCancelling && reason?.length < 5}
              text={isCancelling ? 'Cancel Order & Refund' : 'Cancel Order'}
            />
          </Maybe>


        </Maybe>

      </DialogContent>
    </Dialog>
  );
};

type NavItem = {
  title: string;
  navValue: string;
};

type OrderDetailRouterProps = {
  navItems: NavItem[];
};
export const useOrderDetailRouter = (defaultTabValue: string) => {
  const [activeTab, setActiveTab] = useState(defaultTabValue);

  const OrderDetailRouter: React.FC<OrderDetailRouterProps> = ({
    navItems,
  }) => (
    <div className="flex items-center space-x-4">
      {navItems.map(({ title, navValue }, index) => {
        const isActive = activeTab === navValue;

        return (
          <div key={index} className="relative">
            <button
              onClick={() => setActiveTab(navValue)}
              className={`${isActive ? "text-black font-[500]" : "text-gray-500"} font-ojah`}>
              {title}
            </button>
          </div>
        );
      })}
    </div>
  );

  return { OrderDetailRouter, activeTab };
};

export default OrderDetailModal;
