import { SetStateAction, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { ReactComponent as IconNote } from "assets/images/icons/ojah-note.svg";
import { registerOjahVendor, resetVendorParams } from 'store/ecom/action';

type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   setCreateProductOpen: React.Dispatch<SetStateAction<boolean>>;
   setLogoModalShown: React.Dispatch<SetStateAction<boolean>>;
}

const TermsAgreementModal = ({
   isOpen,
   setIsOpen,
   setCreateProductOpen,
   setLogoModalShown
}: Props) => {
   const { dispatch, useSelector, businessProfile, refetchProfiles } = useToolkit();
   const { registerVendorData, registerVendorLoading } = useSelector((state: RootState) => state.ecom)

   const isValidBusinessLogo = businessProfile?.logo && businessProfile?.logo?.length > 0 && businessProfile?.logo?.includes('https://');
   const privacyPolicyRoute = "https://payshiga.notion.site/PAYSHIGA-PRIVACY-POLICY-6165bd6474ad4297a2227a0f7c061a7b?pvs=4"
   const termsOfUseRoute = "https://payshiga.notion.site/PAYSHIGA-TERMS-OF-USE-c14e881663ec40c7875e61ff0eebc40b?pvs=4"


   const handleClick = () => {
      dispatch(registerOjahVendor())
   }

   useEffect(() => {
      dispatch(resetVendorParams())
      //   eslint-disable-next-line
   }, [])

   useEffect(() => {
      if (registerVendorData?.success === true) {
         refetchProfiles();
         setIsOpen(false);

         if (isValidBusinessLogo === true) {
            setCreateProductOpen(true);
         } else {
            setLogoModalShown(true);
         }
      }
      // eslint-disable-next-line
   }, [registerVendorData, isValidBusinessLogo])

   return (
      <CenterModal
         noHeader
         hasButton
         headerTitle=''
         isOpen={isOpen}
         buttonText='I Agree'
         setIsOpen={setIsOpen}
         onButtonClick={handleClick}
         isButtonLoading={registerVendorLoading}>

         <div className='w-full'>

            <div className="w-full flex-between items-start">
               <p className="mb-0 font-ojah text-lg font-bold lg:text-4xl w-3/5">
                  Read & agree to Ojah's Terms of Use and Policies
               </p>
               <IconNote />
            </div>

            <div className="py-10">


               By tapping the "I agree" button, you confirm that you have thoroughly reviewed and understood
               <span className="font-semiboold">
                  &nbsp;Ojah's&nbsp;
               </span>

               <Link target="_blank" to={termsOfUseRoute}
                  className="border-b border-shiga-green-400 text-shiga-green-400 font-medium">
                  Terms of Use
               </Link>

               &nbsp;and&nbsp;

               <Link target="_blank" to={privacyPolicyRoute}
                  className="border-b border-shiga-green-400 text-shiga-green-400 font-medium">
                  Privacy Policy.
               </Link>

               <br />
               <br />

               By doing so , you acknowledge your acceptance of these terms and agree to comply with them
               throughout your selling experience on Ojah by Payshiga. This agreement also signifies your
               consent to the practices outlined in our Privacy Policy, ensuring transparency in how we
               manage and protect your data.
            </div>

         </div>

      </CenterModal>
   )
}

export default TermsAgreementModal