import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/file-upload.svg";
import { useEffect, useRef, useState } from "react";
import Maybe from "components/common/Maybe";
import useToolkit from "hooks/useToolkit";
import { FileUploader } from "react-drag-drop-files";

type Props = {
   onFileChange: (file: any) => void;
   onDelete: () => void;
   disabled: boolean;
   initialSrc: any;
}

const ProductImageHandler = ({ onFileChange, onDelete, disabled, initialSrc }: Props) => {

   const { toastError } = useToolkit();
   const [logo, setLogo] = useState<any>(null);
   const [img, setImg] = useState<any>(null);
   const imgRef = useRef<any>(null)

   const logoRef = useRef<HTMLInputElement>(null);
   const fileTypes = ['jpeg', 'png', 'jpg'];

   const handleChange = (file: any) => {
      if (!disabled) {
         setLogo(file);
         onFileChange(file);

         imgRef.current = URL.createObjectURL(file);
         setImg(URL.createObjectURL(file))
      }
   }

   useEffect(() => {
      if (initialSrc !== null) {

         if (typeof initialSrc === 'string') {
            setImg(initialSrc);
            setLogo(initialSrc)
         } else {
            setImg(URL.createObjectURL(initialSrc))
            setLogo(initialSrc)
         }

      } else {
         setImg(null)
      }
   }, [initialSrc])

   return (
      <div className={`w-full h-[140px] border-2 border-shiga-gray-50 rounded-2xl ${logo === null ? 'px-5 py-10' : ''}`}>

         <Maybe condition={logo !== null}>
            <div className="relative w-full h-full">
               <img
                  alt='logo'
                  src={img}
                  className="rounded-2xl object-cover max-h-full"
               />

               <input
                  type="file"
                  ref={logoRef}
                  accept=".png, .jpg, .jpeg"
                  style={{ display: 'none' }}
                  onChange={(e: any) => handleChange(e.target.files[0])}
               />

               <Maybe condition={!disabled}>
                  <IconRemove
                     data-tooltip-id="dp-remove"
                     data-tooltip-content="Remove Image"
                     onClick={() => { setImg(null); setLogo(null); onDelete(); }}
                     className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 
               left-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white"
                  />

                  <IconEdit
                     onClick={() => logoRef?.current?.click()}
                     data-tooltip-content={logo ? "" : "Change Image"}
                     className="w-8 h-8 p-1.5 bg-black bg-opacity-50 
                     absolute z-[1] bottom-2 right-2 rounded-full hover:bg-opacity-100 
                     cursor-pointer outline-none"
                  />

               </Maybe>

            </div>
         </Maybe>

         <Maybe condition={logo === null}>
            <div className="w-full h-full relative rounded-3xl flexed flex-col">

               <FileUploader
                  ref={logoRef}
                  multiple={false}
                  classes="file_upload_zone"
                  handleChange={handleChange}
                  types={fileTypes}
                  onTypeError={(err: any) => toastError(err)}
                  onSizeError={(err: any) => toastError(err)}>

                  <div className="w-16 h-16 flexed">
                     <IconFileUpload className="m-auto w-full h-full" />
                  </div>
               </FileUploader>

            </div>
         </Maybe>

      </div>
   )
}

export default ProductImageHandler