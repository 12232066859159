import { ReactNode } from 'react'

type Props = {
   children: ReactNode;
   title: string;
   subtitle: string;
}

const LayoutFlowWrapper = ({ children, title, subtitle }: Props) => {

   return (
      <div className="max-w-[550px] relative">

         <div className="w-full lg:w-5/6 sticky top-32 left-0 bg-white z-20">

            <div className="text-lg lg:text-4xl text-shiga-dark-100 font-ojah font-bold">
               {title}
            </div>

            <div className="text-shiga-gray-300 mt-2 pb-3 text-sm">
               {subtitle}
               <hr className='w-full h-[1px] border-b border-shiga-gray-50 mt-4' />
            </div>
         </div>

         <hr className='h-12 sticky top-20 z-[15] bg-white border-none' />

         <div className={`w-full lg:w-[83%] pt-24`}>
            {children}
         </div>
      </div>
   )
}

export default LayoutFlowWrapper