import { RootState } from "store/store";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { toast } from "react-toastify";

interface InitialState {
    registerVendorLoading: boolean;
    registerVendorData: any;

    productCategoriesLoading: boolean;
    productCategoriesData: any;

    createProductLoading: boolean;
    createProductData: any;

    productDetailsLoading: boolean;
    productDetailsData: any;

    getProductsLoading: boolean;
    getProductsData: any;

    updateProductLoading: boolean;
    updateProductData: any;

    deleteProductLoading: boolean;
    deleteProductData: any;

    couriersLoading: boolean;
    couriersData: any;

    toggleCourierLoading: boolean;
    toggleCourierData: any;

    activeProduct: any;
    isEditingProduct: boolean;

    allOrdersLoading: boolean;
    allOrdersData: any;

    productOrdersLoading: boolean;
    productOrdersData: any;

    orderDetailsLoading: boolean;
    orderDetailsData: any;

    cancelOrderLoading: boolean;
    cancelOrderData: any;
}

const initialState: InitialState = {
    registerVendorLoading: false,
    registerVendorData: null,

    productCategoriesLoading: false,
    productCategoriesData: null,

    productDetailsLoading: false,
    productDetailsData: null,

    createProductLoading: false,
    createProductData: null,

    updateProductLoading: false,
    updateProductData: null,

    deleteProductLoading: false,
    deleteProductData: null,

    toggleCourierLoading: false,
    toggleCourierData: null,

    couriersLoading: false,
    couriersData: null,

    getProductsLoading: false,
    getProductsData: null,

    activeProduct: null,
    isEditingProduct: false,

    allOrdersLoading: false,
    allOrdersData: null,

    productOrdersLoading: false,
    productOrdersData: null,

    orderDetailsLoading: false,
    orderDetailsData: null,

    cancelOrderLoading: false,
    cancelOrderData: null,
}


export default function reducer(state = initialState, { type, payload }: any): InitialState {
    switch (type) {

        case actions.CANCEL_PRODUCT_ORDER_START:
            return {
                ...state,
                cancelOrderLoading: true,
                cancelOrderData: null,
            }

        case actions.CANCEL_PRODUCT_ORDER_DONE:
            toast(payload.message)
            return {
                ...state,
                cancelOrderLoading: false,
                cancelOrderData: payload,
            }

        case actions.CANCEL_PRODUCT_ORDER_FAILED:
            errorReducer(payload)
            return {
                ...state,
                cancelOrderLoading: false,
                cancelOrderData: payload,
            }

        case actions.GET_ORDER_DETAILS_START:
            return {
                ...state,
                orderDetailsLoading: true,
                orderDetailsData: null,
            }

        case actions.GET_ORDER_DETAILS_DONE:
            return {
                ...state,
                orderDetailsLoading: false,
                orderDetailsData: payload,
            }

        case actions.GET_ORDER_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                orderDetailsLoading: false,
                orderDetailsData: payload,
            }

        case actions.GET_ALL_ORDERS_START:
            return {
                ...state,
                allOrdersLoading: true,
                allOrdersData: null,
            }

        case actions.GET_ALL_ORDERS_DONE:
            return {
                ...state,
                allOrdersLoading: false,
                allOrdersData: payload,
            }

        case actions.GET_ALL_ORDERS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                allOrdersLoading: false,
                allOrdersData: payload,
            }

        case actions.GET_PRODUCT_ORDERS_START:
            return {
                ...state,
                productOrdersLoading: true,
                productOrdersData: null,
            }

        case actions.GET_PRODUCT_ORDERS_DONE:
            return {
                ...state,
                productOrdersLoading: false,
                productOrdersData: { success: true },
            }

        case actions.GET_PRODUCT_ORDERS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                productOrdersLoading: false,
                productOrdersData: payload,
            }

        case actions.DELETE_PRODUCT_START:
            return {
                ...state,
                deleteProductLoading: true,
                deleteProductData: null,
            }

        case actions.DELETE_PRODUCT_DONE:
            toast('Product deleted successfully')
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductData: { success: true },
            }

        case actions.DELETE_PRODUCT_FAILED:
            errorReducer(payload)
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductData: payload,
            }

        case actions.CLOSE_PRODUCT_EDIT_MODAL:
            return {
                ...state,
                isEditingProduct: false,
            }

        case actions.OPEN_PRODUCT_EDIT_MODAL:
            return {
                ...state,
                isEditingProduct: true,
            }

        case actions.TOGGLE_COURIERS_START:
            return {
                ...state,
                toggleCourierLoading: true,
                toggleCourierData: null,
            }

        case actions.TOGGLE_COURIERS_DONE:
            toast(payload.message)
            return {
                ...state,
                toggleCourierLoading: false,
                toggleCourierData: payload,
                couriersData: payload,
            }

        case actions.TOGGLE_COURIERS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                toggleCourierLoading: false,
                toggleCourierData: payload,
            }

        case actions.FETCH_COURIERS_START:
            return {
                ...state,
                couriersLoading: true,
                couriersData: null,
            }

        case actions.FETCH_COURIERS_DONE:
            return {
                ...state,
                couriersLoading: false,
                couriersData: payload,
            }

        case actions.FETCH_COURIERS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                couriersLoading: false,
                couriersData: payload,
            }

        case actions.EDIT_PRODUCT_DETAILS_START:
            return {
                ...state,
                updateProductLoading: true,
                updateProductData: null,
            }

        case actions.EDIT_PRODUCT_DETAILS_DONE:
            toast('Product updated successfully');
            const isAvailable = state.activeProduct?.status === 'AVAILABLE';
            return {
                ...state,
                updateProductLoading: false,
                updateProductData: payload,
                activeProduct: {
                    ...state.activeProduct,
                    status: isAvailable ? 'UNAVAILABLE' : 'AVAILABLE'
                },
            }

        case actions.EDIT_PRODUCT_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                updateProductLoading: false,
                updateProductData: payload,
            }

        case actions.GET_PRODUCT_DETAILS_START:
            return {
                ...state,
                productDetailsLoading: true,
                productDetailsData: null,
                activeProduct: null,
            }

        case actions.GET_PRODUCT_DETAILS_DONE:
            return {
                ...state,
                productDetailsLoading: false,
                productDetailsData: payload?.data,
                activeProduct: payload?.data,
            }

        case actions.GET_PRODUCT_DETAILS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                productDetailsLoading: false,
                productDetailsData: payload,
                activeProduct: null,
            }

        case actions.GET_PRODUCTS_START:
            return {
                ...state,
                getProductsLoading: true,
                getProductsData: null
            }

        case actions.GET_PRODUCTS_DONE:
            return {
                ...state,
                getProductsLoading: false,
                getProductsData: payload,
            }

        case actions.GET_PRODUCTS_FAILED:
            errorReducer(payload)
            return {
                ...state,
                getProductsLoading: false,
                getProductsData: payload,
            }

        case actions.CREATE_PRODUCT_START:
            return {
                ...state,
                createProductLoading: true,
                createProductData: null
            }

        case actions.CREATE_PRODUCT_DONE:
            toast(payload.message)
            return {
                ...state,
                createProductLoading: false,
                createProductData: payload,
            }

        case actions.CREATE_PRODUCT_FAILED:
            errorReducer(payload)
            return {
                ...state,
                createProductLoading: false,
                createProductData: payload,
            }

        case actions.GET_PRODUCT_CATEGORIES_START:
            return {
                ...state,
                productCategoriesLoading: true,
                productCategoriesData: null
            }

        case actions.GET_PRODUCT_CATEGORIES_DONE:
            return {
                ...state,
                productCategoriesLoading: false,
                productCategoriesData: payload,
            }

        case actions.GET_PRODUCT_CATEGORIES_FAILED:
            errorReducer(payload)
            return {
                ...state,
                productCategoriesLoading: false,
                productCategoriesData: payload,
            }

        case actions.RESET_VENDOR_PARAMS:
            return {
                ...state,
                registerVendorLoading: false,
                registerVendorData: null,
                createProductData: null,
                productCategoriesData: null,
                productDetailsData: null,
                deleteProductData: null,
                cancelOrderData: null,
            }

        case actions.REGISTER_VENDOR_START:
            return {
                ...state,
                registerVendorLoading: true,
                registerVendorData: null
            }

        case actions.REGISTER_VENDOR_DONE:
            return {
                ...state,
                registerVendorLoading: false,
                registerVendorData: payload,
            }

        case actions.REGISTER_VENDOR_FAILED:
            errorReducer(payload)
            return {
                ...state,
                registerVendorLoading: false,
                registerVendorData: payload,
            }

        default:
            return state;
    }
}


export const getRefundsStore = (state: RootState) => state.refunds;