import axios from "axios";
import { apiBaseUrl, apiCreateCard, apiGetDashboardCards, getCardDetails } from "data/config";
import { getToken } from "lib";
import { Card } from "types/card";
import { ApiResponseMeta, PaginatedApiResponseNoMeta } from "types/data-interface";

export const getCards = async () => axios.get<PaginatedApiResponseNoMeta<{
  cards: Card[];
  meta: ApiResponseMeta
}>>(
  // Uncomment and delete current api when get card is working
  // apiBaseUrl + apiGetCards,
  apiBaseUrl + apiGetDashboardCards,
  {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }
).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const createCard = async (body: {
  amount: number;
  label: string;
}) => axios.post(apiBaseUrl + apiCreateCard, body, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const getCard = async (cardID: string) => axios.get(apiBaseUrl + getCardDetails(cardID), {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const renameCard = async (cardID: string, body: {
  label: string
}) => axios.patch(apiBaseUrl + `/cards/${cardID}/name`, body, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const topupCard = async (cardID: string, body: {
  amount: number;
  narration: string;
  walletId: string
}) => axios.post(apiBaseUrl + `/cards/${cardID}/topup`, body, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const lockCard = async (cardID: string) => axios.patch(apiBaseUrl + `/cards/${cardID}/lock`, {}, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const unLockCard = async (cardID: string) => axios.patch(apiBaseUrl + `/cards/${cardID}/unlock`, {}, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})

export const deleteCard = async (cardID: string) => axios.delete(apiBaseUrl + `/cards/${cardID}/delete`, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(data => data.data).catch(error => {
  throw new Error(error.response?.data?.message || error.message);
})