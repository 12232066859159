import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function retryWebhookLog(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRetryWebhookLog + payload,
            method: "patch",
            data: {},
            onStart: actions.RETRY_WEBHOOK_LOG_START,
            onSuccess: actions.RETRY_WEBHOOK_LOG_DONE,
            onError: actions.RETRY_WEBHOOK_LOG_FAILED
        }
    }
}

export function getWebhookDetails(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWebhookDetails + payload,
            method: "get",
            data: {},
            onStart: actions.GET_WEBHOOK_DETAILS_START,
            onSuccess: actions.GET_WEBHOOK_DETAILS_DONE,
            onError: actions.GET_WEBHOOK_DETAILS_FAILED
        }
    }
}

export function getWebhookLogs(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWebhookLogs + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_WEBHOOK_LOGS_START,
            onSuccess: actions.GET_WEBHOOK_LOGS_DONE,
            onError: actions.GET_WEBHOOK_LOGS_FAILED
        }
    }
}

export function generateSecretKey(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGenerateSecretKey,
            method: "patch",
            data: payload,
            onStart: actions.GENERATE_API_KEY_START,
            onSuccess: actions.GENERATE_API_KEY_DONE,
            onError: actions.GENERATE_API_KEY_FAILED
        }
    }
}

export function resetWebhookParams() {
    return {
        type: actions.RESET_WEBHOOK_PARAMS,
    }
}

// export function openProductEditModal(payload: any) {
//     return {
//         type: actions.OPEN_PRODUCT_EDIT_MODAL,
//         payload,
//     }
// }
