import { useEffect, useState } from "react";
import { Input, SelectInput } from "components/common/form";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import CustomNotePad from "components/custom/CustomNotePad";
import ShigaButton from "components/common/form/ShigaButton";
import usePaymentLinksProvider from "../context/usePaymentLinksProvider";
import { createPaymentLink } from "store/transactions/action";
import { defaultFixedLinkForm } from "../context/exports";
import useMediaService from "hooks/useMediaService";


type Props = {
   wallets: any;
}

const FlexibleAmountLink = ({ wallets }: Props) => {
   const { endPaymentLinkSession, logoFile } = usePaymentLinksProvider();
   const [form, setForm] = useState(defaultFixedLinkForm);

   const [note, setNote] = useState('');
   const { dispatch, useSelector } = useToolkit();
   const {
      allowedCurrenciesLoading,
      allowedCurrenciesData,
      createPaymentLinkLoading,
      createPaymentLinkData
   } = useSelector((state: RootState) => state.transactions);
   const { uploadFile, uploadedFileLoading } = useMediaService();

   const isLoading = uploadedFileLoading || allowedCurrenciesLoading || createPaymentLinkLoading;
   const formIsInvalid = isLoading || form?.name?.length < 3

   const currenciesList = wallets?.map((item: any) => {
      const match = allowedCurrenciesData?.data.find((secondItem: any) => secondItem.currency === item.currency);

      return match ? { ...item, ...match } : item;
   })?.filter((item: any) => item?.canCollect === true)?.map((item: any) => ({
      label: item.currency,
      value: item.currency,
      flagSrc: `/flags/${item.currency.toLowerCase()}.svg`
   }))

   const onSubmit = async (e: any) => {
      e.preventDefault();

      const { name, currency, slug } = form;

      let payload = {
         name,
         currency: { value: currency, type: "FIXED" },
         description: note,
         slug,
         acceptOtherCurrencies: false,
         amountType: "FLEXIBLE",
         logoUrl: ""
      }

      if (logoFile && typeof logoFile !== "string") {
         const response: any = await uploadFile(logoFile);

         if (response?.success === true) {
            const logoUrl = response?.data?.file?.public;
            payload = { ...payload, logoUrl }
         }
      }

      dispatch(createPaymentLink(payload))
   }

   useEffect(() => {
      if (createPaymentLinkData !== null) {
         setForm(defaultFixedLinkForm);
         endPaymentLinkSession();
      }
      // eslint-disable-next-line
   }, [createPaymentLinkData])


   return (
      <div className="w-full">

         <form onSubmit={onSubmit} className="space-y-10">

            <Input
               required
               type="text"
               value={form.name}
               label="Name of Payment Link"
               onChange={(e: any) => setForm({ ...form, name: e.target.value })}
            />

            <SelectInput
               required={true}
               value={form.currency}
               label="Accept payments in"
               onChange={(e: any) => setForm({ ...form, currency: e.target.value })}>
               <option value="">Select a currency</option>
               {currenciesList.map((item: any) => (
                  <option value={item.value} key={item.value}>
                     {item.label}
                  </option>
               ))}
            </SelectInput>

            <div>
               <CustomNotePad
                  noteValue={note}
                  setNoteValue={setNote}
                  noteIsRequired={false}
                  notePadLeftLabel='Add a Description (optional)'
                  notePadPlaceholder='Say a bit about this link'
               />
            </div>

            <div className="w-full relative">
               <div className="pb-2 border-b border-shiga-gray-50">
                  <p className="mb-0 text-shiga-gray-200 font-medium text-xs">
                     Advanced Customer Options
                  </p>
               </div>

               <Input
                  type="text"
                  value={form.slug}
                  label="Custom URL"
                  placeholder="custom slug name"
                  onChange={(e: any) => setForm({ ...form, slug: e.target.value })}
               />

               <div className="absolute -bottom-6 text-shiga-gray-100">
                  https://checkout.payshiga.com/
                  <span className="text-shiga-dark-100">
                     {form.slug}
                  </span>
               </div>
            </div>

            <div className="pt-5">
               <ShigaButton
                  darkBg
                  fullWidth
                  type='submit'
                  text='Create Link'
                  disabled={formIsInvalid}
                  loading={createPaymentLinkLoading}
               />
            </div>

         </form>

      </div>
   )
}

export default FlexibleAmountLink