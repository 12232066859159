import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import Maybe from 'components/common/Maybe';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import EmptyLogs from "assets/images/icons/lined-globe.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import { getWebhookLogs, resetWebhookParams, retryWebhookLog } from 'store/webhooks/action';
import EmptyUI from 'components/common/EmptyUi';
import LogsFilterPagination from './LogsFilterPagination';
import LogDetailsModal from './log-details/LogDetailsModal';
import { Loader } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';


const LogsTab = () => {
   const { useState, copyItem } = useToolkit();
   const dispatch = useAppDispatch();
   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });
   const location = useLocation();
   const navigate = useNavigate();
   const [selected, setSelected] = useState<any>(null);
   const [detailsShown, setDetailsShown] = useState(false);

   const { webhookLogsLoading: logsLoading, webhookLogsData: logs, retryLoading, retryWebhookData } = useSelector((state: RootState) => state.webhook);
   const logsAreValid = logsLoading === false && logs?.data && logs?.data?.length > 0;

   const fetchLogs = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "messageStatus", "string", "", "messageStatus");
      params = processParams(urlQueryParams, params, "environment", "string", "", "environment");

      setListParams(params);
      dispatch(getWebhookLogs(params));
   }

   const handleAction = (log: any) => {
      dispatch(retryWebhookLog(log?.id))
   }

   const handleClick = (log: any) => {
      setSelected(log);
      setDetailsShown(true);
   }

   useEffect(() => {
      navigate(location.pathname);
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      fetchLogs();
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (retryWebhookData?.success === true) {
         setDetailsShown(false);
         fetchLogs();

         setTimeout(() => {
            dispatch(resetWebhookParams())
         }, 1500);
      }
      // eslint-disable-next-line
   }, [retryWebhookData]);

   return (

      <>
         <div className="pb-24">

            <LogsFilterPagination logs={logs} />

            <Table>

               <Maybe condition={(logsLoading === false && !logs?.data) || (logsAreValid)}>
                  <Table.Head>
                     <th>Status</th>
                     <th>Event type</th>
                     <th>Endpoint</th>
                     <th>Attempts</th>
                     <th>Last Attempted</th>
                     <th>Type</th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     total={logs?.data?.length || 0}
                     loading={logsLoading}
                     loadingText="Fetching Logs"
                     data={logs?.data}
                     colspan={6}
                     emptyUI={
                        <EmptyUI
                           icon={EmptyLogs}
                           header="No Logs Yet"
                           subheader="Logged webhook activities and status updates will appear here"
                        />
                     }
                  />

                  {logsLoading === false && logs?.data?.map((log: any) => {
                     const isFailed = log?.status === 'failed';
                     const isSuccessful = log?.status === 'success'

                     return (
                        <tr
                           className='cursor-pointer font-inter'
                           key={log.id}
                           onClick={() => handleClick(log)}>
                           <td>

                              <div
                                 className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] font-inter font-medium 
                              ${isSuccessful ? 'border-shiga-green-50 text-shiga-green-100' : 'border-shiga-red-40 text-shiga-red-100'}`}
                              >
                                 {isSuccessful ? 'Successful' : 'Failed'}
                              </div>
                           </td>

                           <td>
                              <div className='rounded-full py-1.5 px-3 text-sm flexed bg-shiga-gray-20 text-shiga-brown font-medium w-max capitalize'>
                                 {log?.event}
                              </div>
                           </td>

                           <td>
                              <div className="flex items-center">

                                 {log?.url ? `${log?.url?.substring(0, 25)}...` : 'N/A'}

                                 <Maybe condition={log?.url?.length > 0}>

                                    <button className='ml-2' onClick={(e: any) => {
                                       e.stopPropagation();
                                       copyItem(log?.url, 'Endpoint');
                                    }}>
                                       <CopyIcon />
                                    </button>

                                 </Maybe>

                              </div>
                           </td>

                           <td>
                              {log?.retryAttempts ?? 'N/A'}
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(log?.lastAttempted).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                           <td>
                              <button
                                 disabled={retryLoading}
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    handleAction(log);
                                 }}
                                 className={`bg-shiga-lime-50 font-medium text-shiga-lime-100 rounded-3xl flexed py-1.5 px-2.5 text-sm ${retryLoading && 'cursor-not-allowed'}`}>
                                 {retryLoading ?
                                    <Loader
                                       size='1.3rem'
                                       className="animate-spin"
                                    />
                                    : isFailed ? 'Retry' : 'Force Retry'}
                              </button>
                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>

         <LogDetailsModal
            selected={selected}
            isOpen={detailsShown}
            handleAction={handleAction}
            setIsOpen={setDetailsShown}
         />
      </>
   )
}

export default LogsTab