import { ChangeEvent, ReactNode, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { ReactComponent as WarningGrey } from "assets/images/icons/warning-gray.svg";
import { ReactComponent as WarningRed } from "assets/images/icons/warning-red.svg";
import Maybe from "components/common/Maybe";
import ChevronDownIcon from "assets/images/icons/ChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


type Props = {
  inputError?: boolean;
  errorText: string;
  transactionCurrency: string;
  flagIconUrl: string;
  disabled?: boolean;
  inputAmount: string;
  setInputAmount: (event: ChangeEvent<HTMLInputElement>) => void;
  leftLabel: string;
  rightLabel?: ReactNode;
  isFieldRequired?: boolean;
  showCurrenciesDropdown?: boolean;
  dropdownCurrencies?: { label: string; value: string; }[];
  handleSelection?: (currency: string) => void
};

const CustomAmountField = ({
  errorText,
  disabled,
  flagIconUrl,
  inputAmount,
  setInputAmount,
  inputError,
  transactionCurrency,
  leftLabel,
  rightLabel,
  isFieldRequired,
  showCurrenciesDropdown,
  dropdownCurrencies,
  handleSelection
}: Props) => {

  const [selectorShown, setSelectorShown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleSelector = () => setSelectorShown(!selectorShown);

  const isAudCur = transactionCurrency === 'AUD';

  return (
    <div className="w-full">
      <>
        <div className="flex mb-2 items-center justify-between">
          <p className="text-shiga-gray-100">
            {leftLabel}
            {isFieldRequired && <span className="form-input-required">*</span>}
          </p>

          {rightLabel}
        </div>

        <div
          className={`bg-white transition-colors duration-150 relative z-10 flex items-center justify-between border rounded-2xl py-5 px-2 ${inputError ? "border-shiga-red-50" : "border-shiga-gray-75 "
            }`}
        >
          <div>
            <NumericFormat
              type="text"
              decimalScale={2}
              placeholder="0.00"
              disabled={disabled}
              value={inputAmount}
              allowNegative={false}
              thousandSeparator={true}
              allowLeadingZeros={false}
              onValueChange={(values) => {
                setInputAmount({
                  target: { value: values.value },
                } as ChangeEvent<HTMLInputElement>);
              }}
              className="border-none p-0 focus-visible:outline-0 focus:border-transparent text-lg lg:text-[28px] font-ojah text-shiga-dark-100 placeholder:text-shiga-gray-100"
            />
          </div>

          <div onClick={() => showCurrenciesDropdown && toggleSelector()}
            className={`flex items-center absolute z-10 ${showCurrenciesDropdown ? 'right-7 cursor-pointer' : 'right-2'}`}>
            <img alt="Flag" src={isAudCur ? '/flags/aud.svg' : flagIconUrl} className="h-5 w-5 mr-1" />
            <span className="ml-1 mr-px text-shiga-dark-100 font-medium lg:text-[19px]">
              {transactionCurrency}
            </span>
          </div>

          <Maybe condition={showCurrenciesDropdown}>
            <div className="absolute top-9 right-2.5 cursor-pointer">
              <ChevronDownIcon
                className={` translate-y-[-50%] ${selectorShown ? "rotate-180" : ""}`}
              />
            </div>
          </Maybe>

          <Maybe condition={selectorShown}>
            <div className="absolute !top-14 right-0">

              <div ref={dropdownRef} className="p-3 pb-7 bg-shiga-dark-500 w-[120px] rounded-lg min-h-[80px] text-white space-y-4">

                {dropdownCurrencies?.map((item: any) => {

                  const { label, value, flagSrc } = item;
                  const isSelected = value === transactionCurrency;

                  return (
                    <button
                      onClick={() => {
                        if (!isSelected && handleSelection) {
                          handleSelection(value);
                          toggleSelector();
                        }
                      }}
                      className={`flex items-center w-full ${isSelected ? 'cursor-not-allowed' : ''}`}>

                      <img alt="Flag" src={flagSrc} className="h-5 w-5 mr-1" />

                      <span className="!ml-2">
                        {label}
                      </span>

                      <Maybe condition={isSelected}>
                        <div className="ml-auto">
                          <FontAwesomeIcon icon="check-circle" />
                        </div>
                      </Maybe>
                    </button>
                  )
                })}
              </div>
            </div>
          </Maybe>

        </div>
      </>


      <div
        className={` transition-colors duration-150 text-xs flex items-center w-full z-5 bg-gray-100 px-3 pt-2 pb-2.5 rounded-b-2xl ${inputError
          ? "bg-shiga-red-25 text-shiga-red-50"
          : "bg-shiga-gray-25 text-shiga-gray-300"
          }`}
      >
        {inputError ? <WarningRed /> : <WarningGrey />}
        <span className="font-medium ml-2">{errorText}</span>
      </div>

    </div >
  );
};

export default CustomAmountField;
