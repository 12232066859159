import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";

type Props = {
   setIsOpen: React.Dispatch<boolean>;
   endTransferSession: () => void;
}

const InlineTransfersHeader = ({ setIsOpen, endTransferSession }: Props) => {

   const closeModal = () => {
      setIsOpen(false);
      endTransferSession();
   }

   return (
      <div className="px-6 lg:px-9 py-6 flex items-center justify-between border-b border-gray-100 bg-white z-20 fixed w-full top-0">
         <div className="pl-3 ml-3 text-xl text-shiga-dark-100 font-ojah font-bold">
            Make a Transfer
         </div>
         <div onClick={closeModal} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
            <IconClose className="m-auto text-white svg-stroke-gray" />
         </div>
      </div>
   )
}

export default InlineTransfersHeader;