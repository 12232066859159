import { Table } from "@tanstack/react-table";

import { Button } from "components/ui/button";

import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { useToggle } from "hooks/useToggle";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Search,
} from "lucide-react";
import { useEffect, useState } from "react";
interface TableToolbarProps<TData> {
  table: Table<TData>;
  placeholder: string;
  filterOptions: string[];
  filterBy?: string;
  setFilter: (filter: string) => void;
}

export function DataTableHeader<TData>({
  table,
  placeholder,
  filterOptions,
  filterBy = "status",
  setFilter,
}: TableToolbarProps<TData>) {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    table.setGlobalFilter(searchInput);
  }, [searchInput, table]);

  // const lowerLimit =
  //   table.getState().pagination.pageIndex *
  //   table.getState().pagination.pageSize;
  // const upperLimit =
  //   (table.getState().pagination.pageIndex + 1) *
  //   table.getState().pagination.pageSize;
  const lowerLimit =
    table.getState().pagination.pageIndex *
    table.getState().pagination.pageSize +
    1;
  const upperLimit = Math.min(
    (table.getState().pagination.pageIndex + 1) *
    table.getState().pagination.pageSize,
    table.getFilteredRowModel().rows.length
  );
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <Filter
          filterOptions={filterOptions}
          setFilter={setFilter}
        />
      </div>

      <div className="flex items-center justify-between gap-5">
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <Button
              variant="ghost"
              className="size-6 p-0"
              onClick={table.previousPage}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="size-4" />
            </Button>
            <div className="flex items-center justify-center text-sm font-medium">
              {`${lowerLimit}-${upperLimit} of ${table
                .getRowCount()
                .toLocaleString()}`}
            </div>
            <Button
              variant="ghost"
              className="size-6 p-0"
              onClick={table.nextPage}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="size-4" />
            </Button>
          </div>
        </div>

        <div className="relative flex items-center space-x-2">
          <Input
            placeholder={placeholder}
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
            className="w-[150px] lg:w-[250px] pl-10"
          />
          <Search className="size-4 absolute top-1/2 -translate-y-1/2 left-3 text-form-gray" />
        </div>
      </div>
    </div>
  );
}

const Filter: React.FC<{
  filter?: any;
  // setFilter: any;
  setFilter: (filter: string) => void;
  filterOptions: string[];
  // setFilter: (filter: string) => void;
}> = ({ setFilter, filterOptions }) => {
  const [showFilter, toggleShowFilter] = useToggle();
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    setFilter(value);
    toggleShowFilter();
  };

  return (
    <Popover open={showFilter} onOpenChange={toggleShowFilter}>
      <PopoverTrigger asChild>
        <Button
          className="text-base rounded-lg space-x-2 text-zinc-800"
          variant="outline"
        >
          <p>Filter by</p>
          <ChevronDownIcon className="text-neutral-400" size={22} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[291px]  px-1.5 py-2 bg-zinc-900 rounded-[18px] flex-col justify-center items-start gap-1 inline-flex">
        <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
          {filterOptions.map((v) => (
            <div
              key={v}
              className="cursor-pointer self-stretch px-2 py-3 justify-start items-center gap-3 inline-flex"
              onClick={() => handleFilterChange(v)}
            >
              <Checkbox
                checked={v === selectedFilter}
              />
              <div className="grow shrink basis-0 text-white text-[15px] font-normal font-['Inter']">
                {v}
              </div>
            </div>
          ))}
        </div>

        {/* <div className="self-stretch p-2 justify-between items-center inline-flex  border-t border-[#232425] pt-3 mt-1">
                    <Button
                        variant="outline"
                        className="text-white bg-zinc-900 rounded-lg border-zinc-700 text-sm font-medium font-['Inter']"
                    >
                        Clear
                    </Button>
                    <Button
                        onClick={toggleShowFilter}
                        variant="outline"
                        className="bg-white text-zinc-900 rounded-lg border-zinc-700 text-sm font-medium font-['Inter']"
                    >
                        Apply
                    </Button>
                </div> */}
      </PopoverContent>
    </Popover>
  );
};
