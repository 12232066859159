import React, { SetStateAction } from 'react'
import FormDrawer from './form-drawer';
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import Maybe from './Maybe';
import ShigaButton from './form/ShigaButton';


type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   headerTitle: string;
   children: React.ReactNode;
   hasButton?: boolean;
   buttonText?: string;
   buttonClassName?: string;
   buttonDisabled?: boolean;
   isButtonLoading?: boolean;
   onButtonClick?: () => void;
   noHeader?: boolean;
}

const CenterModal = ({
   isOpen,
   setIsOpen,
   headerTitle,
   children,
   hasButton,
   buttonText,
   buttonClassName,
   buttonDisabled,
   isButtonLoading,
   onButtonClick,
   noHeader
}: Props) => {

   const isDeleting = headerTitle?.includes('Delete')

   return (
      <FormDrawer
         size="lg"
         display="center"
         isOpen={isOpen}
         setIsOpen={setIsOpen}>

         <div className='p-6'>

            <Maybe condition={!noHeader}>
               <div className="pb-3 flex items-center justify-between border-b border-gray-100">
                  <div className="text-2xl font-ojah font-medium">{headerTitle}</div>
                  <div onClick={() => setIsOpen(false)}
                     className="w-8 h-8 bg-shiga-gray-40 flex rounded-full cursor-pointer">
                     <IconClose className="m-auto svg-stroke-gray" />
                  </div>
               </div>
            </Maybe>

            <div className="w-full pt-4">
               <div>
                  {children}
               </div>

               <Maybe condition={hasButton}>
                  <div className="w-full pt-5">
                     <ShigaButton
                        fullWidth
                        danger={isDeleting}
                        darkBg={!isDeleting}
                        loading={isButtonLoading}
                        text={buttonText || ''}
                        disabled={buttonDisabled}
                        className={buttonClassName}
                        onClick={() => onButtonClick && onButtonClick()}
                     />
                  </div>
               </Maybe>
            </div>
         </div>

      </FormDrawer>
   )
}

export default CenterModal