import React, { ChangeEvent, ReactNode } from 'react'

type NotePadProps = {
   notePadMaxLength?: number;
   notePadLeftLabel: string;
   notePadPlaceholder: string;
   noteIsRequired: boolean;
   notePadHeight?: string;
   noteValue: string;
   LabelIcon?: ReactNode;
   customBg?: string;
   custommTextColor?: string;
   disableEdit?: boolean;
   setNoteValue: React.Dispatch<React.SetStateAction<string>>
}

const CustomNotePad = ({
   notePadMaxLength = 200,
   notePadLeftLabel,
   notePadPlaceholder,
   noteIsRequired,
   notePadHeight,
   noteValue,
   setNoteValue,
   LabelIcon,
   customBg,
   custommTextColor,
   disableEdit
}: NotePadProps) => {

   const updateNotePad = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      if (value?.length <= notePadMaxLength) setNoteValue(value)
   };

   return (
      <div className="w-full">

         <div className="w-full flex mt-8 justify-between text-sm">

            <p className="text-shiga-gray-100 flex">
               {notePadLeftLabel}
               {noteIsRequired && <span className="form-input-required">*</span>}
               {LabelIcon}
            </p>

            <p>{noteValue?.length || 0}/{notePadMaxLength}</p>
         </div>

         <div className="w-full mt-2 text-shiga-gray">
            <textarea
               maxLength={200}
               value={noteValue}
               disabled={disableEdit}
               onChange={updateNotePad}
               placeholder={notePadPlaceholder}
               style={{ height: notePadHeight || '80px', resize: 'none' }}
               className={`w-full border-0 py-3 px-5 rounded-2xl placeholder:text-shiga-gray-100 placeholder:font-normal font-medium ${customBg || 'bg-shiga-gray-40'} ${custommTextColor || 'text-shiga-dark-100'}`}
            />
         </div>

      </div>
   )
}

export default CustomNotePad