import { RootState } from "store/store";
import * as actions from "./actionTypes";
import { PaginatedApiResponse } from "types/data-interface";
import { Transaction } from "types/transaction";
import errorReducer from "utils/errorReducer";
import { toast } from "react-toastify";

interface InitialState {
	isLoadingTransactions: boolean;
	transactions: PaginatedApiResponse<Transaction[]> | null;

	allSettlementsLoading: boolean;
	allSettlementsData: any;

	settlementDetailsLoading: boolean;
	settlementDetailsData: any;

	selectedSettlement: any;

	allowedCurrenciesLoading: boolean,
	allowedCurrenciesData: any,

	createPaymentLinkLoading: boolean,
	createPaymentLinkData: any,
}

const initialState: InitialState = {
	isLoadingTransactions: false,
	transactions: null,

	allSettlementsLoading: false,
	allSettlementsData: null,

	settlementDetailsLoading: false,
	settlementDetailsData: null,

	selectedSettlement: null,

	allowedCurrenciesLoading: false,
	allowedCurrenciesData: null,

	createPaymentLinkLoading: false,
	createPaymentLinkData: null,
};

export default function reducer(state = initialState, action: any) {
	switch (action.type) {

		case actions.CREATE_PAYMENT_LINK_START:
			state = {
				...state,
				createPaymentLinkLoading: true,
				createPaymentLinkData: null
			};
			return state;

		case actions.CREATE_PAYMENT_LINK_DONE:
			toast(action.payload.message)
			state = {
				...state,
				createPaymentLinkLoading: false,
				createPaymentLinkData: action.payload
			};
			return state;

		case actions.RESET_PAYMENT_LINK_PROPS:
			state = {
				...state,
				createPaymentLinkData: null
			}
			return state;

		case actions.CREATE_PAYMENT_LINK_FAILED:
			errorReducer(action.payload)
			state = {
				...state,
				createPaymentLinkLoading: false,
				createPaymentLinkData: null
			};
			return state;

		case actions.GET_ALLOWED_CURRENCIES_START:
			state = {
				...state,
				allowedCurrenciesLoading: true,
				allowedCurrenciesData: null
			};
			return state;

		case actions.GET_ALLOWED_CURRENCIES_DONE:
			state = {
				...state,
				allowedCurrenciesLoading: false,
				allowedCurrenciesData: action.payload
			};
			return state;

		case actions.GET_ALLOWED_CURRENCIES_FAILED:
			errorReducer(action.payload)
			state = {
				...state,
				allowedCurrenciesLoading: false,
				allowedCurrenciesData: null
			};
			return state;

		case actions.SELECT_SETTLEMENT:
			state = {
				...state,
				selectedSettlement: action.payload
			}
			return state;

		case actions.GET_SETTLEMENT_DETAILS_START:
			state = {
				...state,
				settlementDetailsLoading: true,
				settlementDetailsData: null
			};
			return state;

		case actions.GET_SETTLEMENT_DETAILS_DONE:
			state = {
				...state,
				settlementDetailsLoading: false,
				settlementDetailsData: action.payload
			};
			return state;

		case actions.GET_SETTLEMENT_DETAILS_FAILED:
			errorReducer(action.payload)
			state = {
				...state,
				settlementDetailsLoading: false,
				settlementDetailsData: null
			};
			return state;

		case actions.GET_ALL_SETTLEMENTS_START:
			state = {
				...state,
				allSettlementsLoading: true,
				allSettlementsData: null
			};
			return state;

		case actions.GET_ALL_SETTLEMENTS_DONE:
			state = {
				...state,
				allSettlementsLoading: false,
				allSettlementsData: action.payload
			};
			return state;

		case actions.GET_ALL_SETTLEMENTS_FAILED:
			errorReducer(action.payload)
			state = {
				...state,
				allSettlementsLoading: false,
				allSettlementsData: null
			};
			return state;

		case actions.GET_TRANSACTIONS_START:
			state = {
				...state,
				isLoadingTransactions: true,
			};
			return state;

		case actions.GET_TRANSACTIONS:
			state = {
				...state,
				isLoadingTransactions: false,
				transactions: action.payload
			};
			return state;

		case actions.GET_TRANSACTIONS_FAILED:
			errorReducer(action.payload)
			state = {
				...state,
				isLoadingTransactions: false,
				transactions: null
			};
			return state;

		default:
			return state;
	}
}

export const getTransactionsStore = (state: RootState) => state.transactions;