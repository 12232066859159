import { Dialog } from '@headlessui/react'
import React, { SetStateAction } from 'react'
import LayoutHeader from './LayoutHeader'
import LayoutSidebar from './LayoutSidebar'
import Maybe from 'components/common/Maybe'
import { ReactComponent as BackButton } from "assets/images/icons/arrow-left-bordered.svg";
import SessionLandingPage from '../SessionLandingPage'
import useEcomProvider from '../context/useEcomProvider'


type Props = {
   setSessionModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const LayoutDisplay = ({ setSessionModalOpen }: Props) => {
   const { sessionStep, decrementSessionStep } = useEcomProvider()

   // eslint-disable-next-line
   const handleReturn = () => {
      // decrementPaymentLinkStep()
   }

   return (
      <div className="w-full h-full bg-white overflow-y-auto">
         <Dialog.Panel className="min-h-full flex flex-col">

            <LayoutHeader
               setSessionModalOpen={setSessionModalOpen}
            />

            <div className="flex lg:px-0">

               <div className='w-2/5'>
                  <LayoutSidebar />

                  <Maybe condition={sessionStep > 1}>
                     <button onClick={decrementSessionStep} className="sticky left-[350px] top-32">
                        <BackButton />
                     </button>
                  </Maybe>
               </div>

               <div className='w-[65%]'>
                  <SessionLandingPage />
               </div>

            </div>

         </Dialog.Panel>
      </div>
   )
}

export default LayoutDisplay