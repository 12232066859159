import { SetStateAction, useEffect, useRef, useState } from "react";
import FormDrawer from "components/common/form-drawer";
import PinInput from 'react-pin-input';
import PinInputRef from 'react-pin-input';
import { toast } from "react-toastify";
import { ButtonLoader } from "components/common/form";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import Maybe from "components/common/Maybe";
import { Countdown } from "components/common/Countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageContent from "components/common/PageContent";

type TwoFacProps = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    loading: boolean;
    target: string;
    onSubmit: () => void;
    buttonText: string;
    title: string;
    onPinChange: (value: string) => void;
    showResend?: boolean;
    resendLoading?: boolean;
    onResendClick?: () => void;
    modalLoading?: boolean;

}

export const TwoFacInputModal = ({
    isOpen,
    setIsOpen,
    loading,
    target,
    onSubmit,
    buttonText,
    title,
    onPinChange,
    showResend,
    resendLoading,
    onResendClick,
    modalLoading
}: TwoFacProps) => {

    const pinLength = 6;
    const [pin, setPin] = useState("");
    const [timerShown, setTimerShown] = useState(true);
    let elementRef = useRef<PinInputRef>();

    const submitForm = async (e: any) => {
        e.preventDefault();
        if (pin !== "" && pin.length === pinLength) {
            onSubmit();
        }
        else {
            toast.error("Please enter a valid " + target);
        }
    }

    const clearInputPin = () => {
        if (elementRef && elementRef.current !== undefined) {
            (elementRef.current as PinInputRef)?.clear(); // Use PinInputRef type assertion
            setPin("");
        }
    }

    const handlePaste = (event: any) => {
        const pastedData = event.clipboardData.getData('text');

        if (pastedData.length === pinLength) {
            setPin(pastedData);
            onPinChange(pastedData);
        }
    };

    useEffect(() => {
        clearInputPin();
    }, [isOpen]);

    return (
        <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>

            <Maybe condition={modalLoading === true}>
                <div className="p-12">
                    <PageContent loading={modalLoading} />
                </div>
            </Maybe>

            <Maybe condition={modalLoading === false}>
                <form onSubmit={submitForm} onPaste={handlePaste}>
                    <div className="p-8">
                        <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                            <div className="text-2xl font-ojah font-medium">
                                {title}
                            </div>
                            <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                <IconClose className="m-auto text-white svg-stroke-gray" />
                            </div>
                        </div>

                        <div className="h-16 mt-10 flex justify-center">
                            <PinInput
                                length={pinLength}
                                initialValue={pin}
                                onChange={(value, index) => { setPin(value); onPinChange(value) }}
                                type="numeric"
                                inputMode="number"
                                inputStyle={{ width: '47px', height: '47px', background: "#FAFAFB", border: "2px solid transparent", marginRight: "6px", fontSize: "20px", fontWeight: "bold", paddingBottom: "6px", borderRadius: "1rem" }}
                                inputFocusStyle={{ border: "2px solid #343433" }}
                                onComplete={(value, index) => { }}
                                autoSelect={true}
                                focus={true}
                                secret={true}
                                ref={(n: PinInput) => { elementRef && (elementRef.current = n); }}
                            />
                        </div>

                        <Maybe condition={showResend}>
                            <div className="mt-3 mx-3 flex justify-end text-[#99999C]">
                                <Maybe condition={timerShown === true}>
                                    Resend code in&nbsp;
                                    <span className="text-shiga-purple font-medium cursor-not-allowed">
                                        <Countdown seconds={59} onExpiry={() => setTimerShown(false)} />
                                    </span>
                                </Maybe>
                                <Maybe condition={timerShown === false}>
                                    Didn't receive OTP?&nbsp;
                                    <span onClick={onResendClick} className={"text-shiga-purple font-medium cursor-pointer border-b-2 border-transparent hover:border-shiga-purple " + (resendLoading && "opacity-40")}>
                                        <Maybe condition={resendLoading}>
                                            <FontAwesomeIcon icon="circle-notch" spin className="mr-2" />
                                        </Maybe>
                                        Resend OTP
                                    </span>
                                </Maybe>
                            </div>
                        </Maybe>
                    </div>

                    <div className="border-t border-gray-100 p-8">
                        <ButtonLoader loading={loading} type="submit" disabled={!(pin !== "" && pin.length === pinLength)} className="btn btn-lg btn-block btn-shiga-black">
                            {loading ? 'Authorizing...' : buttonText || "Continue"}
                        </ButtonLoader>
                    </div>
                </form>
            </Maybe>
        </FormDrawer>
    )
}

export default TwoFacInputModal;