import Maybe from 'components/common/Maybe'
import useTransfersProvider from '../../context/useTransfersProvider'
import CustomSwitch from 'components/common/form/CustomSwitch';
import ShigaButton from 'components/common/form/ShigaButton';
import useToolkit from 'hooks/useToolkit';
import { requestTransferOtp } from 'store/transfer/action';

type WrapperProps = {
   title: string,
   value: string,
   hideBorder: boolean
}

const ReviewAndPay = () => {
   const { dispatch, mfaEnabled } = useToolkit()
   const {
      trfCurrency,
      transferAmount,
      transferNote,
      ngnForm,
      transferFee,
      isNgnTransfer,
      saveTrfBeneficiary,
      setSaveTrfBeneficiary,
      toggleTwoFacInput,
      usdForm,
      isUsdTransfer,
      isAudTransfer,
      audForm,
      gbpForm,
      isGbpTransfer
   } = useTransfersProvider();
   const formattedFee = `${transferFee} ${trfCurrency}`;
   const formattedAmount = `${Number(transferAmount)?.toLocaleString()} ${trfCurrency}`;

   const ngnReview = [
      { title: 'Transaction Type', value: 'Bank Transfer' },
      { title: 'Amount', value: formattedAmount },
      { title: 'Account Number', value: ngnForm?.accountNumber },
      { title: 'Bank Name', value: ngnForm?.bankName?.toLowerCase() },
      { title: 'Fee', value: formattedFee },
      { title: 'Note', value: transferNote || 'Sent from Shgia' }
   ]

   const usdReview = [
      { title: 'Transaction Type', value: 'ACH Transfer' },
      { title: 'Amount', value: formattedAmount },
      { title: 'Bank Name', value: usdForm?.bankName },
      { title: 'Account Number', value: usdForm?.accountNumber },
      { title: 'ACH routing number', value: usdForm?.routingNo || 'N/A' },
      { title: 'Account Type', value: usdForm?.meta?.accountType || 'N/A' },
      { title: 'Fee', value: formattedFee },
      { title: 'Note', value: transferNote || 'Sent from Shgia' }
   ]

   const audReview = [
      { title: 'Transaction Type', value: 'BSB Transfer' },
      { title: 'Amount', value: formattedAmount },
      { title: 'Account Name', value: audForm?.accountName },
      { title: 'Account Number', value: audForm?.accountNumber },
      { title: 'BSB number', value: audForm?.bsbNumber },
      { title: 'Fee', value: formattedFee },
      { title: 'Note', value: transferNote || 'Sent from Shgia' }
   ]

   const gbpReview = [
      { title: 'Transaction Type', value: 'Bank Transfer' },
      { title: 'Amount', value: formattedAmount },
      { title: 'Account Name', value: gbpForm?.accountName },
      { title: 'Account Number', value: gbpForm?.accountNumber },
      { title: 'Fee', value: formattedFee },
      { title: 'Note', value: transferNote || 'Sent from Shgia' }
   ]

   const onProceed = () => {
      if (!mfaEnabled) {
         dispatch(requestTransferOtp());
      }
      toggleTwoFacInput();
   };

   return (
      <div>

         <div className='rounded-xl border border-shiga-gray-50 mb-12'>

            <Maybe condition={isUsdTransfer}>
               <div className="p-4">
                  {usdReview.map(({ title, value }, idx) => {
                     return (
                        <ReviewWrapper
                           key={title}
                           title={title}
                           value={value}
                           hideBorder={idx === usdReview?.length - 1}
                        />
                     )
                  })}
               </div>
            </Maybe>

            <Maybe condition={isNgnTransfer}>
               <div className="p-4">
                  {ngnReview.map(({ title, value }, idx) => {
                     return (
                        <ReviewWrapper
                           key={title}
                           title={title}
                           value={value}
                           hideBorder={idx === ngnReview?.length - 1}
                        />
                     )
                  })}
               </div>
            </Maybe>

            <Maybe condition={isAudTransfer}>
               <div className="p-4">
                  {audReview.map(({ title, value }, idx) => {
                     return (
                        <ReviewWrapper
                           key={title}
                           title={title}
                           value={value}
                           hideBorder={idx === audReview?.length - 1}
                        />
                     )
                  })}
               </div>
            </Maybe>

            <Maybe condition={isGbpTransfer}>
               <div className="p-4">
                  {gbpReview.map(({ title, value }, idx) => {
                     return (
                        <ReviewWrapper
                           key={title}
                           title={title}
                           value={value}
                           hideBorder={idx === gbpReview?.length - 1}
                        />
                     )
                  })}
               </div>
            </Maybe>

            <div className="flex-between bg-shiga-gray-25 px-4 py-[18px]">
               <div className="text-shiga-dark-100 font-medium lg:text-[14px]">
                  Save as a beneficiary
               </div>

               <CustomSwitch
                  hideText
                  active={saveTrfBeneficiary}
                  onChange={() => setSaveTrfBeneficiary(!saveTrfBeneficiary)}
               />
            </div>
         </div>

         <ShigaButton
            darkBg
            fullWidth
            onClick={onProceed}
            text="Confirm & Pay"
         />


      </div>
   )
}

const ReviewWrapper = ({ title, value, hideBorder }: WrapperProps) => {


   return (
      <div className={`flex-between w-full py-3 ${hideBorder ? 'pb-0' : 'border-b border-shiga-gray-50'}`}>
         <p className="mb-0 text-shiga-gray-250">{title}</p>
         <p className="mb-0 text-shiga-dark-200 font-medium capitalize">{value}</p>
      </div>
   )
}

export default ReviewAndPay